import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadline from './AmgHeadline';
import AmgHeadlines from './AmgHeadlines';
import AmgImage from './AmgImage';
import AmgLink from './AmgLink';
import { Box } from '@mui/material';

const PREFIX = 'AmgHeroStageHero';

const classes = {
  button: `${PREFIX}-button`,
  emissionStatement: `${PREFIX}-emissionStatement`,
  headline: `${PREFIX}-headline`,
  image: `${PREFIX}-image`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.button}`]: {
    backgroundColor: theme.palette.amgRed,
    margin: theme.spacing(0, 2, 3, 2),
    width: `calc(100% - ${theme.spacing(4)})`,
  },

  [`& .${classes.emissionStatement}`]: {
    margin: theme.spacing(0, 0, 2, 2),
    width: `calc(100% - ${theme.spacing(4)})`,
  },

  [`& .${classes.headline}`]: {
    margin: theme.spacing(0, 2, 2),
    lineHeight: 1.3,
  },
}));

function AmgHeroStageHero({ content }) {

  return (
    (<Root>
      <AmgHeadlines headline={content.headline} subHeadline={content.subHeadline} />
      <Box sx={{ mb: 2 }}>
        <AmgImage content={content.image} />
      </Box>
      <AmgEmissionStatement className={classes.emissionStatement} content={content.emissionStatement} />
      <AmgHeadline className={classes.headline} variant="headline">
        {content.text}
      </AmgHeadline>
      <AmgLink
        className={classes.button}
        eventdetailaction="hero-stage"
        link={content.link}
      />
    </Root>)
  );
}

export default AmgHeroStageHero;

AmgHeroStageHero.propTypes = {
  content: PropTypes.object.isRequired,
};
