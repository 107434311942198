import { Provider } from 'react-redux';

import AmgStatus from './AmgStatus';
import store from './redux/store';

function AmgApp() {
  return (
    <Provider store={store}>
      <AmgStatus />
    </Provider>
  );
}

export default AmgApp;
