import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Typography } from '@mui/material';
const PREFIX = 'AmgSubmitMsg';

const classes = {
  content: `${PREFIX}-content`,
  message: `${PREFIX}-message`,
  root: `${PREFIX}-root`,
};

const StyledCard = styled(Card)((
  {
    theme,
  }
) => ({
  [`& .${classes.content}`]: {
    '&:last-child': {
      paddingBottom: '16px',
    },
  },

  [`& .${classes.message}`]: {
    fontFamily: 'MBCorpoSTitle-Bold-Web',
    fontSize: '1.25rem', // 20px
  },

  [`&.${classes.root}`]: {
    background: theme.palette.amgMineshaftPure,
    margin: theme.spacing('28px', 2, 2),
  },
}));

function AmgSubmitMsg({ i18nKeys, successMessage, type }) {

  const MAP_RESPONDE_MSG = {
    'ALREADY_REGISTERED': i18nKeys['amg.communities.participation.returnmessage.ALREADY_REGISTERED'],
    'DEADLINE_REACHED': i18nKeys['amg.communities.participation.returnmessage.DEADLINE_REACHED'],
    'ERROR': i18nKeys['amg.communities.participation.returnmessage.ERROR'],
    'MAX_PARTICIPANTS_REACHED': i18nKeys['amg.communities.participation.returnmessage.MAX_PARTICIPANTS_REACHED'],
    'SUCCESS': successMessage,
  };

  return (
    <StyledCard className={classes.root}>
      <CardContent className={classes.content}>
        {MAP_RESPONDE_MSG[type] && <Typography className={classes.message}>{MAP_RESPONDE_MSG[type]}</Typography>}
      </CardContent>
    </StyledCard>
  );
}

export default AmgSubmitMsg;

AmgSubmitMsg.propTypes = {
  i18nKeys: PropTypes.object,
  successMessage: PropTypes.string,
  type: PropTypes.string,
};
