import AmgStage from './components/AmgStage';
import DOMPurify from 'dompurify';
import { MAP_HOST_TO_ENV, MAP_TYPE_TO_COMPONENTS } from './constants';
import { updateStatus } from './redux/actions';
import store from './redux/store';

const buildContentSearchUrl = (params) => {
  const state = store.getState();
  const API_BASE = state.env.API_BASE;

  // If we only provide the "nextLink", then just add the language parameter
  if (typeof params === 'string') {
    return `${API_BASE}${params}&language=${state.user.preferred_language}`;
  }

  // If we provide an object, then we need to build the whole URL
  params.categories = getCategoriesFromFilters(state.user.filters);
  params.language = state.user.preferred_language;
  params.tenant = 'amg';

  // Turn an object into query string parameters
  const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');

  return `${API_BASE}${state.env.API_ENDPOINT}?${queryString}`;
};

/**
 * Create a target href for the provided link
 * @param {string|object} link
 * @returns {string}
 */
const buildHref = (link) => {
  let href = '';

  if (link.attributeMap) {
    href = link.attributeMap.href;
    if (isModelJson(link.attributeMap.href)) {
      href = encodeURIComponent(link.attributeMap.href);
    }
  } else if (link.linkType) {
    href = link.url;
    if (isModelJson(link.url)) {
      href = encodeURIComponent(link.url);
    }
  }
  else if (typeof link === 'string') {
    href = link;
  }

  return href;
};

/**
 * Cleans a given HTML from unauthorized tags
 * @param {string} content - string to clean
 * @returns {string}
 */
const cleanHTML = (content, strictMode = false) => {
  // Specify a configuration directive
  // Allowed tags on the strict mode (RTE) :
  // 'b', 'i', 'u', 'p', 'a', 'ul', 'ol', 'li', 'caption', 'div', 'dl', 'dt', 'dd', 'sub', 'sup', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'

  // Blocked tags on default mode:
  // 'applet', 'base', 'basefont', 'command', 'embed', 'frame', 'frameset', 'iframe', 'keygen', 'link', 'meta', 'noframes', 'noscript', 'object', 'param', 'script', 'title'

  // Blocked attributes on default mode:
  // 'accept-charset', 'accesskey', 'allow', 'async', 'autocapitalize', 'autofocus', 'autoplay', 'buffered', 'challenge', 'charset', 'code', 'codebase', 'content',
  // 'contenteditable', 'contextmenu', 'codebase', 'content', 'contenteditable', 'contextmenu', 'controls', 'data', 'decoding', 'defer', 'dirname', 'draggable',
  // 'dropzone', 'form', 'formaction', 'http-equiv', 'icon', 'importance', 'itemprop', 'keytype', 'kind', 'language', 'lazyload', 'manifest', 'minlength',
  // 'muted', 'ping', 'sandbox', 'scoped', 'slot', 'spellcheck', 'srcdoc', 'srclang', 'start', 'target', 'translate', 'wrap'

  const config = {
    // USE_PROFILES: { html: true }, //using use_profiles will override allowed_tags
    KEEP_CONTENT: false, // remove content from non-allow-listed nodes too
    RETURN_DOM: false, // return a document object instead of a string
    ADD_ATTR: ['target'],
    ...(strictMode && { ALLOWED_TAGS: ['b', 'i', 'u', 'p', 'a', 'ul', 'ol', 'li', 'caption', 'div', 'dl', 'dt', 'dd', 'sub', 'sup',
      'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', '#text'] }),
  };
  return DOMPurify.sanitize(content, config);
};

/**
 * Prepare the init arguments of the fetchHandler
 * @param {string} resource - The resource to call
 * @returns {object} The init object to use in the fetch
 */
const initFetchHandler = (resource) => {
  const jwt = getJsonWebToken();
  const init = {
    headers: {},
  };

  if (resource.startsWith('/api/') || resource.startsWith('/app/') || resource.startsWith('https://content-search')) {
    init.headers.amg_suinfo_s = jwt;
  }

  if (process.env.REACT_APP_HTTP_AUTH) {
    if (resource.startsWith('/content/') || resource.startsWith('/bin/')) {
      init.headers.authorization = `Basic ${process.env.REACT_APP_HTTP_AUTH}`;
    }
  }

  if (resource.startsWith('/bin/daimler/public/refresh-token')) {
    init.credentials = 'include';
    init.mode = 'no-cors';
  }

  return init;
};

/**
 * Trigger a Fetch call with the right headers depending on the endpoint and the environment
 * @param {string} resource - The resource to call
 * @returns {json} JSON object returned by the API
 */
const fetchHandler = (resource) => {
  const init = initFetchHandler(resource);

  return fetch(resource, init).then(async (response) => {
    let result = {};
    let code;

    // HTTP code is in the range 200-299
    if (response.ok) {
      // Check if the result is a JSON object, i.e. the refresh-token endpoint does not return anything
      const contentType = response.headers.get('content-type');
      const isJson = contentType && contentType.includes('application/json');

      if (isJson) {
        result = await response.json();
      }

      // If the response has a key called "maintenance", then we manually turned on the maintenance mode
      if (result.maintenance) {
        window.location.href = '/app/index.html#/maintenance';
      }
    } else if (response.status === 403) {
      code = 'expired';
    } else {
      code = 'error';
    }

    // If a new code is set in the previous steps, then we have an issue and we show the relevant screen to the user
    if (code) {
      store.dispatch(updateStatus({ code }));
    }

    return result;
  }).catch(() => {
    store.dispatch(updateStatus({ code: 'error' }));
    return {};
  });
};

/**
 * Format the endpoint with the user language
 * Default parameters are used just to avoid breaking during testing
 * @param {string} path
 * @param {string} lang
 * @returns {string} endpoint
 */
const formatEndpoint = (path = '', lang = 'EN') => {
  return path.replace('{lang}', lang.toLowerCase());
};

/**
 * Get an object of filters and returns the active filters separared by commas
 * @param {object} filters
 * @returns {string} categories separated by commas
 */
const getCategoriesFromFilters = (filters) => {
  const categories = [];

  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      categories.push(key);
    }
  }

  return categories.join(',');
};

/**
 * Get the JSON Web Token from the user cookies
 * @returns {string} jwt
 */
const getJsonWebToken = () => {
  // Get the JWT from the cookie to call
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((row) => row.startsWith('amg_suinfo_s='));

  return cookie ? cookie.split('=')[1] : '';
};

/**
 * Returns the environment based on the hostname
 * @returns {string} environment
 */
const getHostToEnv = () => {
  const host = window.location.hostname;

  return MAP_HOST_TO_ENV[host] ? MAP_HOST_TO_ENV[host] : console.error('Hostname unknown');
};

/**
 * Get the newest timestamps from a list of posts
 * @param {array} posts
 * @returns timestamp
 */
const getMaxTimestampFromPosts = (posts) => {
  let result = 0;

  if (Array.isArray(posts)) {
    result = Math.max(...posts.map((randomPost) => randomPost.timestamp));
  }

  return result;
};

/**
 * Check if the active filters when opening the Drawer are the same when closing the Drawer
 * @param {object} oldFilters
 * @param {object} newFilters
 * @returns {boolean}
 */
const hasFiltersBeenUpdated = (oldFilters, newFilters) => {
  return !Object.entries(oldFilters).every(([key, value]) => newFilters[key] === value);
};

/**
 * Detects iOS Safari
 * @returns {boolean}
 */
const IsIosSafari = () => {
  // Returns a boolean indicating whether the browser is running in standalone mode. Available on Apple's iOS Safari only.
  const chromeIOS = navigator.userAgent.indexOf('CriOS') >= 0;
  return 'standalone' in window.navigator && !chromeIOS;
};

/**
 * Check if the url is a .model.json file or not
 * @param {string} url
 * @returns {boolean}
 */
const isModelJson = (url) => {
  return url.endsWith('.model.json');
};

/**
 * Device is considered PWA optimized if any of these conditions are met:
 * - orientation is portrait
 * - width or heigth is < 480 (iPhone 8 and previous)
 * - pixelratio is > 2 (iPhone 8 plus and post)
 * @returns {boolean}
 */
const isPWAOptimized = () => {
  return window.innerHeight > window.innerWidth || window.innerWidth < 480 || window.innerHeight < 480 || window.devicePixelRatio > 2;
};

/**
 * Detects if the PWA is in standalone mode
 * @returns {boolean}
 */
const isStandalone = () => {
  return ('standalone' in window.navigator) && window.navigator.standalone;
};

/**
 * Returns the string limited to a maximum number of characters
 * @param {string} str
 * @param {integer} maxLength
 * @returns string
 */
const maxChar = (str, maxLength) => {
  if (typeof str !== 'string') {
    return '';
  }

  if (str.length <= maxLength) {
    return str;
  }

  // Trim the string to the maximum length
  let trimmedString = str.substring(0, maxLength);
  // Re-trim if we are in the middle of a word
  trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));

  return `${trimmedString}...`;
};

/**
 * Checks the content node for its aem components and maps them to the react components
 * @param {object} content - JSON object with components
 * @returns {object}
 */
const renderComponents = (content) => {
  return content[':itemsOrder'].map((item, index) => {
    const component = content[':items'][item];
    const componentType = component[':type'];
    // Do not render the component if it's flagged as not valid on the backend side
    if ('valid' in component && component.valid === false) {
      return null;
    }
    // dynamic components might come with responsive grid inside and type like:
    // 'amg/components/editable/content/comparisonSlider' instead of 'amg/components/components/content/comparisonSlider'
    const standardType = componentType.replace('editable', 'components');
    if (componentType === '/apps/amg/components/components/global/parsys' || componentType === 'wcm/foundation/components/responsivegrid') {
      return renderComponents(component);
    } else if (componentType === '/apps/amg/components/components/content/stage') {
      return <AmgStage content={component} key={index} />;
    } else if (MAP_TYPE_TO_COMPONENTS[standardType]) {
      const AmgComponent = MAP_TYPE_TO_COMPONENTS[standardType];
      return <AmgComponent content={component} key={index} />;
    }

    // Component is not supported yet by the PWA
    return null;
  });
};

export {
  buildContentSearchUrl,
  buildHref,
  cleanHTML,
  fetchHandler,
  formatEndpoint,
  getCategoriesFromFilters,
  getHostToEnv,
  getJsonWebToken,
  getMaxTimestampFromPosts,
  hasFiltersBeenUpdated,
  IsIosSafari,
  isModelJson,
  isPWAOptimized,
  isStandalone,
  maxChar,
  renderComponents,
};
