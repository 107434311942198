import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { ReactComponent as ChevronRightIcon } from '../svg/chevron-right.svg';
import { buildHref } from '../utils';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgHeroStageTeaser';

const classes = {
  box: `${PREFIX}-box`,
  header: `${PREFIX}-header`,
  headline: `${PREFIX}-headline`,
  text: `${PREFIX}-text`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`&.${classes.box}`]: {
    backgroundColor: theme.palette.amgMineshaft,
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2, 1, 2),
  },

  [`& .${classes.header}`]: {
    alignItems: 'baseline',
    display: 'flex',
  },

  [`& .${classes.headline}`]: {
    flexGrow: 1,
    fontFamily: 'MBCorpoSTitle-Bold-Web',
    fontSize: '1.3rem',
    lineHeight: 1.1,
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.text}`]: {
    fontFamily: 'MBCorpoSText-Regular-Web',
  },
}));

function AmgHeroStageTeaser({ content }) {

  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    if (content.link.valid) {
      const href = buildHref(content.link);

      trackEvent({
        event: 'click',
        eventDetail: {
          action: 'hero-stage',
          category: 'link',
          destinationUrl: decodeURIComponent(href),
          label: content.headline,
        },
      });

      if (href === '#') { // we seems to have st the case
        window.reload();
      } else if (!href.endsWith('.model.json')) {
        window.open(href, '_blank'); // all non pwa related links should open in new tab
      } else {
        navigate(href);
      }
    }
  };

  return (
    <StyledBox className={classes.box} onClick={handleClick}>
      <div className={classes.header}>
        <div className={classes.headline}>{content.headline}</div>
        {content.link.valid && <ChevronRightIcon />}
      </div>
      <div className={classes.text}>{content.text}</div>
    </StyledBox>
  );
}

export default AmgHeroStageTeaser;

AmgHeroStageTeaser.propTypes = {
  content: PropTypes.object.isRequired,
};
