import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useRouteListener() {
  const location = useLocation();

  useEffect(() => {
    // Do not scroll the user to the top when landing on the Activity Stream
    if (location.pathname !== '/') {
      // Prevent the user from landing in the middle of a page after a click on a link
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
}

export default useRouteListener;
