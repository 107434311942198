import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadlines from './AmgHeadlines';
import AmgImage from './AmgImage';
import AmgVideoDam from './AmgVideoDam';
import AmgLink from './AmgLink';
import AmgRichText from './AmgRichText';
import AmgSlider from './AmgSlider';

const PREFIX = 'AmgImageText';

const classes = {
  button: `${PREFIX}-button`,
  img: `${PREFIX}-img`,
  root: `${PREFIX}-root`,
  slide: `${PREFIX}-slide`,
  slider: `${PREFIX}-slider`,
  headline: `${PREFIX}-headline`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.img}`]: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },

  [`& .${classes.root}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.slide}`]: {
    padding: theme.spacing(0, 0.5),
  },

  [`& .${classes.slider}`]: {
    margin: theme.spacing(0, -2, 4),
  },

  /**
   * Special case for the ImageText component which has a different style for the headline than the default one.
   * The reason for that is that many times content-wise they use the Image Text component as “sub-component”
   * placed below other components with a caps headline.
   */
  [`& .${classes.headline}`]: {
    '& h2': {
      fontFamily: 'MBCorpoSText-Bold-Web',
      fontSize: '1.8rem',
      lineHeight: 1.1,
      textTransform: 'inherit',
    },
  },
}));

function AmgImageText({ content }) {

  const sliderSettings = {
    centerPadding: '12px',
    dots: true,
    eventDetailAction: 'content',
    infinite: false,
  };

  const buildLink = (link) => {
    if (!link.valid) {
      return null;
    }

    if (link.attributeMap['data-amg4web-linkcategory'] === 'internal' && link.attributeMap.href.includes('.html')) {
      link.attributeMap['data-amg4web-linkcategory'] = 'external';
    }

    return link;
  };

  return (
    <StyledBox>
      <AmgHeadlines headline={content.headline.headline} subHeadline={content.headline.topicLine} className={classes.headline} />
      <div className={classes.root}>
        <AmgSlider className={classes.slider} settings={sliderSettings}>
          {content.sliders && content.sliders.map((slide, index) => {
            return (
              <div className={classes.slide} key={index}>
                {slide.mediaType === 'image' && <AmgImage className={classes.img} content={slide.image} />}
                {slide.mediaType === 'video' && (
                  <AmgVideoDam
                    content={{
                      autoPlay: true,
                      controls: false,
                      exportedVideoPath: slide.videoResourcePath,
                      loop: true,
                      muted: true,
                      posterImage: slide.media.mediaVideoImage.image.fallbackSourceElement.imageUrl,
                    }}
                  />
                )}
                <AmgEmissionStatement content={slide.emissionStatementModel} />
              </div>
            );
          })}
        </AmgSlider>
        <AmgRichText content={content.richText} />
        {['link1', 'link2', 'link3'].map((link, index) => {
          if (!content[link]) {
            return null;
          }

          return (
            <AmgLink
              className={clsx(classes.button)}
              color={link === 'link1' ? 'primary' : 'secondary'}
              eventdetailaction="image-text"
              fullWidth
              key={index}
              link={buildLink(content[link])}
            />
          );
        })}
      </div>
    </StyledBox>
  );
}

export default AmgImageText;

AmgImageText.propTypes = {
  content: PropTypes.object.isRequired,
};
