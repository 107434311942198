import { SET_USER, UPDATE_FILTER } from '../actionTypes';

const initialState = {
  addToHomescreenPopupDisabled: false,
  filters: {},
  preferred_language: 'EN', // Default language when loading the app
};

const user = (state = initialState, action = {}) => {
  if (action.type === SET_USER) {
    const newUser = action.payload;

    // Force EN as default language if no language is set (i.e. Layer A user)
    if (['DE', 'EN'].indexOf(newUser.preferred_language) === -1) {
      newUser.preferred_language = 'EN';
    }

    // Common filters for all layers
    const sharedFilters = {
      'news': true,
      'story': true,
      'event': true,
      'project-one': true,
    };

    // Specific filters for each layer
    const MAP_LAYER_TO_FILTERS = {
      'layer_a': {}, // Not sure if that's 100% necessary?
      'layer_b': {},
      'layer_c': { 'forum': true },
      'layer_d': { 'forum': true },
    };
    // Merge shared filters with user specific layer
    const defaultFilters = { ...sharedFilters, ...MAP_LAYER_TO_FILTERS[newUser.user_layer] };

    newUser.filters = JSON.parse(localStorage.getItem('filters')) || defaultFilters;

    return { ...state, ...newUser };
  } else if (action.type === UPDATE_FILTER) {
    const filters = { ...state.filters };

    filters[action.payload.filter] = !action.payload.isActive;
    localStorage.setItem('filters', JSON.stringify(filters));

    const isFiltered = Object.keys(filters).some((key) => filters[key] === false);
    if (isFiltered) {
      localStorage.setItem('filtersLastUpdate', new Date().getTime());
    } else {
      localStorage.removeItem('filtersLastUpdate');
    }

    return { ...state, filters };
  }
  else {
    return state;
  }
};

export default user;
