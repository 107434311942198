import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateYoutubeList } from '../redux/actions';
import useYouTubeIframeAPI from '../hooks/useYouTubeIframeAPI';

function AmgVideoYoutubePlayer({ content }) {
  const dispatch = useDispatch();
  const isConsentGiven = useSelector((state) => state.ytpconsent.accepted);
  const videos = useSelector((state) => state.ytpconsent.videos);

  const { generatePlayerId } = useYouTubeIframeAPI(isConsentGiven);
  const playerId = generatePlayerId();

  const onPlayerStateChange = useCallback((event) => {
    // When a video starts to play...
    if (event.data === window.YT.PlayerState.PLAYING) {
      // ... pause all videos that are not the current video
      for (const [, video] of Object.entries(videos)) {
        if (video.player.m !== event.target.m) {
          video.player.pauseVideo();
        }
      }
    }
  }, [videos]);

  const loadVideos = useCallback(() => {
    for (const [queuedPlayerId, queuedVideo] of Object.entries(videos)) {
      if (!queuedVideo.player) {
        const player = new window.YT.Player(queuedPlayerId, {
          playerVars: {
            start: queuedVideo.video.startTime || 0,
          },
          videoId: queuedVideo.video.id,
          events: {
            onStateChange: onPlayerStateChange,
          },
        });

        // Save the player instance globally so we can pause it later from any YouTube video component
        dispatch(updateYoutubeList(queuedPlayerId, queuedVideo.video, player));
      }
    }
  }, [dispatch, onPlayerStateChange, videos]);

  useEffect(() => {
    if (isConsentGiven) {
      dispatch(updateYoutubeList(playerId, content));

      if (window.YT) {
        loadVideos();
      } else {
        // Once the YouTube Iframe API is ready...
        window.onYouTubeIframeAPIReady = () => {
          loadVideos();
        };
      }
    }
  }, [content, dispatch, isConsentGiven, loadVideos, playerId]);

  return <div id={playerId}></div>;
}

export default AmgVideoYoutubePlayer;

AmgVideoYoutubePlayer.propTypes = {
  content: PropTypes.object.isRequired,
};
