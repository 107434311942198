import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const PREFIX = 'AmgInputText';

const classes = {
  error: `${PREFIX}-error`,
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.amgRed,
    color: theme.palette.amgWhite,
    display: 'inline-block',
    fontFamily: 'MBCorpoSText-Regular-Web',
    fontSize: '0.875rem', // 14px
    lineHeight: '1rem',
    padding: theme.spacing(0.5, 1),
    margin: 0,
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(1.5, 0),
    '.errorBox + &': {
      marginTop: '6px',
    },
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: `1px solid ${theme.palette.amgWhite}`,
        transform: 'scaleX(1)',
      },
      '&.Mui-error:after': {
        borderColor: theme.palette.amgRed,
      },
    },
  },
}));

function AmgInputText({ error, label, onChange, value }) {

  return (
    (<Root>
      <TextField
        className={classes.root}
        error={!!error}
        fullWidth={true}
        label={label}
        onChange={onChange}
        value={value}
        variant="standard"
      />
      {error && <p className={clsx(classes.error, 'errorBox')}>{error.message}</p>}
    </Root>)
  );
}

export default AmgInputText;

AmgInputText.propTypes = {
  error: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
