import {
  SET_HAS_NEW_POSTS,
  UPDATE_ACTIVITY_STREAM,
} from '../actionTypes';

const initialState = {
  hasNewPosts: false,
  lastTimestamp: 0,
  posts: null,
  scrollTop: 0,
};

const post = (state = initialState, action = {}) => {
  if (action.type === SET_HAS_NEW_POSTS) {
    let hasNewPosts;

    // Don't flag as new posts the first time
    if (state.lastTimestamp === 0) {
      hasNewPosts = false;
    }
    // If the payload is a boolean then just use this value
    else if (typeof action.payload === 'boolean') {
      hasNewPosts = action.payload;
    }
    // If the payload is a timestamp, then compare with the previous one
    else {
      hasNewPosts = action.payload !== state.lastTimestamp;
    }

    return {
      ...state,
      hasNewPosts,
    };
  } else if (action.type === UPDATE_ACTIVITY_STREAM) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};

export default post;
