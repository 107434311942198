import { Link, useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import AmgActivityStream from './AmgActivityStream';
import AmgArticleTeaserOverview from '../components/AmgArticleTeaserOverview';
import AmgComparisonSlider from '../components/AmgComparisonSlider';
import AmgDeviceNotification from '../components/AmgDeviceNotification';
import AmgError from '../components/AmgError';
import AmgEventOverview from '../components/AmgEventOverview';
import AmgFreeMediaGallery from '../components/AmgFreeMediaGallery';
import AmgFullWidthImage from '../components/AmgFullWidthImage';
import AmgHeadlines from '../components/AmgHeadlines';
import AmgHeadlineText from '../components/AmgHeadlineText';
import AmgHeroStage from '../components/AmgHeroStage';
import AmgImageGallery from '../components/AmgImageGallery';
import AmgImageText from '../components/AmgImageText';
import AmgLivestream from '../components/AmgLivestream';
import AmgLogo from '../components/AmgLogo';
import AmgMaintenance from './AmgMaintenance';
import AmgMediaSlider from '../components/AmgMediaSlider';
import AmgMode from '../components/AmgMode';
import AmgParticipation from '../components/AmgParticipation';
import AmgQuickLinks from '../components/AmgQuickLinks';
import AmgSafariInstallHelper from '../components/AmgSafariInstallHelper';
import AmgStage from '../components/AmgStage';
import AmgTabbedContent from '../components/AmgTabbedContent';
import AmgTable from '../components/AmgTable';
import AmgTeaserCombination from '../components/AmgTeaserCombination';
import AmgText from '../components/AmgText';
import AmgVideoDam from '../components/AmgVideoDam';
import AmgVideoOnDemand from '../components/AmgVideoOnDemand';
import AmgVideoProjectOne from '../components/AmgVideoProjectOne';
import AmgVideoYoutube from '../components/AmgVideoYoutube';
import { Box } from '@mui/material';
import AmgNotification from '../components/AmgNotification';

const StyledAmgMode = styled(AmgMode)(() => {});

function AmgTest() {
  const { component, content, mode } = useParams();

  if (component) {
    let json = {};

    if (content) {
      json = require(`../test/content/${content}.json`);
    }

    const comps = {
      AmgActivityStream: <AmgActivityStream content={json} />,
      AmgArticleTeaserOverview: <AmgArticleTeaserOverview content={json} />,
      AmgComparisonSlider: <AmgComparisonSlider content={json} />,
      AmgDeviceNotification: <AmgDeviceNotification />,
      AmgError: <AmgError variant="error" />,
      AmgEventOverview: <AmgEventOverview content={json} />,
      AmgFreeMediaGallery: <AmgFreeMediaGallery content={json} />,
      AmgFullWidthImage: <AmgFullWidthImage content={json} />,
      AmgHeadlineText: <AmgHeadlineText content={json} />,
      AmgHeroStage: <AmgHeroStage content={json} />,
      AmgImageGallery: <AmgImageGallery content={json} />,
      AmgImageText: <AmgImageText content={json} />,
      AmgLivestream: <AmgLivestream content={json} />,
      AmgLogo: <AmgLogo />,
      AmgMaintenance: <AmgMaintenance />,
      AmgMediaSlider: <AmgMediaSlider content={json} />,
      AmgNotification: <AmgNotification />,
      AmgParticipation: <AmgParticipation content={json} />,
      AmgQuickLinks: <AmgQuickLinks content={json} />,
      AmgSafariInstallHelper: <AmgSafariInstallHelper />,
      AmgStage: <AmgStage content={json} />,
      AmgTabbedContent: <AmgTabbedContent content={json} />,
      AmgTable: <AmgTable content={json} />,
      AmgTeaserCombination: <AmgTeaserCombination content={json} />,
      AmgText: <AmgText content={json} />,
      AmgVideoDam: <AmgVideoDam content={json} />,
      AmgVideoOnDemand: <AmgVideoOnDemand content={json} />,
      AmgVideoProjectOne: <AmgVideoProjectOne content={json} />,
      AmgVideoYoutube: <AmgVideoYoutube content={json} />,
    };

    return (
      <StyledAmgMode mode={mode === 'light' ? 'light' : 'dark'}>
        {comps[component]}
      </StyledAmgMode>
    );
  }

  return (
    <AmgMode mode="light">
      <Box sx={{ px: 2 }}>
        <AmgHeadlines headline="Test Page" />
        <ul>
          <li><Link to="/test/AmgActivityStream/activityStream">Activity Stream</Link></li>
          <li><Link to="/test/AmgArticleTeaserOverview/articleTeaserOverviewSlider">Article Teaser Overview with Slider</Link></li>
          <li><Link to="/test/AmgArticleTeaserOverview/articleTeaserOverviewStacked">Article Teaser Overview with Stack</Link></li>
          <li><Link to="/test/AmgComparisonSlider/comparisonSlider">Comparison Slider</Link></li>
          <li><Link to="/test/AmgDeviceNotification">Device Notification</Link></li>
          <li><Link to="/test/AmgError">Error</Link></li>
          <li><Link to="/test/AmgEventOverview/eventOverview">Event overview</Link></li>
          <li><Link to="/test/AmgFreeMediaGallery/freeMediaGallery">Free Media Gallery</Link></li>
          <li><Link to="/test/AmgFullWidthImage/fullWidthImageWithEmissionStatement">Full Width Image With Emission Statement</Link></li>
          <li><Link to="/test/AmgFullWidthImage/fullWidthImageWithEmptyEmissionStatement">Full Width Image With Empty Emission Statement</Link></li>
          <li><Link to="/test/AmgFullWidthImage/fullWidthImageWithoutEmissionStatement">Full Width Image Without Emission Statement</Link></li>
          <li><Link to="/test/AmgHeadlineText/headlineText">Headline Text</Link></li>
          <li><Link to="/test/AmgHeroStage/heroStage">Hero Stage</Link></li>
          <li><Link to="/test/AmgImageGallery/imageGallery3">Image Gallery 3</Link></li>
          <li><Link to="/test/AmgImageGallery/imageGallery6">Image Gallery 6</Link></li>
          <li><Link to="/test/AmgImageText/imageTextWithOneSlide">Image Text With One Slide</Link></li>
          <li><Link to="/test/AmgImageText/imageTextWithThreeSlides">Image Text With Three Slides</Link></li>
          <li><Link to="/test/AmgImageText/imageTextWithNineSlides">Image Text With Nine Slides</Link></li>
          <li><Link to="/test/AmgLivestream/livestream">Livestream</Link></li>
          <li><Link to="/test/AmgLogo">Logo</Link></li>
          <li><Link to="/test/AmgMaintenance">Maintenance</Link></li>
          <li><Link to="/test/AmgMediaSlider/mediaSlider3">Media Slider 3</Link></li>
          <li><Link to="/test/AmgMediaSlider/mediaSlider9">Media Slider 9</Link></li>
          <li><Link to="/test/AmgNotification">Notification</Link></li>
          <li><Link to="/test/AmgParticipation/participation">Participation Module</Link></li>
          <li><Link to="/test/AmgQuickLinks/quickLinks">Quick Links</Link></li>
          <li><Link to="/test/AmgSafariInstallHelper">Safari Installer Helper</Link></li>
          <li><Link to="/test/AmgStage/stage">Stage</Link></li>
          <li><Link to="/test/AmgTabbedContent/tabbedContent">Tabbed Content</Link></li>
          <li><Link to="/test/AmgTable/table">Table</Link></li>
          <li><Link to="/test/AmgTeaserCombination/teaserCombination">Teaser Combination</Link></li>
          <li><Link to="/test/AmgText/text">Text</Link></li>
          <li><Link to="/test/AmgVideoDam/videoDam">Video DAM</Link></li>
          <li><Link to="/test/AmgVideoOnDemand/videoOnDemand">Video On Demand</Link></li>
          <li><Link to="/test/AmgVideoProjectOne/videoProjectOne">Video Project One</Link></li>
          <li><Link to="/test/AmgVideoYoutube/videoYoutube">Video Youtube</Link></li>
        </ul>
      </Box>
    </AmgMode>
  );
}

export default AmgTest;
