import AmgError from './AmgError';
import AmgRoutes from '../AmgRoutes';
import useNetworkStatus from '../hooks/useNetworkStatus';

function AmgNetworkStatus() {
  const { isOffline } = useNetworkStatus();

  if (isOffline) {
    return <AmgError variant="offline" />;
  }

  return <AmgRoutes />;
}

export default AmgNetworkStatus;
