import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AmgArrow from './AmgArrow';
import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import AmgSlider from './AmgSlider';

const PREFIX = 'AmgImageGallery';

const classes = {
  box: `${PREFIX}-box`,
  caption: `${PREFIX}-caption`,
  emission: `${PREFIX}-emission`,
  headline: `${PREFIX}-headline`,
  slide: `${PREFIX}-slide`,
  slider: `${PREFIX}-slider`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`&.${classes.box}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.caption}`]: {
    fontSize: '1rem',
    margin: theme.spacing(2, 2),
  },

  [`& .${classes.emission}`]: {
    margin: theme.spacing(2, 2, 0),
  },

  [`& .${classes.headline}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.slide}`]: {
    position: 'relative',
  },

  [`& .${classes.slider}`]: {
    margin: theme.spacing(3, -2, 4),
  },
}));

function AmgImageGallery({ content }) {

  const gallery = useRef(null);

  const sliderSettings = {
    arrows: true,
    dots: true,
    centerMode: false,
    infinite: false,
    prevArrow: <AmgArrow variant="left" />,
    nextArrow: <AmgArrow variant="right" />,
    onReInit: () => {
      if (!gallery.current) {
        return;
      }

      const currentSlide = gallery.current.querySelector('.slick-active');
      const container = currentSlide.querySelector('img');
      const chevrons = gallery.current.querySelectorAll('.slick-slider .chevron');

      if (container && chevrons) {
        const top = container.offsetTop + (container.height / 2) - 16;
        chevrons.forEach((chevron) => {
          chevron.style.top = `${top}px`;
        });
      }

      if (Number(currentSlide.dataset.index) === 0) {
        chevrons[0].style.display = 'none';
      } else {
        chevrons[0].style.display = 'block';
      }
      if (Number(currentSlide.dataset.index) === content.imageList.length - 1) {
        chevrons[1].style.display = 'none';
      } else {
        chevrons[1].style.display = 'block';
      }
    },
  };

  return (
    <StyledBox ref={gallery} className={classes.box}>
      {content.headline && (
        <AmgHeadline variant="subHeadline" className={classes.headline}>
          {content.headline}
        </AmgHeadline>
      )}
      <Typography component="p">
        {content.text}
      </Typography>
      <AmgSlider className={classes.slider} settings={sliderSettings}>
        {content.imageList.map((image, index) => {
          return (
            <div className={classes.slide} key={index}>
              <AmgImage content={image} />
              <Typography component="p" className={classes.caption}>
                {content[`image${index + 1}ValueMap`].caption}
              </Typography>
              <AmgEmissionStatement content={content.emissionStatement} className={classes.emission} />
            </div>
          );
        })}
      </AmgSlider>
    </StyledBox>
  );
}

export default AmgImageGallery;

AmgImageGallery.propTypes = {
  content: PropTypes.object.isRequired,
};
