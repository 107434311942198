import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { InputLabel, NativeSelect } from '@mui/material';

const PREFIX = 'AmgSelectBox';

const classes = {
  label: `${PREFIX}-label`,
  root: `${PREFIX}-root`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.label}`]: {
    fontFamily: 'MBCorpoSTitle-Bold-Web',
    fontSize: '0.875rem',
  },

  [`&.${classes.root}`]: {
    margin: '12px 0',
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: `1px solid ${theme.palette.amgWhite}`,
        transform: 'scaleX(1)',
      },
      '&.Mui-error:after': {
        borderColor: theme.palette.amgRed,
      },
    },
  },
}));

function AmgSelectBox({ entries, firstOptionLabel, name, onChange, selectLabel, value }) {

  const sbID = `selectbox-${Math.floor(Math.random() * 1000)}`;

  return (
    <Root className={classes.root}>
      <InputLabel variant="standard" htmlFor={sbID} className={classes.label}>
        {selectLabel}
      </InputLabel>
      <NativeSelect
        fullWidth={true}
        onChange={onChange}
        value={value}
        inputProps={{
          name: name,
          id: sbID,
        }}
      >
        {/* first option element for the plz choose entry as implemented on homepage as well */}
        <option value="pleaseChoose">{firstOptionLabel}</option>
        {
          Object.keys(entries).map((index) => {
            const country = entries[index];
            return (
              <option key={index} value={country.value}>{country.text}</option>
            );
          })
        }
      </NativeSelect>
    </Root>
  );
}

export default AmgSelectBox;

AmgSelectBox.propTypes = {
  entries: PropTypes.array.isRequired,
  firstOptionLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selectLabel: PropTypes.string,
  value: PropTypes.string,
};
