import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const PREFIX = 'AmgHeadline';

const classes = {
  headline: `${PREFIX}-headline`,
  headlineSlider: `${PREFIX}-headlineSlider`,
  subHeadline: `${PREFIX}-subHeadline`,
};

const StyledTypography = styled(Typography)(() => ({
  [`&.${classes.headline}`]: {
    fontSize: '1rem',
    lineHeight: 1,
  },

  [`&.${classes.headlineSlider}`]: {
    fontFamily: 'MBCorpoSTitle-Bold-Web',
    fontSize: '1.25rem',
    lineHeight: 1.1,
  },

  [`&.${classes.subHeadline}`]: {
    fontFamily: 'MBCorpoSTitle-ExtraBold-Web',
    fontSize: '30px',
    lineHeight: 1,
    textTransform: 'uppercase',
  },
}));

function AmgHeadline({ children, className, component, variant }) {

  return (
    <StyledTypography className={clsx(className, classes[variant])} component={component}>
      {children}
    </StyledTypography>
  );
}

export default AmgHeadline;

AmgHeadline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  className: PropTypes.string,
  component: PropTypes.string,
  variant: PropTypes.string.isRequired,
};
