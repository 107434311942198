import { useEffect, useState } from 'react';
import useTracking from './useTracking';

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(true);
  const { trackEvent } = useTracking();

  useEffect(() => {
    const track = (networkStatus) => {
      trackEvent({
        event: networkStatus,
        eventDetail: {
          action: networkStatus,
          category: networkStatus,
          label: networkStatus,
        },
      });
    };

    const setOnline = () => {
      track('online');
      setIsOnline(true);
    };

    const setOffline = () => {
      track('offline');
      setIsOnline(false);
    };

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, [trackEvent]);

  return { isOnline, isOffline: !isOnline };
}

export default useNetworkStatus;
