import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import AmgChip from './AmgChip';

import {
  setHasNewPosts as setHasNewPostsAction,
  updateActivityStream as updateActivityStreamAction,
} from '../redux/actions';
import { buildContentSearchUrl, fetchHandler, getMaxTimestampFromPosts } from '../utils';
import useTranslate from '../hooks/useTranslate';

const mapStateToProps = (state) => {
  return {
    lang: state.user.preferred_language,
    post: state.post,
  };
};

function AmgActivityStream({ lang, post, setHasNewPosts, setIsFetching, updateActivityStream }) {
  const translate = useTranslate();

  // Fetch posts when the user clicks on the "New Post" chip
  const fetchNewPosts = useCallback(async (event) => {
    const isPullingDown = event?.detail?.action === 'pullDown' ? true : false;
    const startFetchTime = Date.now();
    let delay = 0;

    // Clear the bubble before fetching again
    setHasNewPosts(false);

    // Show the spinner
    if (isPullingDown) {
      setIsFetching(true);
    }

    const url = buildContentSearchUrl({ page: 0 });
    const res = await fetchHandler(url);
    // "results" can be undefined if there are no results
    res.results = res.results || [];
    const newPosts = [];
    // Order all posts by timestamp ASC
    const sorted = [...res.results].sort((post1, post2) => post1.timestamp - post2.timestamp);

    sorted.forEach((fetchedPost) => {
      // If the fetched post is newer than the last post, add it at the beginning of the array
      if (fetchedPost.timestamp > post.lastTimestamp) {
        newPosts.unshift(fetchedPost);
      }
    });

    // Wait at least 1s before updating the activity stream to avoid a short flash of the spinner
    if (isPullingDown) {
      delay = 1000 + startFetchTime - Date.now();
    }

    setTimeout(() => {
      // Concatenate the new posts at the beginning of the existing posts
      updateActivityStream({
        lastTimestamp: getMaxTimestampFromPosts(res.results),
        posts: newPosts.concat(post.posts),
      });
      setIsFetching(false);
    }, delay);
  }, [post.lastTimestamp, post.posts, setHasNewPosts, setIsFetching, updateActivityStream]);

  const handleClickChip = () => {
    fetchNewPosts();
    setHasNewPosts(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('refresh', fetchNewPosts);

    return () => {
      window.removeEventListener('refresh', fetchNewPosts);
    };
  }, [fetchNewPosts]);

  if (!post.hasNewPosts) {
    return null;
  }

  return <AmgChip label={translate('pwa.activityStream.newPosts')} onClick={handleClickChip} />;
}

export default connect(
  mapStateToProps,
  {
    setHasNewPosts: setHasNewPostsAction,
    updateActivityStream: updateActivityStreamAction,
  },
)(AmgActivityStream);

AmgActivityStream.propTypes = {
  lang: PropTypes.string.isRequired,
  post: PropTypes.object.isRequired,
  setHasNewPosts: PropTypes.func.isRequired,
  setIsFetching: PropTypes.func.isRequired,
  updateActivityStream: PropTypes.func.isRequired,
};
