import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AmgSafariInstallHelperDialog from './AmgSafariInstallHelperDialog';
import { IsIosSafari, isPWAOptimized, isStandalone } from '../utils';

const mapStateToProps = (state) => {
  return {
    addToHomescreenPopupDisabled: state.user.addToHomescreenPopupDisabled,
  };
};

function AmgSafariInstallHelper({ addToHomescreenPopupDisabled }) {
  const isDisabled = typeof addToHomescreenPopupDisabled === 'undefined' ? false : !addToHomescreenPopupDisabled;
  const showDialog = !window.sessionStorage.getItem('addToHomescreen') && IsIosSafari() && !isStandalone() && isPWAOptimized() && isDisabled;

  return showDialog ? <AmgSafariInstallHelperDialog /> : null;
}

export default connect(
  mapStateToProps,
)(AmgSafariInstallHelper);

AmgSafariInstallHelper.propTypes = {
  addToHomescreenPopupDisabled: PropTypes.bool.isRequired,
};
