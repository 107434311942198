import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

import AmgButton from './AmgButton';

const PREFIX = 'AmgLivestream';

const classes = {
  video: `${PREFIX}-video`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.video}`]: {
    width: '100%',
  },
}));

function AmgLivestream({ content }) {

  const [videoReady, setVideoReady] = useState(false);
  const videoRef = useRef();

  const handleClick = () => {
    videoRef.current.play();
  };

  useEffect(() => {
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(content.url);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          setVideoReady(true);
        });
      });
    }
  }, [content.url]);

  return (
    (<Root>
      <video className={classes.video} ref={videoRef}></video>
      <AmgButton onClick={handleClick} disabled={!videoReady}>Play</AmgButton>
    </Root>)
  );
}

export default AmgLivestream;

AmgLivestream.propTypes = {
  content: PropTypes.object.isRequired,
};
