import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
const PREFIX = 'AmgVideoDam';

const classes = {
  video: `${PREFIX}-video`,
};

const Root = styled('video')((
  {
    theme,
  }
) => ({
  [`&.${classes.video}`]: {
    width: '100%',
  },
}));

function AmgVideoDam({ content }) {

  // Split the string with '.' and return the last array element to get the file extension
  const fileExtension = content.exportedVideoPath.split('.').pop();

  // define attributes for the video component
  const customProperties = {
    autoPlay: typeof content.autoPlay !== 'undefined' ? content.autoPlay : false,
    controls: typeof content.controls !== 'undefined' ? content.controls : true,
    loop: typeof content.loop !== 'undefined' ? content.loop : false,
    muted: typeof content.muted !== 'undefined' ? content.muted : false,
  };

  return (
    <Root
      {...customProperties}
      poster={content.posterImage}
      className={classes.video}
      data-testid="AmgVideoDam"
    >
      <source
        src={content.exportedVideoPath}
        type={`video/${fileExtension}`}
      />
      Sorry, your browser does not support embedded videos.
    </Root>
  );
}

export default AmgVideoDam;

AmgVideoDam.propTypes = {
  content: PropTypes.object.isRequired,
};
