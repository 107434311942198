import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import useRouteListener from './hooks/useRouteListener';
import useTracking from './hooks/useTracking';
import AmgActivityStream from './pages/AmgActivityStream';
import AmgDetails from './pages/AmgDetails';
import AmgLanguage from './pages/AmgLanguage';
import AmgMaintenance from './pages/AmgMaintenance';
import AmgPage from './pages/AmgPage';
import AmgPrivacy from './pages/AmgPrivacy';
import AmgProfile from './pages/AmgProfile';
import AmgSettings from './pages/AmgSettings';
import AmgTest from './pages/AmgTest';

function AmgRoutes() {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  useRouteListener();

  useEffect(() => {
    // Track all clicks on <a> tags with a parent .amg-tracked class
    const track = (clickEvent) => {
      const anchor = clickEvent.target.closest('a');
      const trackedElement = clickEvent.target.closest('.amg-tracked');

      // Track only clicks on <a> elements if a parent has a ".amg-tracked" class
      if (anchor !== null && trackedElement !== null) {
        trackEvent({
          event: 'click',
          eventDetail: {
            action: 'content',
            category: 'link',
            label: anchor.textContent,
          },
        });
      }
    };

    const trackHomescreen = (label) => () => {
      trackEvent({
        event: 'click',
        eventDetail: {
          action: 'homescreen-info',
          category: 'link',
          label: label,
        },
      });
    };

    // Redirect the user where he was before login
    const redirect = localStorage.getItem('redirect');
    if (redirect) {
      localStorage.removeItem('redirect');
      navigate(redirect.substring(1));
    }

    const main = document.querySelector('main');
    if (main) {
      main.addEventListener('click', track);
    }
    // Tracking of "Add to Home Screen" on Android
    window.addEventListener('appinstalled', trackHomescreen('installed'));
    window.addEventListener('beforeinstallprompt', trackHomescreen('displayed'));

    trackEvent({ event: 'global' });

    // user comes from ciam, trigger a click event to gtm
    const justLoggedIn = window.localStorage.getItem('just_logged_in');
    if (justLoggedIn) {
      window.localStorage.removeItem('just_logged_in');
      trackEvent({
        event: 'click',
        eventDetail: {
          category: 'login',
          action: 'mercedes-me',
          label: 'login-successful',
        },
      });
    }

    return () => {
      if (main) {
        main.removeEventListener('click', track);
      }
      window.removeEventListener('appinstalled', trackHomescreen('installed'));
      window.removeEventListener('beforeinstallprompt', trackHomescreen('displayed'));
    };
  }, [navigate, trackEvent]);

  return (
    <main>
      <Routes>
        <Route path="/details" element={<AmgDetails />} />
        <Route path="/language" element={<AmgLanguage />} />
        <Route path="/maintenance" element={<AmgMaintenance />} />
        <Route path="/page/:url" element={<AmgPage />} />
        <Route path="/event/:url" element={<AmgPage isEvent={true} />} />
        <Route path="/privacy" element={<AmgPrivacy />} />
        <Route path="/profile" element={<AmgProfile />} />
        <Route path="/settings" element={<AmgSettings />} />
        <Route path="/test" element={<AmgTest />}>
          <Route path=":component" element={<AmgTest />}>
            <Route path=":content" element={<AmgTest />}>
              <Route path=":mode" element={<AmgTest />} />
            </Route>
          </Route>
        </Route>
        <Route path="/" element={<AmgActivityStream />} />
      </Routes>
    </main>
  );
}

export default AmgRoutes;
