import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { updateFilter } from '../redux/actions';
import useTranslate from '../hooks/useTranslate';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgDrawerItem';

const classes = {
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  listItem: `${PREFIX}-listItem`,
  listItemText: `${PREFIX}-listItemText`,
  selected: `${PREFIX}-selected`,
  text: `${PREFIX}-text`,
};

const StyledListItem = styled(ListItem)((
  {
    theme,
  }
) => ({
  [`&.${classes.button}`]: {
    padding: 0,
    transition: 'none', // Prevent from a background flickering while closing the drawer
  },

  [`& .${classes.icon}`]: {
    height: 15,
    minWidth: 15,
    marginRight: theme.spacing(3),
    position: 'relative',
    width: 15,
  },

  [`&.${classes.listItem}`]: {
    '&:before, &:after': {
      backgroundColor: theme.palette.amgWhite,
      content: '""',
      position: 'absolute',
      top: '50%',
      right: theme.spacing(3),
      height: 2,
      transition: 'transform 300ms cubic-bezier(.75,-0.6,.14,1.59) 150ms',
      width: 15,
    },
    '&:before': {
      transform: 'translate(0, 0) rotate(-90deg) scale(1, 1)',
    },
    '&:after': {
      transform: 'translate(0, 0) rotate(180deg) scale(1, 1)',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.amgRed,

      '&:before, &:after': {
        transitionDuration: '150ms',
        transitionTimingFunction: 'ease-out',
      },
      '&:before': {
        transform: 'translate(0) rotate(-45deg) scale(1, 1)',
      },
      '&:after': {
        transform: 'translate(-6.3px, 3px) rotate(45deg) scale(.43, 1)',
      },
    },
  },

  [`& .${classes.listItemText}`]: {
    margin: 0,
  },

  [`& .${classes.text}`]: {
    fontFamily: 'MBCorpoSTitle-ExtraBold-Web',
    fontSize: '18px',
    lineHeight: '20px',
    padding: theme.spacing(2.5, 3),
    textTransform: 'uppercase',
  },
}));

function AmgDrawerItem({ filterKey, isActive }) {
  const dispatch = useDispatch();
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const label = translate(`pwa.settings.filter.${filterKey}`);

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'drawer',
        category: 'navigation click',
        label,
      },
    });

    dispatch(updateFilter(filterKey, isActive));
  };

  return (
    <StyledListItem
      button
      classes={{
        button: classes.button,
        root: classes.listItem,
        selected: classes.selected,
      }}
      selected={isActive}
      onClick={handleClick}
    >
      <ListItemText
        classes={{
          primary: classes.text,
          root: classes.listItemText,
        }}
        primary={label}
      />
      <ListItemIcon classes={{ root: classes.icon }} />
    </StyledListItem>
  );
}

export default AmgDrawerItem;

AmgDrawerItem.propTypes = {
  filterKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};
