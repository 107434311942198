import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AmgActivityStreamForum from './AmgActivityStreamForum';
import AmgActivityStreamEvent from './AmgActivityStreamEvent';
import AmgActivityStreamNews from './AmgActivityStreamNews';
import useEmissionStatement from '../hooks/useEmissionStatement';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgActivityStreamPost';

const classes = {
  dark: `${PREFIX}-dark`,
  light: `${PREFIX}-light`,
  post: `${PREFIX}-post`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.dark}`]: {
    background: theme.palette.amgMineshaftGrey,
  },

  [`&.${classes.light}`]: {
    background: theme.palette.amgWhite,
    color: theme.palette.amgBlack,
  },

  [`&.${classes.post}`]: {
    margin: theme.spacing(0, 2, 2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
  },
}));

const activityStreamComponents = {
  'forum': AmgActivityStreamForum,
  'news': AmgActivityStreamNews,
  'project-one': AmgActivityStreamNews,
  'story': AmgActivityStreamNews,
  'event': AmgActivityStreamEvent,
};

function AmgActivityStreamPost({ content }) {

  const navigate = useNavigate();
  const ref = useRef();
  const { trackEvent } = useTracking();
  content.emissions = useEmissionStatement(content.category !== 'event' ? content : null);
  const AmgComponent = activityStreamComponents[content.category];

  const handleClick = (item, clickEvent) => {
    clickEvent.preventDefault();

    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'activity-stream',
        category: 'link',
        label: `${content.category}: ${content.title}`,
      },
    });

    const MAP_CATEGORY_TO_URL = {
      'forum': 'url',
      'news': 'pageLink',
      'project-one': 'pageLink',
      'story': 'pageLink',
      'event': 'detailViewUrl',
    };
    const url = clickEvent.target.href ? clickEvent.target.href : item[MAP_CATEGORY_TO_URL[item.category]];

    if (url.includes('.html')) {
      window.open(url, '_blank');
    } else {
      item.category === 'event' ? navigate(`/event/${encodeURIComponent(url)}`) : navigate(`/page/${encodeURIComponent(url)}`);
    }
  };

  useEffect(() => {
    const current = ref.current;

    const callbackFunction = (entries) => {
      const [ entry ] = entries;
      if (entry.isIntersecting) {
        trackEvent({
          event: 'click',
          eventDetail: {
            action: 'activity-stream',
            category: 'impression',
            label: `${content.category}: ${content.title}`,
          },
        });
        observer.unobserve(current);
      }
    };

    const options = {
      rootMargin: '-60px', // Height of the AppBar and Bottom Navigation
    };

    const observer = new IntersectionObserver(callbackFunction, options);

    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [content.category, content.title, trackEvent]);

  return (
    <Root
      className={clsx({
        [classes.post]: true,
        [classes.dark]: content.category !== 'forum',
        [classes.light]: content.category === 'forum',
      })}
      onClick={(clickEvent) => handleClick(content, clickEvent)}
      ref={ref}
      role="link"
    >
      <AmgComponent content={content} />
    </Root>
  );
}

export default AmgActivityStreamPost;

AmgActivityStreamPost.propTypes = {
  content: PropTypes.object.isRequired,
};
