import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import Slider from '@mui/material/Slider';

import AmgComparisonSliderThumb from './AmgComparisonSliderThumb';
import AmgImage from './AmgImage';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgComparisonSliderSlides';

const classes = {
  img: `${PREFIX}-img`,
  mask: `${PREFIX}-mask`,
  placeholder: `${PREFIX}-placeholder`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.img}`]: {
    maxWidth: 'unset',
    position: 'absolute',
    width: '100vw',
  },

  [`& .${classes.mask}`]: {
    borderRight: `1px solid ${theme.palette.amgGreyDisabled}`,
    height: 'calc(100vw * 9 / 16)',
    overflow: 'hidden',
    position: 'absolute',
  },

  [`& .${classes.placeholder}`]: {
    height: 'calc(100vw * 9 / 16)',
  },
}));

function AmgComparisonSliderSlides({ index, value, values }) {

  const [percent, setPercent] = useState(50);
  const [prevPercent, setPrevPercent] = useState(50);
  const { trackEvent } = useTracking();

  // Callback function that is fired when the slider's value changed.
  const handleChange = (event, newPercent) => {
    setPercent(newPercent);
  };

  // Callback function that is fired when the mouseup is triggered.
  const handleChangeCommitted = (event, newPercent) => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'comparison-slider',
        category: 'link',
        label: `slider|${newPercent > prevPercent ? 'swipe-right' : 'swipe-left'}`,
      },
    });

    setPrevPercent(newPercent);
  };

  return (
    (<Root>
      {value === index && (
        <div className={classes.placeholder}>
          <AmgImage content={values.right.image} className={classes.img} skeleton={false} />
          <div className={classes.mask} style={{ width: `${percent}%` }}>
            <AmgImage content={values.left.image} className={classes.img} skeleton={false} />
          </div>
        </div>
      )}
      <Slider
        components={{
          Thumb: AmgComparisonSliderThumb,
        }}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        track={false}
        value={percent}
      />
    </Root>)
  );
}

export default AmgComparisonSliderSlides;

AmgComparisonSliderSlides.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};
