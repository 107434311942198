import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgDivider from './AmgDivider';
import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import AmgSlider from './AmgSlider';
import { buildHref, cleanHTML } from '../utils';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgTeaserCombination';

const classes = {
  root: `${PREFIX}-root`,
  headline: `${PREFIX}-headline`,
  teasers: `${PREFIX}-teasers`,
  teaser: `${PREFIX}-teaser`,
  teaserImage: `${PREFIX}-teaserImage`,
  teaserArrow: `${PREFIX}-teaserArrow`,
  teaserTopicLine: `${PREFIX}-teaserTopicLine`,
  teaserHeadline: `${PREFIX}-teaserHeadline`,
  teaserText: `${PREFIX}-teaserText`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.headline}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.teasers}`]: {
    margin: theme.spacing(0, -2),
  },

  [`& .${classes.teaser}`]: {
    position: 'relative',
    padding: theme.spacing(0, 0.5),
    color: theme.palette.amgWhite,
    textDecorationLine: 'none',
  },

  [`& .${classes.teaserImage}`]: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.teaserArrow}`]: {
    float: 'right',
    fontSize: '2em',
    transform: 'scaleY(1.3)',
  },

  [`& .${classes.teaserTopicLine}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.teaserHeadline}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.teaserText}`]: {
    marginBottom: theme.spacing(1),
  },
}));

function AmgTeaserCombination({ content }) {

  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const handleClick = (teaser) => {
    const href = buildHref(teaser.link.attributeMap.href);

    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'teaser',
        category: 'link',
        destinationUrl: decodeURIComponent(href),
        label: teaser.headline,
      },
    });

    if (href.startsWith('https') || href.endsWith('.html')) {
      window.open(href, '_blank');
    } else {
      navigate(`/page/${encodeURIComponent(href)}`);
    }
  };

  return (
    <Root className={classes.root}>
      {content.headline.topicLine && (
        <AmgHeadline variant="headline">
          {content.headline.topicLine}
        </AmgHeadline>
      )}
      {content.headline.separator && (<AmgDivider />)}
      {content.headline.headline && (
        <AmgHeadline variant="subHeadline" className={classes.headline}>
          {content.headline.headline}
        </AmgHeadline>
      )}
      <AmgSlider className={classes.teasers} settings={{ infinite: false, dots: true, eventDetailAction: 'teaser' }}>
        {content.teasers && content.teasers.map((teaser, index) => {
          return (
            <Box className={classes.teaser} onClick={() => handleClick(teaser)} key={index}>
              {teaser.subHeadline && (
                <AmgHeadline variant="headline" className={classes.teaserTopicLine}>
                  {teaser.subHeadline}
                </AmgHeadline>
              )}
              <AmgImage className={classes.teaserImage} content={teaser.image} />
              <ChevronRightIcon className={classes.teaserArrow} />
              <AmgHeadline variant="headlineSlider" className={classes.teaserHeadline}>
                {teaser.headline}
              </AmgHeadline>
              <Typography className={classes.teaserText} component="p">
                {teaser.text}
              </Typography>
              <AmgEmissionStatement content={teaser.emissionStatement} />
            </Box>
          );
        })}
      </AmgSlider>
      <div className={classes.text} dangerouslySetInnerHTML={{ __html: cleanHTML(content.richText?.markup) }} />
    </Root>
  );
}

export default AmgTeaserCombination;

AmgTeaserCombination.propTypes = {
  content: PropTypes.object.isRequired,
};
