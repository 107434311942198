import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { useSelector } from 'react-redux';

function useDateBeautifier(timestamp, format = 'long', forceDate = false) {
  const lang = useSelector((state) => state.user.preferred_language);

  // get language for correct locale formatting
  moment.locale(lang.toLowerCase());
  // utc
  const currentUtcTimeStamp = moment.utc().valueOf();
  // if no more than 24h since post created
  if (!forceDate && (currentUtcTimeStamp - timestamp) <= (24 * 60 * 60 * 1000)) {
    format = 'timestamp';
  }

  switch (format) {
    case 'long': // 24. Mär 2018 - 15:59 Uhr || Mar 21, 2019 - 9:01 PM
      return lang === 'DE' ? `${moment.utc(timestamp).local().format('DD. MMM YYYY - HH:mm')} Uhr` : moment.utc(timestamp).local().format('MMM DD, YYYY - HH:mm A');
    case 'noyear': // 24. Mär ||  Mar 21
      return lang === 'DE' ? `${moment.utc(timestamp).local().format('DD. MMM')}` : moment.utc(timestamp).local().format('MMM DD');
    case 'timeonly': // 15:59 Uhr || 9:01 PM
      return lang === 'DE' ? `${moment.utc(timestamp).local().format('HH:mm')} Uhr` : `${moment.utc(timestamp).local().format('hh:mm a')}`;
    case 'timestamp':
      return moment.utc(timestamp).from(moment.utc());
    default: // 24. Mär 2018 || Mar 21, 2019
      return lang === 'DE' ? moment.utc(timestamp).local().format('DD. MMM YYYY') : moment.utc(timestamp).local().format('MMM DD, YYYY');
  }
}

export default useDateBeautifier;
