import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { IsIosSafari } from '../utils';

const PREFIX = 'AmgActivityStreamSpinner';

const classes = {
  loading: `${PREFIX}-loading`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.loading}`]: {
    animation: 'loading-animation 1s infinite linear',
    display: 'flex',
    height: 32,
    left: 'calc(50% - 16px)',
    margin: theme.spacing(2, 0, 0),
    position: 'absolute',
    width: 32,
  },
}));

function AmgActivityStreamSpinner({ children, isFetching }) {

  if (IsIosSafari()) {
    return (
      (<Root>
        <img src="./loading.svg" alt="Loading" className={classes.loading} />
        <div className={clsx({ 'is-fetching': isFetching })}>
          {children}
        </div>
      </Root>)
    );
  }

  return children;
}

export default AmgActivityStreamSpinner;

AmgActivityStreamSpinner.propTypes = {
  children: PropTypes.node.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
