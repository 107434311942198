import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import AmgArticleTeaser from './AmgArticleTeaser';
import AmgHeadlines from './AmgHeadlines';
import AmgSlider from './AmgSlider';

const PREFIX = 'AmgArticleTeaserOverview';

const classes = {
  headlines: `${PREFIX}-headlines`,
  slider: `${PREFIX}-slider`,
  stack: `${PREFIX}-stack`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`& .${classes.headlines}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.slider}`]: {
    paddingRight: theme.spacing(1),
    transform: `translateX(-${theme.spacing(1)})`,
  },

  [`& .${classes.stack}`]: {
    background: theme.palette.amgMineshaftGrey,
    margin: theme.spacing(0, 2, 2),
  },
}));

function AmgArticleTeaserOverview({ content }) {

  const Slider = () => {
    return (
      <AmgSlider settings={{ centerPadding: 0, dots: true, infinite: false, eventDetailAction: 'teaser' }}>
        {content.validArticleTeasers.map((slide, index) => {
          return <AmgArticleTeaser key={index} className={classes.slider} content={slide} variant="slider" />;
        })}
      </AmgSlider>
    );
  };

  const Stack = () => {
    return content.validArticleTeasers.map((teaser, index) => {
      return <AmgArticleTeaser className={classes.stack} key={index} content={teaser} variant="stack" />;
    });
  };

  return (
    <StyledBox>
      <AmgHeadlines className={classes.headlines} headline={content.headline} subHeadline={content.topline} />
      {content.pwaDisplay === 'slider' ? <Slider /> : <Stack />}
    </StyledBox>
  );
}

export default AmgArticleTeaserOverview;

AmgArticleTeaserOverview.propTypes = {
  content: PropTypes.object.isRequired,
};
