import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'AmgLabel';

const classes = {
  root: `${PREFIX}-root`,
  forum: `${PREFIX}-forum`,
  event: `${PREFIX}-event`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  // Default style for all labels
  [`&.${classes.root}`]: {
    alignItems: 'center',
    background: theme.palette.amgWhite,
    color: theme.palette.amgBlack,
    display: 'flex',
    height: 30,
    lineHeight: '1rem',
    padding: theme.spacing(0, 2),
    position: 'absolute',
    textTransform: 'capitalize',
    top: 20,
  },

  // If type="forum"
  [`&.${classes.forum}`]: {
    background: theme.palette.amgRed,
    color: theme.palette.amgWhite,
    display: 'inline-block',
    lineHeight: '1.875rem', // 30px
    marginBottom: theme.spacing(2),
    position: 'relative',
  },

  // If type="event"
  [`&.${classes.event}`]: {
    background: theme.palette.amgRed,
    color: theme.palette.amgWhite,
    lineHeight: '1.875rem', // 30px
    marginBottom: theme.spacing(2),
  },
}));

function AmgLabel({ className, type }) {
  return (
    <Root className={clsx(classes.root, classes[type], className)}>
      {type.replace('-', ' ')}
    </Root>
  );
}

export default AmgLabel;

AmgLabel.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
};
