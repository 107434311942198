import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import AmgMode from '../components/AmgMode';
import AmgEventOverview from '../components/AmgEventOverview';
import useTracking from '../hooks/useTracking';
import { setPage, updateYoutubeList } from '../redux/actions';
import { fetchHandler, renderComponents } from '../utils';

function AmgPage({ isEvent }) {
  const { url } = useParams();
  const page = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const endpoint = decodeURIComponent(url);
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (endpoint.startsWith('test')) {
      const json = require(`../test/content/${endpoint.split('/')[1]}.json`);
      dispatch(setPage(json));
    } else {
      fetchHandler(endpoint).then((res) => {
        dispatch(setPage(res));
        trackEvent({
          event: 'pageview',
          page: {
            title: res.title,
            type: res[':type'],
            url: decodeURIComponent(url),
            ...res.tracking,
          },
        });
      });
    }

    return () => {
      dispatch(setPage({}));
      dispatch(updateYoutubeList(null));
    };
  }, [dispatch, endpoint, trackEvent, url]);
  if (page[':items']) {
    return (
      <AmgMode className="amg-page" mode="dark">
        {renderComponents(page)}
      </AmgMode>
    );
  } else if (Object.keys(page).length && isEvent) {
    return (
      <AmgMode className="amg-page" mode="dark">
        <AmgEventOverview content={page} key={page.id} />
      </AmgMode>
    );
  }

  return null;
}

export default AmgPage;
AmgPage.propTypes = {
  isEvent: PropTypes.bool,
};
