import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import AmgButton from './AmgButton';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgFilterDialog';

const classes = {
  actions: `${PREFIX}-actions`,
  content: `${PREFIX}-content`,
  dialogPaper: `${PREFIX}-dialogPaper`,
};

const StyledDialog = styled(Dialog)((
  {
    theme,
  }
) => ({
  [`& .${classes.actions}`]: {
    padding: theme.spacing(0, 2, 2),
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.dialogPaper}`]: {
    backgroundColor: theme.palette.amgObsidian,
    margin: theme.spacing(2),
    opacity: 0.95,
    width: '100%',
  },
}));

function AmgFilterDialog() {

  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const filters = useSelector((state) => state.user.filters);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (Object.values(filters).every((isActive) => isActive === false)) {
      setOpen(true);
    }
  }, [filters]);

  return (
    <StyledDialog
      open={open}
      PaperProps={{
        square: true,
        classes: {
          root: classes.dialogPaper,
        },
      }}
    >
      <DialogContent className={classes.content}>
        <Typography className={classes.description}>
          {translate('pwa.settings.dialog.filter.text')}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <AmgButton onClick={handleClose}>
          {translate('pwa.settings.dialog.filter.confirm')}
        </AmgButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default AmgFilterDialog;
