import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';

import AmgActivityStreamForumAvatar from './AmgActivityStreamForumAvatar';
import AmgHeadline from './AmgHeadline';
import AmgLabel from './AmgLabel';
import { cleanHTML, maxChar } from '../utils';

import useDateBeautifier from '../hooks/useDateBeautifier';

const PREFIX = 'AmgActivityStreamForum';

const classes = {
  date: `${PREFIX}-date`,
  emission: `${PREFIX}-emission`,
  headline: `${PREFIX}-headline`,
  img: `${PREFIX}-img`,
  info: `${PREFIX}-info`,
  post: `${PREFIX}-post`,
  text: `${PREFIX}-text`,
  textWrapper: `${PREFIX}-textWrapper`,
  username: `${PREFIX}-username`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.date}`]: {
    color: theme.palette.amgDarkGrey,
    fontSize: '0.875rem', // 14px
  },

  [`& .${classes.emission}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.headline}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.img}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.info}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  [`& .${classes.post}`]: {
    margin: theme.spacing(2, 2, 0),

    '& h1, & h2, & h3, & h4': {
      fontFamily: 'MBCorpoSText-Bold-Web',
      fontWeight: 700,
      margin: theme.spacing(0, 0, 1, 0),
      textTransform: 'none',
    },
    '& h1': {
      fontSize: '1.375rem', // 22px
      lineHeight: '1.5rem', // 24px
    },
    '& h2': {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.375rem', // 22px
    },
    '& h3': {
      fontSize: '1.125rem', // 18px
      lineHeight: '1.25rem', // 20px
    },
    '& h4': {
      fontSize: '1rem', // 16px
      lineHeight: '1.3125rem', // 21px
    },
  },

  [`& .${classes.text}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.textWrapper}`]: {
    display: 'flex',
    marginTop: 16,
  },

  [`& .${classes.username}`]: {
    color: theme.palette.amgBlack,
    fontSize: '0.875rem', // 14px
  },
}));

const mapStateToProps = (state) => {
  return {
    lang: state.user.preferred_language,
  };
};

function AmgActivityStreamForum({ content }) {
  const date = useDateBeautifier(content.timestamp);

  return (
    (<Root>
      <AmgLabel type={content.category} />
      <AmgHeadline
        className={classes.headline}
        component="div"
        variant="headlineSlider"
      >
        {content.title}
      </AmgHeadline>
      <div className={classes.textWrapper}>
        <AmgActivityStreamForumAvatar src={content.avatarref} />
        <div className={classes.info}>
          <div className={classes.username}>{content.author}</div>
          <div className={classes.date}>{date}</div>
        </div>
      </div>
      <div className={classes.post} dangerouslySetInnerHTML={{ __html: maxChar(cleanHTML(content.text), 200) }} />
    </Root>)
  );
}

export default connect(
  mapStateToProps,
)(AmgActivityStreamForum);

AmgActivityStreamForum.propTypes = {
  content: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};
