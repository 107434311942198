import { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ReactComponent as LocationIcon } from '../svg/location.svg';
import { ReactComponent as TimeIcon } from '../svg/time.svg';
import Box from '@mui/material/Box';
import { cleanHTML } from '../utils';
import useDateBeautifier from '../hooks/useDateBeautifier';

import AmgButton from '../components/AmgButton';
import AmgEventDialog from '../components/AmgEventDialog';
import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgEventOverview';

const classes = {
  containerBox: `${PREFIX}-containerBox`,
  summaryBox: `${PREFIX}-summaryBox`,
  headerBox: `${PREFIX}-headerBox`,
  headline: `${PREFIX}-headline`,
  introText: `${PREFIX}-introText`,
  stack: `${PREFIX}-stack`,
  icon: `${PREFIX}-icon`,
  date: `${PREFIX}-date`,
  day: `${PREFIX}-day`,
  month: `${PREFIX}-month`,
  separator: `${PREFIX}-separator`,
  info: `${PREFIX}-info`,
  description: `${PREFIX}-description`,
  bookNow: `${PREFIX}-bookNow`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`&.${classes.containerBox}`]: {
    margin: theme.spacing(0, 2, 2, 2),
  },

  [`& .${classes.summaryBox}`]: {
    backgroundColor: theme.palette.amgWhite,
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.headerBox}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 0, 0),
  },

  [`& .${classes.headline}`]: {
    flexDirection: 'column',
    fontFamily: 'MBCorpoSTitle-Bold-Web',
    color: theme.palette.amgBlack,
    fontSize: '26px',
    lineHeight: '28px',
    margin: theme.spacing(2, 2, 1, 2),
  },

  [`& .${classes.introText}`]: {
    color: theme.palette.amgBlack,
    margin: theme.spacing(1.5, 2, 1.5, 2),
  },

  [`& .${classes.stack}`]: {
    background: theme.palette.amgMineshaftGrey,
    margin: theme.spacing(0, 2, 2),
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.date}`]: {
    alignItems: 'center',
    background: theme.palette.amgRed,
    color: theme.palette.amgWhite,
    display: 'flex',
    height: '66px',
    width: '128px',
    justifyContent: 'space-between',
    marginLeft: '-3px',
    lineHeight: '20px',
    padding: '8px 16px',
    textAlign: 'center',
  },

  [`& .${classes.day}`]: {
    flexDirection: 'column',
    fontFamily: 'MBCorpoSTitle-Bold-Web',
    fontSize: '26px',
    lineHeight: '28px',
  },

  [`& .${classes.month}`]: {
    flexDirection: 'column',
    fontSize: '14px',
    lineHeight: '18px',
    textTransform: 'uppercase',

  },

  [`& .${classes.separator}`]: {
    padding: '0 9px',
  },

  [`& .${classes.info}`]: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '0.875rem', // 14px
    margin: theme.spacing(0, 2),
    color: theme.palette.amgBoulder,

    '& svg': {
      fill: theme.palette.amgBoulder,
    },
  },

  [`& .${classes.description}`]: {
    marginTop: '-50px',
    paddingBottom: '50px',
    fontFamily: 'MBCorpoSText-Regular-Web',

    '& a': {
      color: theme.palette.amgWhite,

      '&:hover, &:active, &:focus': {
        color: theme.palette.amgRed,
      },
    },
  },

  [`& .${classes.bookNow}`]: {
    position: 'sticky',
    shapeOutside: 'linear-gradient(transparent, transparent)',
    top: '85%',
    width: '100%',
  },
}));

function AmgEventOverview({ content }) {
  const { trackEvent } = useTracking();

  // We show start date time and end date time if no duration is shown
  const startTime = useDateBeautifier(content.startDate.timestamp, 'timeonly', true);
  const endTime = useDateBeautifier(content.endDate.timestamp, 'timeonly', true);
  const minuteTime = content.minutes ? `${content.hours}h ${content.minutes}m` : `${content.hours}h`;
  const time = content.displayDuration ? minuteTime : `${startTime} - ${endTime}`;
  const hasEndDate = content.endDate.day !== content.startDate.day || content.endDate.month !== content.startDate.month ? true : false;
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (isDialogClick) => {
    let bookAction; // Action can be 1.dialog "next"  2.Book now "open dialog"  3. Book now "next"

    if (content.disableDisclaimerPopUpDialog || isDialogClick) {
      const target = content.cta.ref === content.cta.url ? '_blank' : '_self';
      bookAction = 'book-now';
      window.open(content.cta.url, target);
      setOpenDialog(false);
    } else {
      bookAction = 'popup-open';
      setOpenDialog(true);
    }
    const trackLabel = isDialogClick ? 'popup-next' : bookAction;

    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'event',
        category: 'link',
        destinationUrl: content.cta.url,
        label: `${content.headline}|${trackLabel}|${content.cta.url}`,
      },
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'events',
        category: 'link',
        destinationUrl: content.cta.url,
        label: `${content.headline}|popup-cancel|${content.cta.url}`,
      },
    });
  };

  return (
    <StyledBox className={classes.containerBox}>
      <div className={classes.summaryBox}>
        <div className={classes.headerBox}>
          <div className={classes.date}>
            <div>
              <div className={classes.day}>{content.startDate.day}</div>
              <div className={classes.month}>{content.startDate.month}</div>
            </div>
            {hasEndDate && <>
              <div className={classes.separator}>
                -
              </div>
              <div>
                <div className={classes.day}>{content.endDate.day}</div>
                <div className={classes.month}>{content.endDate.month}</div>
              </div>
            </>}
          </div>
          <div className={classes.info}>
            <TimeIcon className={classes.icon} />
            {time}
          </div>
        </div>
        <AmgHeadline
          className={classes.headline}
          component="div"
          variant="headlineSlider"
        >
          {content.headline}
        </AmgHeadline>
        <div className={classes.introText}>
          {content.introductionText}
        </div>
        <div className={classes.info}>
          <LocationIcon className={classes.icon} />
          {content.location}
        </div>
      </div>
      <AmgImage content={content.mediaRef.image} />
      <AmgButton className={classes.bookNow} fullWidth onClick={() => handleClick(false)}>
        {content.cta.title || content.eventCalendarComponentI18n['amg.components.content.eventcalendar.bookNowCta']}
      </AmgButton>
      <div className={classes.description} dangerouslySetInnerHTML={{ __html: cleanHTML(content.description) }} />
      <AmgEventDialog content={content} openDialog={openDialog} handleClick={handleClick} handleClose={handleClose} />
    </StyledBox>
  );
}

export default AmgEventOverview;

AmgEventOverview.propTypes = {
  content: PropTypes.object.isRequired,
};
