import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import AmgButton from './AmgButton';
import AmgImage from './AmgImage';
import { toggleYTPConsent } from '../redux/actions';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgVideoYoutubeConsent';

const classes = {
  text: `${PREFIX}-text`,
  wrapper: `${PREFIX}-wrapper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.text}`]: {
    color: theme.palette.amgWhite,
  },

  [`& .${classes.wrapper}`]: {
    alignItems: 'center',
    background: theme.palette.amgNero,
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
  },
}));

const buttonStyles = {
  height: '35px',
  marginRight: 8,
  padding: 8,
};

function AmgVideoYoutubeConsent({ fallbackImage }) {

  const dispatch = useDispatch();
  const translate = useTranslate();
  const YTPConsentID = 'BJz7qNsdj-7'; // fix here, needs to be configurable and hand over by backend

  const showConsentDialog = () => {
    window.usercentrics.toggleConsentInfoModalIsVisible(YTPConsentID);
  };

  const acceptConsent = () => {
    window.usercentrics.updateConsents([{ templateId: YTPConsentID, status: true }]);
    dispatch(toggleYTPConsent(true));
  };

  return (
    (<Root>
      <AmgImage content={fallbackImage} />
      <div className={classes.wrapper}>
        <div className={classes.text}>
          {translate('pwa.youtube.consentText')}
        </div>
        <AmgButton className={classes.button} onClick={showConsentDialog} style={buttonStyles}>
          {translate('pwa.youtube.moreBtn')}
        </AmgButton>
        <AmgButton className={classes.button} onClick={acceptConsent} style={buttonStyles}>
          {translate('pwa.youtube.acceptBtn')}
        </AmgButton>
      </div>
    </Root>)
  );
}

export default AmgVideoYoutubeConsent;

AmgVideoYoutubeConsent.propTypes = {
  fallbackImage: PropTypes.object.isRequired,
};
