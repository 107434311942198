import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

const colors = {
  amgBlack: '#000',
  amgBoulder: '#767676',
  amgDarkGrey: '#666',
  amgGreyDisabled: '#bfbfbf',
  amgLightGrey: '#999',
  amgMineshaft: '#262626',
  amgMineshaftGrey: 'rgba(38, 38, 38, 0.7)', // #262626,
  amgMineshaftPure: '#333',
  amgNero: 'rgba(38, 38, 38, 0.95)', // #262626
  amgObsidian: '#3c3c3c',
  amgQuickSilver: '#a8a8a8',
  amgRed: '#b21e23',
  amgSlate: '#1f1f1f',
  amgWhite: '#fff',
  amgWhiteSmoke: '#f4f4f4',
};

const typography = {
  fontFamily: ['MBCorpoSText-Regular-Web', 'sans-serif'].join(','),
};

const palette = {
  ...colors,
  divider: colors.amgRed,
  primary: {
    main: colors.amgRed,
  },
  secondary: {
    main: colors.amgBlack,
  },
  text: {
    primary: colors.amgWhite,
  },
  background: {
    default: colors.amgBlack,
  },
  action: {
    disabled: colors.amgWhite,
    disabledBackground: colors.amgGreyDisabled,
  },
};

const components = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        borderTop: `1px solid ${colors.amgDarkGrey}`,
        overflow: 'hidden',

        '&:last-child': {
          borderBottom: `1px solid ${colors.amgDarkGrey}`,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: colors.amgBlack,
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        backgroundColor: colors.amgBlack,
        borderTop: `1px solid ${colors.amgMineshaft}`,
        bottom: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 1100, // Same value as default for material appBar
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        color: colors.amgBoulder,
        maxWidth: 'none', // Is this still necessary?

        '&.Mui-selected': {
          color: colors.amgWhite,

          '& svg': {
            fill: colors.amgWhite,
          },
        },

        '& svg': {
          fill: colors.amgBoulder,
          height: 20,
          width: 20,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        fontSize: '1rem', // 16px
        height: '50px',
        textTransform: 'none',

        '& svg': {
          fill: colors.amgWhite,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.amgRed,
        },
      },
      containedSecondary: {
        border: `1px solid ${colors.amgWhite}`,

        '&:hover': {
          backgroundColor: colors.amgBlack,
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      rail: {
        backgroundColor: colors.amgDarkGrey,
        height: 4,
        opacity: 1,
      },
      root: {
        marginTop: -20,
      },
      thumb: {
        backgroundColor: colors.amgRed,
        height: 60,
        marginLeft: 0,
        marginTop: 0,
        transition: 'none',
        width: 60,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: colors.amgBoulder,

        '&.Mui-selected': {
          color: colors.amgWhite,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        visibility: 'hidden',
      },
    },
  },
};

const theme = createTheme({
  typography,
  palette,
  components,
});

function AmgTheme({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default AmgTheme;

AmgTheme.propTypes = {
  children: PropTypes.node.isRequired,
};
