import { Button, Stack, Typography } from '@mui/material';

function AmgNotification() {
  const handleClick = () => {
    Notification.requestPermission((result) => {
      if (result === 'granted') {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification('New article in PL', {
            body: 'There is a new article in the PL!',
            icon: 'https://www.mercedes-amg.com/content/global/en/home/jcr:content/root/stage/media/slides/slide_290417432_copy_1100429537/mediaMobile/image.media1.responsive_3_4_1_image.1680098147893.jpeg',
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: 'amg-notification',
          });
        });
      }
    });
  };

  return (
    <Stack sx={{ p: 2 }}>
      <Typography variant="h6">Notification API</Typography>
      <Button onClick={handleClick}>Notification</Button>
    </Stack>
  );
}

export default AmgNotification;
