import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import { ReactComponent as ChevronLeftIcon } from '../svg/chevron-left.svg';
import useTracking from '../hooks/useTracking';

function AmgBack() {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'app bar',
        category: 'navigation click',
        label: 'back',
      },
    });

    navigate(-1);
  };

  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="Back"
      onClick={handleClick}
      size="large">
      <ChevronLeftIcon />
    </IconButton>
  );
}

export default AmgBack;
