import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { toggleDrawer, updateFiltersDrawer } from '../redux/actions';
import { ReactComponent as FilterIcon } from '../svg/icon-filter.svg';
import { ReactComponent as CheckmarkIcon } from '../svg/checkmark.svg';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgFilter';

const classes = {
  checkmark: `${PREFIX}-checkmark`,
  root: `${PREFIX}-root`,
};

const StyledIconButton = styled(IconButton)((
  {
    theme,
  }
) => ({
  [`& .${classes.checkmark}`]: {
    alignItems: 'center',
    backgroundColor: theme.palette.amgRed,
    borderRadius: 8,
    display: 'flex',
    height: 16,
    marginRight: 2,
    width: 16,
  },

  [`&.${classes.root}`]: {
    marginRight: 0,
  },
}));

function AmgFilter() {

  const dispatch = useDispatch();
  const filters = useSelector((state) => state.user.filters);
  const isFiltered = Object.keys(filters).some((key) => filters[key] === false);
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'app bar',
        category: 'navigation click',
        label: 'drawer',
      },
    });

    dispatch(toggleDrawer(true));
    dispatch(updateFiltersDrawer(filters));
  };

  return (
    <StyledIconButton
      aria-label="filter"
      className={classes.root}
      color="inherit"
      edge="end"
      onClick={handleClick}
      size="large">
      {isFiltered &&
        <div className={classes.checkmark}>
          <CheckmarkIcon style={{ height: 8, width: 8 }} />
        </div>
      }
      <FilterIcon />
    </StyledIconButton>
  );
}

export default AmgFilter;
