import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import AmgHeadlines from './AmgHeadlines';
import AmgRichText from './AmgRichText';

const PREFIX = 'AmgText';

const classes = {
  richText: `${PREFIX}-richText`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`& .${classes.richText}`]: {
    padding: theme.spacing(0, 2),
  },
}));

function AmgText({ content }) {

  return (
    <StyledBox>
      <AmgHeadlines headline={content.headline.headline} subHeadline={content.headline.topicLine} />
      <AmgRichText className={classes.richText} content={content.richText} />
    </StyledBox>
  );
}

export default AmgText;

AmgText.propTypes = {
  content: PropTypes.object.isRequired,
};
