import { UPDATE_YOUTUBE_LIST, YOUTUBE_CONSENT } from '../actionTypes';

let currentYTConsent = false;

const uc = JSON.parse(window.localStorage.getItem('usercentrics'));

if (uc !== null && typeof uc.consents !== 'undefined') {
  // eslint-disable-next-line no-unused-vars
  Object.entries(uc.consents).forEach(([key, category]) => {
    if (category.categorySlug && category.categorySlug === 'marketing') {
      currentYTConsent = category.consentStatus;
    }
  });
}

const initialState = {
  accepted: currentYTConsent,
  videos: {},
};

const ytpconsent = (state = initialState, action = {}) => {
  if (action.type === YOUTUBE_CONSENT) {
    return {
      ...state,
      accepted: action.payload.accepted,
    };
  } else if (action.type === UPDATE_YOUTUBE_LIST) {
    if (action.payload.playerId) {
      state.videos[action.payload.playerId] = {
        player: action.payload.player,
        video: action.payload.video,
      };
    } else {
      state.videos = {};
    }

    return {
      ...state,
      videos: state.videos,
    };
  } else {
    return state;
  }
};

export default ytpconsent;
