import PropTypes from 'prop-types';

import AmgButton from './AmgButton';
import pkg from '../../package.json';

function AmgLink({ className, link, ...props }) {
  if (!link || !link.valid) {
    return null;
  }

  let linkHref = '';
  let linkName = '';

  if (link.attributeMap) {
    // Links have a fallback to an empty string in case the link is valid but has an empty href (needs to be fixed on the BE side)
    linkHref = link.attributeMap.href || '';
    linkName = link.attributeMap['data-amg4web-linkname'] || '';

    if (linkHref.endsWith('.model.json')) {
      linkHref = `${pkg.homepage}/index.html#/page/${encodeURIComponent(link.attributeMap.href)}`;
    }
  }

  return (
    <AmgButton href={linkHref} className={className} {...props}>
      {linkName}
    </AmgButton>
  );
}

export default AmgLink;

AmgLink.propTypes = {
  className: PropTypes.string,
  link: PropTypes.object,
};
