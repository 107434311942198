import { Box, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as LogoutIcon } from '../svg/logout-xs.svg';
import pkg from '../../package.json';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgSettingsLogout';

const classes = {
  container: `${PREFIX}-container`,
  logoutIcon: `${PREFIX}-logoutIcon`,
};

const StyledListItem = styled(ListItem)(() => ({
  [`& .${classes.logoutIcon}`]: {
    height: 22,
    width: 23,
  },
}));

function AmgSettingsLogout() {
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const theme = useTheme();

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'mercedes-me',
        category: 'logout',
        label: 'overlay-opened',
      },
      page: {
        pageName: translate('pwa.settings.overview'),
        pagePath: `${pkg.homepage}/index.html#/settings`,
        pageType: 'pwa-settings',
      },
      user: {
        loginState: 'logged out',
      },
    });

    window.sessionStorage.removeItem('addToHomescreen');
    window.open('/bin/daimler/public/ciam/oidc-logout.html?pwa=true', '_self');
  };

  return (
    <Box
      onClick={handleClick}
      sx={{ borderTop: `1px solid ${theme.palette.amgGreyDisabled}`, px: 0, py: 1 }}
    >
      <StyledListItem classes={{ container: classes.container }}>
        <ListItemIcon>
          <LogoutIcon className={classes.logoutIcon} />
        </ListItemIcon>
        <ListItemText primary={translate('pwa.settings.overview.logout')} />
        <ListItemSecondaryAction />
      </StyledListItem>
    </Box>
  );
}

export default AmgSettingsLogout;
