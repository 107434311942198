import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import AmgComparisonSliderPanel from './AmgComparisonSliderPanel';
import AmgHeadlines from './AmgHeadlines';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgComparisonSlider';

const classes = {
  text: `${PREFIX}-text`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.text}`]: {
    margin: theme.spacing(0, 2, 3),
  },
}));

function AmgComparisonSlider({ content }) {
  const [view, setView] = useState(0);

  const { trackEvent } = useTracking();

  // Switch between Front View and Rear View
  const handleChange = (event, newView) => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'comparison-slider',
        category: 'link',
        label: `viewchange|${newView === 0 ? 'Front View' : 'Rear View'}`,
      },
    });

    setView(newView);
  };

  return (
    <Root>
      <AmgHeadlines headline={content.headline} subHeadline={content.topicLine} />
      <Typography className={classes.text} component="p">
        {content.text}
      </Typography>
      <AppBar position="static">
        <Tabs
          onChange={handleChange}
          value={view}
          variant="fullWidth"
        >
          {content.slideCategories.map((category, index) => {
            return (
              <Tab
                key={index}
                label={category.title}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {content.slideCategories.map((category, index) => (
        <AmgComparisonSliderPanel content={category} index={index} key={index} value={view} />
      ))}
    </Root>
  );
}

export default AmgComparisonSlider;

AmgComparisonSlider.propTypes = {
  content: PropTypes.object.isRequired,
};
