import { useEffect, useState } from 'react';

import AmgBanner from './AmgBanner';
import useTranslate from '../hooks/useTranslate';

function AmgFiltersResetBanner() {
  const translate = useTranslate();
  const [showFiltersWarning, setShowFiltersWarning] = useState(false);

  const handleClick = () => {
    localStorage.removeItem('filters');
    localStorage.removeItem('filtersLastUpdate');
    window.location.reload();
  };

  const handleClose = () => {
    localStorage.setItem('filtersLastUpdate', new Date().getTime());
  };

  useEffect(() => {
    const filtersLastUpdate = localStorage.getItem('filtersLastUpdate') || 0;
    const daysSinceLastUpdate = (new Date().getTime() - filtersLastUpdate) / 1000 / 60 / 60 / 24;

    // If the filters have been updated more than 14 days ago, then show the dialog with the reset button
    if (filtersLastUpdate && daysSinceLastUpdate > 14) {
      setShowFiltersWarning(true);
    }
  }, []);

  if (showFiltersWarning) {
    return (
      <AmgBanner handleClose={handleClose}>
        <span onClick={handleClick}>{translate('pwa.settings.filter.notification.text.1')}</span>{translate('pwa.settings.filter.notification.text.2')}
      </AmgBanner>
    );
  }

  return null;
}

export default AmgFiltersResetBanner;
