import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';

import { cleanHTML } from '../utils';

import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgEmissionStatement';

const classes = {
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.amgLightGrey,
    fontFamily: 'MBCorpoSText-Regular-Web',
    fontSize: '14px',
    lineHeight: '18px',

    '& .amg-e-link': {
      color: theme.palette.amgWhite,
      textDecorationLine: 'none',

      '&:hover, &:active, &:focus': {
        color: theme.palette.amgRed,
      },
    },

    '& sub': {
      lineHeight: 0,
    },
  },
}));

const calculateRange = (min, max) => {
  if (min && max && min !== max) {
    return `${max} - ${min}`;
  }

  return min || max || 0;
};

/**
 * Find placeholders in HTML and replace them with emission values
 *
 * @param {string} emissionStatement - e.g. Kraftstoffverbrauch kombiniert: {0} l/100 km, {1} g/km
 * @param {array} emissions - e.g. [11.4, 261]
 * @returns {string} - e.g. Kraftstoffverbrauch kombiniert: 11.4 l/100 km, 261 g/km
 */
const replaceValues = (emissionStatement, emissions) => {
  const output = emissionStatement || '';

  return output.replace(/{\d+}/g, (match) => emissions[match.slice(1, -1)]);
};

const mapStateToProps = (state) => {
  return {
    emissionStatementLink: state.env.pwaEmissionStatementLink || {}, // Default value provided only for the tests
  };
};

function AmgEmissionStatement({ className, content, emissionStatementLink }) {

  const [emissions, setEmissions] = useState();
  const translate = useTranslate();

  // useEffect to not calculate each time the component is rerendered
  useEffect(()=> {
    setEmissions(true);
  }, [content]);

  // Hide the emission statement if the author asked for it)
  if (!content || content.ignoreEmission) {
    return null;
  }

  function OneEmissionStatement(es) {
    const emissionStatements = [];
    let i18nKey;

    // fuel
    if (es.engineType === 'Combustion') {
      i18nKey = 'pwa.emissionStatement.combustion';
      emissionStatements.push(calculateRange(es.combinedConsumptionMin, es.combinedConsumptionMax));
      emissionStatements.push(calculateRange(es.combinedCO2EmissionMin, es.combinedCO2EmissionMax));
    } else if (es.engineType === 'Electric') { // electric
      i18nKey = 'pwa.emissionStatement.electric';
      emissionStatements.push(calculateRange(es.combinedConsumptionMin, es.combinedConsumptionMax));
    } else if (es.engineType === 'Hybrid') { // hybrid
      i18nKey = 'pwa.emissionStatement.hybrid';
      // fuel
      emissionStatements.push(calculateRange(es.minimalHybridCombinedFuelConsumption, es.maximalHybridCombinedFuelConsumption));
      emissionStatements.push(calculateRange(es.minimalHybridCombinedCO2Emission, es.maximalHybridCombinedCO2Emission));
      // electric
      emissionStatements.push(calculateRange(es.minimalWeightedElectricConsumption, es.maximalWeightedElectricConsumption));
    }

    emissionStatements.push(emissionStatementLink?.attributeMap?.href);
    emissionStatements.push(es.footnotes ? es.footnotes.toString() : '');

    return (
      <div
        className={clsx(classes.root, className)}
        dangerouslySetInnerHTML={{ __html: cleanHTML(replaceValues(translate(i18nKey), emissionStatements)) }}
      />
    );
  }

  return emissions ? <Root>
    {content.combustionEmissionStatement?.combinedConsumption !== 0 && <OneEmissionStatement {...content.combustionEmissionStatement} />}
    {content.electricEmissionStatement?.combinedConsumption !== 0 && <OneEmissionStatement {...content.electricEmissionStatement} />}
    {content.hybridEmissionStatement?.combinedConsumption !== 0 && <OneEmissionStatement {...content.hybridEmissionStatement} />}
  </Root> : null;
}

export default connect(
  mapStateToProps,
)(AmgEmissionStatement);

AmgEmissionStatement.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  emissionStatementLink: PropTypes.object.isRequired,
};
