import { FormControl, MenuItem, Select, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { ReactComponent as ChevronDownIcon } from '../svg/chevron-down.svg';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgComparisonSliderSelect';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledFormControl = styled(FormControl)((
  {
    theme,
  }
) => ({
  [`&.${classes.root}`]: {
    marginBottom: theme.spacing(3),
    width: '100%',

    '&:last-child': {
      marginBottom: theme.spacing(4),
    },
  },
}));

function AmgComparisonSliderSelect({ content, handleSelect, side, value }) {
  const selectRef = useRef();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const handleChange = (event) => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'comparison-slider',
        category: 'link',
        label: `selectionchange|${selectRef.current.textContent}`,
      },
    });

    handleSelect(side, event.target.value);
  };

  return (
    <StyledFormControl className={classes.root}>
      <Select
        IconComponent={() => (
          <ChevronDownIcon />
        )}
        MenuProps={{
          MenuListProps: {
            sx: {
              p: 0,
            },
          },
          PaperProps: {
            sx: {
              backgroundColor: theme.palette.amgMineshaftPure,
              borderRadius: 0,
            },
          },
        }}
        onChange={handleChange}
        value={value}
        sx={{
          fontSize: 16,
          lineHeight: '1.25',

          '&:before': {
            borderColor: theme.palette.amgGreyDisabled,
          },
        }}
      >
        {content.slides.map((slide, index) => {
          return (
            <MenuItem
              key={index}
              ref={selectRef}
              value={index}
              sx={{
                fontSize: '16px',
                lineHeight: '21px',
                padding: theme.spacing(2),

                '&:not(:last-child)': {
                  borderBottom: `1px solid ${theme.palette.amgDarkGrey}`,
                },

                '&.Mui-selected': {
                  backgroundColor: theme.palette.amgMineshaft,
                },
              }}
            >
              {slide.title}
            </MenuItem>
          );
        })}
      </Select>
    </StyledFormControl>
  );
}

export default AmgComparisonSliderSelect;

AmgComparisonSliderSelect.propTypes = {
  content: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  side: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
