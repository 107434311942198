import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import AmgButton from '../components/AmgButton';

const PREFIX = 'AmgEventDialog';

const classes = {
  dialog: `${PREFIX}-dialog`,
  cancelBtn: `${PREFIX}-cancelBtn`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogText: `${PREFIX}-dialogText`,
  dialogActions: `${PREFIX}-dialogActions`,
};

const StyledDialog = styled(Dialog)((
  {
    theme,
  }
) => ({
  [`& .${classes.dialog}`]: {
    backgroundColor: theme.palette.amgObsidian,
    margin: theme.spacing(2),
    opacity: 0.95,
    width: '100%',
  },

  [`& .${classes.cancelBtn}`]: {
    backgroundColor: theme.palette.amgObsidian,
  },

  [`& .${classes.dialogContent}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.dialogText}`]: {
    padding: theme.spacing(2, 0),
  },

  [`& .${classes.dialogActions}`]: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

function AmgEventDialog({ content, openDialog, handleClick, handleClose }) {

  return (
    <StyledDialog
      open={openDialog}
      PaperProps={{
        square: true,
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography>
          {content.eventCalendarComponentI18n['components.content.eventcalendar.disclaimerSalutation']}
        </Typography>
        <Typography className={classes.dialogText}>
          {content.eventCalendarComponentI18n['components.content.eventcalendar.disclaimerText']}
        </Typography>
        <Typography>
          {content.eventCalendarComponentI18n['amg.components.content.eventcalendar.disclaimerInfo']}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AmgButton color="secondary" className={classes.cancelBtn} onClick={() => handleClose()}>
          {content.eventCalendarComponentI18n['amg.components.content.eventcalendar.backButton']}
        </AmgButton>
        <AmgButton onClick={() => handleClick(true)}>
          {content.eventCalendarComponentI18n['amg.components.content.eventcalendar.disclaimerNextCta']}
        </AmgButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default AmgEventDialog;

AmgEventDialog.propTypes = {
  content: PropTypes.object.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
