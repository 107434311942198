import AmgDeviceNotificationBanner from './AmgDeviceNotificationBanner';
import { isPWAOptimized } from '../utils';

function AmgDeviceNotification() {
  // Shown if device is not PWA optimized
  const showDialog = !window.sessionStorage.getItem('deviceNotification') && !isPWAOptimized();

  return showDialog ? <AmgDeviceNotificationBanner /> : null;
}

export default AmgDeviceNotification;
