import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import { setHasNewPosts } from '../redux/actions';
import { ReactComponent as HomeIcon } from '../svg/home-xs.svg';
import { ReactComponent as SettingsIcon } from '../svg/settings.svg';
import { ReactComponent as UserIcon } from '../svg/user.svg';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

function AmgBottomNavigation() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const hasNewPosts = useSelector((state) => state.post.hasNewPosts);
  const layer = useSelector((state) => state.user.user_layer);
  const [value, setValue] = useState();
  const { trackEvent } = useTracking();
  const translate = useTranslate();

  const handleChange = (event, newValue) => {
    // If the user clicks on the active button
    if (value === newValue) {
      // If the user is in the Activity Stream and and clicks on the home button
      if (value === '/') {
        window.dispatchEvent(new Event('refresh'));
        dispatch(setHasNewPosts(false));
        window.scrollTo(0, 0);
      }

      return;
    }

    setValue(newValue);
    navigate(newValue);

    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'bottom',
        category: 'navigation click',
        label: newValue === '/' ? 'home' : newValue.substring(1),
      },
    });
  };

  useEffect(() => {
    // Only used when we reload the page
    if (location.pathname === '/') {
      setValue('/');
    } else if (location.pathname.startsWith('/profile')) {
      setValue('/profile');
    } else if (location.pathname.startsWith('/settings')) {
      setValue('/settings');
    } else {
      setValue(null);
    }
  }, [location.pathname]);

  // Do not show the Bottom Navigation on the maintenance page
  if (location.pathname === '/maintenance') {
    return null;
  }

  return (
    <BottomNavigation
      showLabels
      onChange={handleChange}
      value={value}
    >
      <BottomNavigationAction
        icon={hasNewPosts ? <Badge color="secondary" variant="dot"><HomeIcon /></Badge> : <HomeIcon />}
        label={translate('pwa.bottomNavi.home')}
        value="/"
      />
      {layer !== 'layer_b' && <BottomNavigationAction
        icon={<UserIcon />}
        label={translate('pwa.bottomNavi.profile')}
        value="/profile"
      />}
      <BottomNavigationAction
        icon={<SettingsIcon />}
        label={translate('pwa.bottomNavi.settings')}
        value="/settings"
      />
    </BottomNavigation>
  );
}

export default AmgBottomNavigation;
