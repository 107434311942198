import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { cleanHTML } from '../../utils';

const PREFIX = 'AmgCheckbox';

const classes = {
  checkbox: `${PREFIX}-checkbox`,
  checkboxLabel: `${PREFIX}-checkboxLabel`,
  error: `${PREFIX}-error`,
  root: `${PREFIX}-root`,
};

const StyledFormGroup = styled(FormGroup)((
  {
    theme,
  }
) => ({
  [`& .${classes.checkbox}`]: {
    '& svg': {
      fill: 'none',
      backgroundColor: theme.palette.amgWhite,
    },
    '& :checked + svg': {
      fill: theme.palette.amgRed,
    },
  },

  [`& .${classes.checkboxLabel}`]: {
    alignItems: 'start',
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.amgRed,
    color: theme.palette.amgWhite,
    display: 'inline-block',
    fontFamily: 'MBCorpoSText-Regular-Web',
    fontSize: '0.875rem', // 14px
    lineHeight: '1rem',
    padding: theme.spacing(0.5, 1),
    margin: 0,
  },

  [`&.${classes.root}`]: {
    marginTop: '32px',
    '& a': {
      color: theme.palette.amgWhite,
    },
    '& .MuiFormControlLabel-label': {
      paddingTop: '10px',
    },
  },
}));

function AmgCheckbox({ error, id, name, linkUrl, label, value, onChange }) {

  return (
    <StyledFormGroup className={classes.root}>
      <FormControlLabel
        className={classes.checkboxLabel}
        control={<Checkbox onChange={onChange} checkedIcon={<Check />} className={classes.checkbox} id={id} name={name} size="medium" value={value} />}
        label={<span dangerouslySetInnerHTML={{ __html: cleanHTML(label.replace('{0}', linkUrl)) }} />}
        labelPlacement="end"
      />
      {error && <p className={clsx(classes.error, 'errorBox')}>{error.message}</p>}
    </StyledFormGroup>
  );
}

export default AmgCheckbox;

AmgCheckbox.propTypes = {
  error: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};
