import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AmgError from './components/AmgError';
import AmgInit from './AmgInit';
import AmgTheme from './AmgTheme';

const withTheme = (Component) => {
  return <AmgTheme>{Component}</AmgTheme>;
};

function AmgApp() {
  const [hasError, setHasError] = useState(false);
  const code = useSelector((state) => state.status.code);

  // Sometimes we have hasError = true with code 200 because of background calls returning 200 (i.e. ActStr Listener)
  useEffect(() => {
    setHasError(code !== 'ok');
  }, [code]);

  if (hasError && code !== 'ok') {
    return withTheme(<AmgError variant={code} />);
  }

  return <AmgInit />;
}

export default AmgApp;
