import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

const PREFIX = 'AmgMode';

const classes = {
  common: `${PREFIX}-common`,
  dark: `${PREFIX}-dark`,
  light: `${PREFIX}-light`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`&.${classes.common}`]: {
    minHeight: '100vh',
    paddingBottom: '72px', // Bottom Navigation 56px + 16px safe area
    paddingTop: '76px', // NavBar 60px + 16px safe area
  },

  [`&.${classes.dark}`]: {
    backgroundColor: theme.palette.amgBlack,
    color: theme.palette.amgWhite,

    '& svg': {
      fill: theme.palette.amgWhite,
    },
  },

  [`&.${classes.light}`]: {
    backgroundColor: theme.palette.amgWhite,
    color: theme.palette.amgMineshaftPure,

    '& h1:first-of-type': {
      marginBottom: theme.spacing(4),
    },

    '& input': {
      color: theme.palette.amgMineshaftPure,
    },
  },
}));

function AmgMode({ children, className, mode }) {
  return (
    <StyledBox className={clsx(className, classes.common, classes[mode])}>
      {children}
    </StyledBox>
  );
}

export default AmgMode;

AmgMode.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mode: PropTypes.string.isRequired,
};
