import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import AmgButton from '../components/AmgButton';
import AmgHeadline from '../components/AmgHeadline';
import AmgMode from '../components/AmgMode';
import { fetchHandler } from '../utils';
import useTranslate from '../hooks/useTranslate';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgDetails';

const classes = {
  button: `${PREFIX}-button`,
  label: `${PREFIX}-label`,
  text: `${PREFIX}-text`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.button}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.label}`]: {
    fontWeight: 'bold',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.text}`]: {
    display: 'inline-block',
  },

  [`&.${classes.wrapper}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function AmgDetails() {

  const [account, setAccount] = useState({});
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const env = useSelector((state) => state.env);
  const lang = useSelector((state) => state.user.preferred_language);

  const page = useMemo(() => {
    const trackingType = 'pwa-account';

    return {
      contentProduct: trackingType,
      contentProject: trackingType,
      name: translate('pwa.settings.details.header'),
      siteSection: trackingType,
      type: trackingType,
    };
  }, [translate]);

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'account-details',
        category: 'link',
        destinationUrl: account.ciamUrl,
        label: 'view-edit-profile',
      },
      page,
    });

    window.open(account.ciamUrl, '_self');
  };

  useEffect(() => {
    if (Object.keys(account).length === 0) {
      const url = `${env.CONTENT_PATH}/${lang.toLowerCase()}/jcr:content.useraccountsettings.json`;

      fetchHandler(url).then((res) => {
        setAccount(res);
      });

      trackEvent({
        event: 'pageview',
        page,
      });
    }
  }, [account, env.CONTENT_PATH, lang, page, trackEvent]);

  return (
    <StyledAmgMode mode="light" className={classes.wrapper}>
      <AmgHeadline component="h1" variant="subHeadline">
        {translate('pwa.settings.details.header')}
      </AmgHeadline>
      <div className={classes.label}>{translate('pwa.settings.details.firstname')}:</div>
      <div className={classes.text}>{account.firstName}</div>
      <div className={classes.label}>{translate('pwa.settings.details.lastname')}:</div>
      <div className={classes.text}>{account.lastName}</div>
      <div className={classes.label}>{translate('pwa.settings.details.email')}:</div>
      <div className={classes.text}>{account.email}</div>
      <div className={classes.label}>{translate('pwa.settings.details.mobile')}:</div>
      <div className={classes.text}>{account.mobile}</div>
      <div className={classes.label}>{translate('pwa.settings.details.country')}:</div>
      <div className={classes.text}>{account.country}</div>
      <div className={classes.label}>{translate('pwa.settings.details.password')}:</div>
      <div className={classes.text}>{translate('pwa.settings.details.passwordMessage')}</div>
      <div className={classes.button}>
        <AmgButton fullWidth onClick={handleClick}>
          {translate('pwa.settings.details.changeButton')}
        </AmgButton>
      </div>
    </StyledAmgMode>
  );
}

export default AmgDetails;
