import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import useTracking from '../hooks/useTracking';

function AmgButton({ children, href, ...props }) {
  const { trackEvent } = useTracking();

  // Open non PWA related links in a new window
  if (typeof href === 'string' && !href.includes('.model.json')) {
    props.target = '_blank';
  }

  const handleClick = () => {
    if (props.eventdetailaction) {
      trackEvent({
        event: 'click',
        eventDetail: {
          action: props.eventdetailaction,
          category: 'link',
          destinationUrl: href,
          label: children,
        },
      });
    }
  };

  return (
    <Button
      disabled={props.disabled ? props.disabled : false}
      href={href}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  );
}

export default AmgButton;

AmgButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  eventdetailaction: PropTypes.string,
  href: PropTypes.string,
};
