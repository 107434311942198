import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Divider from '@mui/material/Divider';

const PREFIX = 'AmgDivider';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledDivider = styled(Divider)((
  {
    theme,
  }
) => ({
  [`&.${classes.root}`]: {
    height: 1,
    margin: theme.spacing(2, 0),
    width: 50,
  },
}));

function AmgDivider({ className }) {
  return <StyledDivider className={clsx(classes.root, className)} />;
}

export default AmgDivider;

AmgDivider.propTypes = {
  className: PropTypes.string,
};
