import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import AmgDivider from './AmgDivider';
import AmgHeadline from './AmgHeadline';
import { renderComponents } from '../utils';
import { ReactComponent as ChevronDownIcon } from '../svg/chevron-down.svg';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgTabbedContent';

const classes = {
  accordion: `${PREFIX}-accordion`,
  accordionButton: `${PREFIX}-accordionButton`,
  accordionButtonContent: `${PREFIX}-accordionButtonContent`,
  accordionButtonTitle: `${PREFIX}-accordionButtonTitle`,
  expanded: `${PREFIX}-expanded`,
  headline: `${PREFIX}-headline`,
  root: `${PREFIX}-root`,
  topicLine: `${PREFIX}-topicLine`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.accordion}`]: {
    margin: theme.spacing(0, -2),

    '&.Mui-expanded': {
      margin: theme.spacing(0, -2),

      '&:before': {
        opacity: 1,
      },
    },

    '&.Mui-expanded + &:before': {
      display: 'block',
    },
  },

  [`& .${classes.accordionButton}`]: {
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
  },

  [`& .${classes.accordionButtonContent}`]: {
    margin: theme.spacing(2.5, 0),

    '&.Mui-expanded': {
      margin: theme.spacing(2.5, 0),
    },
  },

  [`& .${classes.accordionButtonTitle}`]: {
    fontSize: '1rem !important',
  },

  [`& .${classes.headline}`]: {
    marginBottom: theme.spacing(3),
  },

  [`&.${classes.root}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.topicLine}`]: {
    marginBottom: theme.spacing(2),
  },
}));

function AmgTabbedContent({ content }) {
  const [expanded, setExpanded] = useState('panel1');
  const { trackEvent } = useTracking();

  const handleClick = (tabName) => () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'tabbed-content',
        category: 'navigation click',
        label: tabName,
      },
    });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Root className={classes.root}>
      {content.headline.topicLine && (
        <AmgHeadline variant="headline" className={classes.topicLine}>
          {content.headline.topicLine}
        </AmgHeadline>
      )}
      {content.headline.separator && (<AmgDivider />)}
      {content.headline.headline && (
        <AmgHeadline variant="subHeadline" className={classes.headline}>
          {content.headline.headline}
        </AmgHeadline>
      )}
      {content.tabs.map((tab, index) => {
        if (!tab.valid) {
          return null;
        }

        return (
          <Accordion
            classes={{
              expanded: classes.expanded,
              root: classes.accordion,
            }}
            expanded={expanded === `panel${index + 1}`}
            key={index}
            onChange={handleChange(`panel${index + 1}`)}
            square
          >
            <AccordionSummary
              classes={{
                content: classes.accordionButtonContent,
                expanded: classes.expanded,
                root: classes.accordionButton,
              }}
              expandIcon={<ChevronDownIcon />}
              onClick={handleClick(tab.name)}
            >
              <AmgHeadline variant="subHeadline" className={classes.accordionButtonTitle}>
                {tab.name}
              </AmgHeadline>
            </AccordionSummary>
            <AccordionDetails>
              {renderComponents(tab)}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Root>
  );
}

export default AmgTabbedContent;

AmgTabbedContent.propTypes = {
  content: PropTypes.object.isRequired,
};
