import { useEffect, useMemo, useState } from 'react';
import { FormControl, MenuItem, Select, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import AmgButton from '../components/AmgButton';
import AmgHeadline from '../components/AmgHeadline';
import AmgMode from '../components/AmgMode';
import { ReactComponent as ChevronDownIcon } from '../svg/chevron-down.svg';

import useCsrfToken from '../hooks/useCsrfToken';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgLanguage';

const classes = {
  arrowDown: `${PREFIX}-arrowDown`,
  dropdown: `${PREFIX}-dropdown`,
  select: `${PREFIX}-select`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.arrowDown}`]: {
    fill: theme.palette.amgMineshaftPure,
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    width: 12,
  },

  [`& .${classes.select}`]: {
    color: theme.palette.amgMineshaftPure,
    fontSize: 16,
    lineHeight: '1.25',

    '&:before, &:after': {
      borderColor: theme.palette.amgGreyDisabled,
    },
  },

  [`&.${classes.wrapper}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
}));

function AmgLanguage() {
  const translate = useTranslate();
  const { trackEvent } = useTracking();
  const lang = useSelector((state) => state.user.preferred_language);
  const csrfToken = useCsrfToken();
  const theme = useTheme();

  const [language, setLanguage] = useState(translate(`pwa.settings.language.${lang.toLowerCase()}`));
  const strGerman = translate('pwa.settings.language.de');
  const strEnglish = translate('pwa.settings.language.en');

  const page = useMemo(() => {
    const trackingType = 'pwa-language';

    return {
      contentProduct: trackingType,
      contentProject: trackingType,
      siteSection: trackingType,
      type: trackingType,
    };
  }, []);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleClick = async () => {
    const newLanguage = language === strEnglish ? 'EN' : 'DE';
    const token = await csrfToken.get();

    const formData = new FormData();
    formData.append('profileLanguage', newLanguage);

    const response = await fetch('/bin/amg/updatepreferredlanguage', {
      method: 'POST',
      headers: {
        'CSRF-Token': token,
      },
      body: formData,
    });

    if (response.ok) {
      trackEvent({
        event: 'click',
        eventDetail: {
          action: 'preferred-language',
          category: 'link',
          label: newLanguage,
        },
        page,
      });

      window.location.reload();
    }
  };

  useEffect(() => {
    trackEvent({
      event: 'pageview',
      page: {
        ...page,
        name: translate('pwa.settings.language.header'),
      },
    });
  }, [page, trackEvent, translate]);

  return (
    <StyledAmgMode mode="light" className={classes.wrapper}>
      <AmgHeadline component="h1" variant="subHeadline">
        {translate('pwa.settings.language.header')}
      </AmgHeadline>
      <p>
        {translate('pwa.settings.language.description')}
      </p>
      <FormControl variant="standard" sx={{ width: '100%' }}>
        <Select
          className={classes.select}
          value={language}
          onChange={handleChange}
          IconComponent={() => (
            <ChevronDownIcon className={classes.arrowDown} />
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: 0,
                color: theme.palette.amgMineshaftPure,
              },
            },
          }}
        >
          <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.selected,
            }}
            data-testid="German"
            value={strGerman}
          >
            {strGerman}
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.selected,
            }}
            data-testid="English"
            value={strEnglish}
          >
            {strEnglish}
          </MenuItem>
        </Select>
      </FormControl>
      <AmgButton fullWidth onClick={handleClick}>
        {translate('pwa.settings.save')}
      </AmgButton>
    </StyledAmgMode>
  );
}

export default AmgLanguage;
