const generatePlayerId = () => {
  const array = new Uint32Array(1);
  const randomValue = crypto.getRandomValues(array)[0];

  return `player${randomValue}`;
};

function useYouTubeIframeAPI(isConsentGiven) {
  if (!window.YT && isConsentGiven) {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  return { generatePlayerId };
}

export default useYouTubeIframeAPI;
