import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Box, ListItem, useTheme } from '@mui/material';

import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import useTracking from '../hooks/useTracking';

const StyledListItem = styled(ListItem)(() => {});

function AmgPrivacyListItem({ text, url }) {
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'privacy',
        category: 'link',
        destinationUrl: url === 'usercentrics' ? null : url,
        label: text,
      },
    });

    if (url === 'usercentrics') {
      window.usercentrics?.toggleConsentInfoModalIsVisible();
    } else {
      window.open(url, '_self');
    }
  };

  return (
    <Box
      onClick={handleClick}
      sx={{ borderTop: `1px solid ${theme.palette.amgGreyDisabled}`, px: 0, py: 1 }}
    >
      <StyledListItem>
        <ListItemText primary={text} />
        <ListItemSecondaryAction>
          {url === 'usercentrics' ? null : <OpenInNewIcon />}
        </ListItemSecondaryAction>
      </StyledListItem>
    </Box>
  );
}

export default AmgPrivacyListItem;

AmgPrivacyListItem.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
