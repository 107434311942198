import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgImage from './AmgImage';

const PREFIX = 'AmgFullWidthImage';

const classes = {
  box: `${PREFIX}-box`,
  img: `${PREFIX}-img`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`&.${classes.box}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.img}`]: {
    marginBottom: theme.spacing(1),
  },
}));

function AmgFullWidthImage({ content }) {

  return (
    <StyledBox className={classes.box}>
      <AmgImage className={classes.img} content={content.image} />
      <AmgEmissionStatement className={classes.emissionStatement} content={content.emissionStatement} />
    </StyledBox>
  );
}

export default AmgFullWidthImage;

AmgFullWidthImage.propTypes = {
  content: PropTypes.object.isRequired,
};
