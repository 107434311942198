import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import AmgBack from './AmgBack';
import AmgFilter from './AmgFilter';
import AmgInfo from './AmgInfo';
import AmgLogo from './AmgLogo';
import { useLocation } from 'react-router-dom';

const PREFIX = 'AmgAppBar';

const classes = {
  grow: `${PREFIX}-grow`,
  toolbar: `${PREFIX}-toolbar`,
};

const StyledAppBar = styled(AppBar)((
  {
    theme,
  }
) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    height: '60px',

    '& svg': {
      fill: theme.palette.amgWhite,
      height: '20px',
      width: '20px',
    },
  },
}));

function AmgAppBar() {

  const isHome = useSelector((state) => state.status.isHome);
  const location = useLocation();

  // Do not show the App Bar on the maintenance page
  if (location.pathname === '/maintenance') {
    return null;
  }

  return (
    <StyledAppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        {isHome && <AmgLogo />}
        {!isHome && <AmgBack />}
        <div className={classes.grow} />
        <AmgInfo />
        {isHome && <AmgFilter />}
      </Toolbar>
    </StyledAppBar>
  );
}

export default AmgAppBar;
