import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import AmgLabel from './AmgLabel';
import { maxChar } from '../utils';

const PREFIX = 'AmgActivityStreamNews';

const classes = {
  emission: `${PREFIX}-emission`,
  description: `${PREFIX}-description`,
  headline: `${PREFIX}-headline`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.emission}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.description}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.headline}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },
}));

function AmgActivityStreamNews({ content }) {

  return (
    (<Root>
      <AmgImage content={content.image} />
      <AmgLabel type={content.category} />
      <AmgHeadline
        className={classes.headline}
        component="div"
        variant="headlineSlider"
      >
        {maxChar(content.title, 48)}
      </AmgHeadline>
      {content.description &&
        <div className={classes.description}>
          {maxChar(content.description, 85)}
        </div>
      }
      {content.emissions &&
        <AmgEmissionStatement
          className={classes.emission}
          content={content.emissions}
        />
      }
    </Root>)
  );
}

export default AmgActivityStreamNews;

AmgActivityStreamNews.propTypes = {
  content: PropTypes.object.isRequired,
};
