import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PREFIX = 'AmgHeadlineText';

const classes = {
  line: `${PREFIX}-line`,
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`& .${classes.line}`]: {
    backgroundColor: theme.palette.amgWhite,
    border: 0,
    height: '1px',
    width: 'calc(100% / 3)',
  },

  [`&.${classes.root}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.text}`]: {
    fontFamily: 'MBCorpoSTitle-Regular-Web',
    fontSize: '1.5rem',
    lineHeight: 1.1,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    textAlign: 'center',
  },
}));

function AmgHeadlineText({ content }) {

  return (
    <StyledBox className={classes.root}>
      <hr className={classes.line} />
      <div className={classes.text}>
        {content.text}
      </div>
      <hr className={classes.line} />
    </StyledBox>
  );
}

export default AmgHeadlineText;

AmgHeadlineText.propTypes = {
  content: PropTypes.object.isRequired,
};
