function useCsrfToken() {
  const get = async () => {
    const response = await fetch('/libs/granite/csrf/token.json');
    const json = await response.json();

    return json.token;
  };

  return { get };
}

export default useCsrfToken;
