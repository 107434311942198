export const SET_ENV = 'SET_ENV';
export const SET_HAS_NEW_POSTS = 'SET_HAS_NEW_POSTS';
export const SET_PAGE = 'SET_PAGE';
export const SET_USER = 'SET_USER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_UPDATE_DRAWER = 'TOGGLE_UPDATE_DRAWER';
export const UPDATE_ACTIVITY_STREAM = 'UPDATE_ACTIVITY_STREAM';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_YOUTUBE_LIST = 'UPDATE_YOUTUBE_LIST';
export const YOUTUBE_CONSENT = 'YOUTUBE_CONSENT';
