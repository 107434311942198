import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './css/animations.css';
import './css/fonts.css';
import './css/index.css';

import AmgApp from './AmgApp';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Enforce the use of index.html as the entry point for the PWA when loading the PWA from the ServiceWorker
if (!window.location.pathname.startsWith('/app/index.html')) {
  window.location.href = '/app/index.html';
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <AmgApp />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
