import { useEffect, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';

import AmgHeadline from '../components/AmgHeadline';
import AmgMode from '../components/AmgMode';
import AmgSettingsListItem from '../components/AmgSettingsListItem';
import AmgSettingsLogout from '../components/AmgSettingsLogout';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgSettings';

const classes = {
  list: `${PREFIX}-list`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.list}`]: {
    borderBottom: `1px solid ${theme.palette.amgGreyDisabled}`,
    padding: 0,

    '& svg': {
      fill: theme.palette.amgMineshaftPure,
    },
  },

  [`&.${classes.wrapper}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function AmgSettings() {
  const { trackEvent } = useTracking();
  const translate = useTranslate();

  const page = useMemo(() => {
    const trackingType = 'pwa-settings';

    return {
      contentProduct: trackingType,
      contentProject: trackingType,
      name: translate('pwa.settings.overview'),
      siteSection: trackingType,
      type: trackingType,
    };
  }, [translate]);

  useEffect(() => {
    trackEvent({
      event: 'pageview',
      page,
    });
  }, [page, trackEvent]);

  return (
    <StyledAmgMode mode="light" className={classes.wrapper}>
      <AmgHeadline component="h1" variant="subHeadline">
        {translate('pwa.settings.overview')}
      </AmgHeadline>
      <List className={classes.list} component="nav">
        <AmgSettingsListItem link="language" />
        <AmgSettingsListItem link="details" />
        <AmgSettingsListItem link="privacy" />
        <AmgSettingsLogout />
      </List>
    </StyledAmgMode>
  );
}

export default AmgSettings;
