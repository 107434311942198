import { styled } from '@mui/material/styles';

import AmgHeadline from './AmgHeadline';
import AmgLabel from './AmgLabel';

const PREFIX = 'AmgActivityStreamSkeleton';

const classes = {
  label: `${PREFIX}-label`,
  post: `${PREFIX}-post`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.label}`]: {
    width: 80,
  },

  [`&.${classes.post}`]: {
    animation: 'shine-skeleton 2s infinite linear',
    backgroundColor: theme.palette.amgGreyDisabled,
    backgroundImage: `linear-gradient(90deg, ${theme.palette.amgGreyDisabled} 0px, ${theme.palette.amgWhite} 100px, ${theme.palette.amgGreyDisabled} 200px)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 2000,
    height: 200,
    margin: theme.spacing(0, 2, 2),
    position: 'relative',
  },
}));

function AmgActivityStreamSkeleton() {

  return (
    <Root className={classes.post}>
      <AmgLabel className={classes.label} type="" />
      <AmgHeadline
        className={classes.headline}
        component="div"
        variant="headlineSlider"
      />
    </Root>
  );
}

export default AmgActivityStreamSkeleton;
