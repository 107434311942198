import { UPDATE_STATUS } from '../actionTypes';

const initialState = {
  code: 'ok',
  isExpired: false,
  isHome: false,
};

const status = (state = initialState, action = {}) => {
  if (action.type === UPDATE_STATUS) {
    // If the previous call returns something different than 200, freeze the app and show an error/maintenance page
    if (state.code !== 'ok') {
      return { ...state };
    }
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};

export default status;
