import { useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import Avatar from '@mui/material/Avatar';

import AmgButton from '../components/AmgButton';
import AmgHeadline from '../components/AmgHeadline';
import AmgMode from '../components/AmgMode';
import { ReactComponent as ExternalLinkIcon } from '../svg/external-link.svg';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgProfile';

const classes = {
  avatar: `${PREFIX}-avatar`,
  background: `${PREFIX}-background`,
  description: `${PREFIX}-description`,
  img: `${PREFIX}-img`,
  username: `${PREFIX}-username`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.avatar}`]: {
    border: `3px solid ${theme.palette.amgWhite}`,
    borderRadius: 1,
    bottom: 0,
    height: 94,
    left: 'calc(50% - 50px)',
    margin: '30px auto 0',
    position: 'absolute',
    width: 94,
  },

  [`& .${classes.background}`]: {
    height: 200,
    position: 'relative',
  },

  [`& .${classes.description}`]: {
    margin: theme.spacing(4, 0),
    textAlign: 'center',
  },

  [`& .${classes.img}`]: {
    height: 150,
    objectFit: 'cover',
    width: '100%',
  },

  [`& .${classes.username}`]: {
    fontSize: 28,
    marginTop: theme.spacing(3),
    textAlign: 'center',
    textTransform: 'none',
    wordBreak: 'break-word',
  },

  [`&.${classes.wrapper}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function AmgProfile() {
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const env = useSelector((state) => state.env);
  const user = useSelector((state) => state.user);

  const page = useMemo(() => {
    const trackingType = 'pwa-profile';

    return {
      contentProduct: trackingType,
      contentProject: trackingType,
      siteSection: trackingType,
      type: trackingType,
    };
  }, []);

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'user-profile',
        category: 'link',
        destinationUrl: env.myProfileLink.attributeMap.href,
        label: 'see-complete-profile',
      },
    });

    window.open(env.myProfileLink.attributeMap.href, '_self');
  };

  useEffect(() => {
    trackEvent({
      event: 'pageview',
      page: {
        ...page,
        name: translate('pwa.myProfile.title'),
      },
    });
  }, [page, trackEvent, translate]);

  return (
    <StyledAmgMode mode="light" className={classes.wrapper}>
      <AmgHeadline component="h1" variant="subHeadline">
        {translate('pwa.myProfile.title')}
      </AmgHeadline>
      {user.cover_image && (
        <div className={classes.background}>
          <img className={classes.img} src={user.cover_image} alt="Background" />
          <Avatar className={classes.avatar} src={user.amg_avatar} alt="Username" />
        </div>
      )}
      <h3 className={classes.username}>
        {user.display_name}
      </h3>
      <div className={classes.description}>
        {translate('pwa.myProfile.linkout.description')}
      </div>
      {env.myProfileLink && (
        <AmgButton fullWidth onClick={handleClick} endIcon={<ExternalLinkIcon />}>
          {translate('pwa.myProfile.linkout.button')}
        </AmgButton>
      )}
    </StyledAmgMode>
  );
}

export default AmgProfile;
