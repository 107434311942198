import { useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { toggleDrawer } from '../redux/actions';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgDrawerHeader';

const classes = {
  close: `${PREFIX}-close`,
  icon: `${PREFIX}-icon`,
  listItemText: `${PREFIX}-listItemText`,
  text: `${PREFIX}-text`,
};

const StyledListItem = styled(ListItem)((
  {
    theme,
  }
) => ({
  [`&.${classes.close}`]: {
    padding: theme.spacing(3, 2),
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.amgWhite,
  },

  [`& .${classes.listItemText}`]: {
    margin: 0,
  },

  [`& .${classes.text}`]: {
    fontFamily: 'MBCorpoSTitle-ExtraBold-Web',
    fontSize: '18px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
}));

function AmgDrawerHeader() {

  const translate = useTranslate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleDrawer(false));
  };

  return (
    <StyledListItem
      button
      className={classes.close}
      onClick={handleClick}
    >
      <ListItemIcon>
        <ChevronRightIcon className={classes.icon} />
      </ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.text,
          root: classes.listItemText,
        }}
        primary={translate('pwa.settings.filter.header')}
      />
    </StyledListItem>
  );
}

export default AmgDrawerHeader;
