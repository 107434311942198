import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import AmgHeroStageHero from './AmgHeroStageHero';
import AmgHeroStageTeaser from './AmgHeroStageTeaser';

function AmgHeroStage({ content }) {
  return (
    <Box>
      {content.heroTeaser && <AmgHeroStageHero content={content.heroTeaser} />}
      {content.subTeasers && content.subTeasers.map((teaser, index) => {
        return (
          <AmgHeroStageTeaser key={index} content={teaser} />
        );
      })}
    </Box>
  );
}

export default AmgHeroStage;

AmgHeroStage.propTypes = {
  content: PropTypes.object.isRequired,
};
