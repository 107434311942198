import { SET_ENV } from '../actionTypes';

// Make sure the object remains empty to avoid an infinite loop during the login flow
const initialState = {};

const env = (state = initialState, action = {}) => {
  if (action.type === SET_ENV) {
    return { ...state, ...action.payload };
  } else {
    return state;
  }
};

export default env;
