import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AmgDivider from './AmgDivider';

const PREFIX = 'AmgHeadlines';

const classes = {
  box: `${PREFIX}-box`,
  divider: `${PREFIX}-divider`,
  headline: `${PREFIX}-headline`,
  subHeadline: `${PREFIX}-subHeadline`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`&.${classes.box}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.headline}`]: {
    fontFamily: 'MBCorpoSTitle-ExtraBold-Web',
    fontSize: '30px',
    lineHeight: '32px',
    margin: theme.spacing(0, 0, 3, 0),
    textTransform: 'uppercase',
  },

  [`& .${classes.subHeadline}`]: {
    fontFamily: 'MBCorpoSText-Regular-Web',
    fontSize: '16px',
    lineHeight: '21px',
    margin: theme.spacing(0, 0, 3, 0),
  },
}));

function AmgHeadlines({ className, headline, subHeadline }) {
  return (
    <StyledBox className={clsx(classes.box, className, 'amg-headlines')}>
      {subHeadline && (
        <Typography component="h3" className={classes.subHeadline}>
          {subHeadline}
        </Typography>
      )}
      {headline && subHeadline && (
        <Box sx={{ mb: 3 }}>
          <AmgDivider />
        </Box>
      )}
      {headline && (
        <Typography component="h2" className={classes.headline}>
          {headline}
        </Typography>
      )}
    </StyledBox>
  );
}

export default AmgHeadlines;

AmgHeadlines.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  subHeadline: PropTypes.string,
};
