import PropTypes from 'prop-types';

import { SliderThumb } from '@mui/material/Slider';

import { ReactComponent as ChevronLeftIcon } from '../svg/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../svg/chevron-right.svg';

function AmgComparisonSliderThumb({ children, ...other }) {
  return (
    <SliderThumb {...other}>
      {children}
      <ChevronLeftIcon />
      <ChevronRightIcon />
    </SliderThumb>
  );
}

export default AmgComparisonSliderThumb;

AmgComparisonSliderThumb.propTypes = {
  children: PropTypes.node.isRequired,
};
