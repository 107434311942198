import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import AmgHeadlines from './AmgHeadlines';
import AmgVideoYoutubeConsent from './AmgVideoYoutubeConsent';
import AmgVideoYoutubePlayer from './AmgVideoYoutubePlayer';

const PREFIX = 'AmgVideoYoutube';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.wrapper}`]: {
    height: 0,
    paddingBottom: '56.25%', // 16:9
    position: 'relative',

    '& iframe': {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
}));

function AmgVideoYoutube({ content }) {

  const isConsentGiven = useSelector((state) => state.ytpconsent.accepted);

  return (
    <StyledBox>
      <AmgHeadlines headline={content.headline.headline} subHeadline={content.headline.topicLine} />
      <div className={classes.wrapper}>
        {isConsentGiven ? <AmgVideoYoutubePlayer content={content} /> : <AmgVideoYoutubeConsent fallbackImage={content.fallbackImage} />}
      </div>
    </StyledBox>
  );
}

export default AmgVideoYoutube;

AmgVideoYoutube.propTypes = {
  content: PropTypes.object.isRequired,
};
