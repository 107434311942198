const MQs = {
  MQ6: '1440px',
  MQ5: '1280px',
  MQ4: '1024px',
  MQ3: '768px',
  MQ2: '480px',
  MQ1: '0px',
};

function useMediaQuery() {
  let currentMQ;

  Object.entries(MQs).forEach((el) => {
    if (!currentMQ && window.matchMedia(`(min-width: ${el[1]})`).matches) {
      currentMQ = el[0];
    }
  });

  return currentMQ;
}

export default useMediaQuery;
