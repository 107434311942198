export const AmgParticipationFormSettings = (content) => {
  return [
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.participation.label.name'],
      name: 'name',
      required: content.i18nKeys['amg.communities.participation.required.name'],
      type: 'text',
      value: content?.user?.name || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.participation.label.surname'],
      name: 'surname',
      required: content.i18nKeys['amg.communities.participation.required.surname'],
      type: 'text',
      value: content?.user?.surname || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.participation.label.email'],
      name: 'email',
      required: content.i18nKeys['amg.communities.participation.required.email'],
      validation: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // email validation pattern
      type: 'text',
      value: content?.user?.email || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.street'],
      name: 'street',
      required: content.i18nKeys['required.street'],
      type: 'text',
      value: content?.user?.street || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.houseNumber.label'],
      name: 'houseNumber',
      required: false,
      type: 'text',
      value: content?.user?.houseNumber || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.addressLine.label'],
      name: 'addressLine',
      required: false,
      type: 'text',
      value: content?.user?.addressLine || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.postalCode'],
      name: 'postalCode',
      required: content.i18nKeys['required.postal.code'],
      type: 'text',
      value: content?.user?.postalCode || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.city'],
      name: 'city',
      required: content.i18nKeys['required.city'],
      type: 'text',
      value: content?.user?.city || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.countryState'],
      name: 'countryState',
      required: false,
      type: 'text',
      value: content?.user?.countryState || '',
    },
    {
      cssClass: 'textField',
      countries: content.countries,
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.country'],
      name: 'country',
      required: false,
      type: 'select',
      value: content?.user?.country || '',
    },
    {
      cssClass: 'textField',
      fullWidth: true,
      label: content.i18nKeys['amg.communities.userprofile.myData.phone.label'],
      name: 'phone',
      required: false,
      type: 'text',
      value: content?.user?.phone || '',
    },
    {
      cssClass: 'checkBox',
      id: 'termsConditions-participation',
      label: content.i18nKeys['amg.communities.participation.text.termsAndConditions'],
      linkUrl: content.termsConditionsPath,
      name: 'termsConditions',
      required: content.i18nKeys['amg.communities.participation.required.termsConditions'],
      type: 'checkbox',
      value: 'yes',
    },
    {
      cssClass: 'checkBox',
      id: 'termsProcessing-participation',
      label: content.i18nKeys['communities.participation.text.dataProcessingTerms'],
      linkUrl: content.dataProcessingTermsLink,
      name: 'termsProcessing',
      required: content.i18nKeys['communities.participation.text.dataProcessingTerms.required'],
      type: 'checkbox',
      value: 'yes',
    },
  ];
};
