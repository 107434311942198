import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
const PREFIX = 'AmgVideoProjectOne';

const classes = {
  video: `${PREFIX}-video`,
};

const Root = styled('video')((
  {
    theme,
  }
) => ({
  [`&.${classes.video}`]: {
    width: '100%',
  },
}));

function AmgVideoProjectOne({ content }) {

  // Split the string with '.' and return the last array element to get the file extension
  const fileExtension = content.videoRef.split('.').pop();

  return (
    <Root
      controls
      poster={content.pictureRef}
      className={classes.video}
      data-testid="AmgVideoProjectOne"
    >
      <source
        src={content.videoRef}
        type={`video/${fileExtension}`}
      />
      Sorry, your browser does not support embedded videos.
    </Root>
  );
}

export default AmgVideoProjectOne;

AmgVideoProjectOne.propTypes = {
  content: PropTypes.object.isRequired,
};
