import { combineReducers } from 'redux';

import drawer from './drawer';
import env from './env';
import page from './page';
import post from './post';
import status from './status';
import user from './user';
import ytpconsent from './ytpconsent';

export default combineReducers({
  drawer,
  env,
  page,
  post,
  status,
  user,
  ytpconsent,
});
