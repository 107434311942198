import { useCallback, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';

import AmgVersion from './AmgVersion';
import AmgDrawerHeader from './AmgDrawerHeader';
import AmgDrawerItem from './AmgDrawerItem';
import { toggleDrawer, updateActivityStream } from '../redux/actions';
import { buildContentSearchUrl, fetchHandler, getMaxTimestampFromPosts, hasFiltersBeenUpdated } from '../utils';
import useTracking from '../hooks/useTracking';
import { Box } from '@mui/material';

const PREFIX = 'AmgDrawer';

const classes = {
  divider: `${PREFIX}-divider`,
  list: `${PREFIX}-list`,
  paper: `${PREFIX}-paper`,
  version: `${PREFIX}-version`,
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)((
  {
    theme,
  }
) => ({
  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.amgObsidian,
    borderColor: theme.palette.amgObsidian,
    height: 1,
    margin: theme.spacing(0, 2),
  },

  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.amgMineshaftGrey,
    color: theme.palette.amgWhite,
    width: 300,
  },
}));

function AmgDrawer() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.user.filters);
  const { filtersOnDrawer, open } = useSelector((state) => state.drawer);
  const { trackEvent } = useTracking();
  const isFirstRender = useRef(true);

  // Fetch new posts on first load
  const fetchInitPosts = useCallback(async () => {
    const url = buildContentSearchUrl({
      maxEditorialPosts: 10,
      maxForumPosts: 10,
    });
    const json = await fetchHandler(url);

    dispatch(updateActivityStream({
      lastTimestamp: getMaxTimestampFromPosts(json.results),
      nextLink: json._links ? json._links.next : '',
      posts: json.results || [],
    }));
  }, [dispatch]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      trackEvent({
        event: 'click',
        eventDetail: {
          action: 'drawer',
          caegory: 'navigation click',
          label: open ? 'open' : 'close',
        },
      });
    }
  }, [open, trackEvent]);

  useEffect(() => {
    // Retrieve posts only when we close the Drawer or on the 1st load
    if (!open && hasFiltersBeenUpdated(filters, filtersOnDrawer)) {
      fetchInitPosts();
    }
  }, [fetchInitPosts, filters, filtersOnDrawer, open]);

  return (
    <StyledSwipeableDrawer
      swipeAreaWidth={-10}
      anchor="right"
      classes={{ paper: classes.paper, root: classes.root }}
      open={open}
      role="presentation"
      onOpen={() => dispatch(toggleDrawer(true))}
      onClose={() => dispatch(toggleDrawer(false))}
    >
      <List component="div" disablePadding sx={{ flexGrow: 1 }}>
        <AmgDrawerHeader />
        <Divider className={classes.divider} />
        {Object.entries(filters).map(([key, value]) => (
          <div key={key}>
            <AmgDrawerItem filterKey={key} isActive={value} />
            <Divider className={classes.divider} />
          </div>
        ))}
      </List>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        <AmgVersion />
      </Box>
    </StyledSwipeableDrawer>
  );
}

export default AmgDrawer;
