import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';

import AmgButton from './AmgButton';
import AmgMode from './AmgMode';
import AmgVersion from './AmgVersion';
import { ReactComponent as AlertCircle } from '../svg/alert-circle.svg';
import { ReactComponent as WifiIcon } from '../svg/wifi_icon.svg';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgError';

const classes = {
  box: `${PREFIX}-box`,
  button: `${PREFIX}-button`,
  headline: `${PREFIX}-headline`,
  text: `${PREFIX}-text`,
  version: `${PREFIX}-version`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`&.${classes.box}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',

    '& svg': {
      fill: theme.palette.amgMineshaftPure,
      height: '107px',
      width: '107px',
    },
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(4),
    width: '100%',
  },

  [`& .${classes.headline}`]: {
    margin: theme.spacing(7, 0, 4),
  },

  [`& .${classes.text}`]: {
    fontFamily: 'MBCorpoSText-Light-Web',
    fontSize: '18px',
    lineHeight: '23px',
    margin: theme.spacing(0, 2),
  },

  [`& .${classes.version}`]: {
    marginTop: theme.spacing(4),
  },
}));

const mapStateToProps = (state) => {
  return {
    lang: state.user.preferred_language,
  };
};

const icons = {
  emptyStream: AlertCircle,
  error: AlertCircle,
  expired: AlertCircle,
  offline: WifiIcon,
};

function AmgError({ lang, variant }) {

  const translate = useTranslate();
  const Icon = icons[variant];

  // The "Back" button is technically just reloading the PWA, landing on the ActStr which is the expected behaviour
  const handleGoHome = () => {
    // useNavigate() may be used only in the context of a <Router> component
    window.location.href = '/app/index.html';
  };

  const handleReload = () => {
    window.open('/bin/daimler/public/ciam/authent.html?pwa=true', '_self');
  };

  return (
    <StyledAmgMode mode="dark" className={classes.box}>
      <Icon />
      <h2 className={classes.headline}>
        {translate(`pwa.${variant}.header`)}
      </h2>
      <div className={classes.text}>
        {translate(`pwa.${variant}.message`)}
      </div>
      <div className={classes.button}>
        {variant === 'error' &&
          <AmgButton fullWidth onClick={handleGoHome}>
            {translate('pwa.error.back')}
          </AmgButton>
        }
        {(variant === 'expired' || variant === 'emptyStream') &&
          <AmgButton fullWidth onClick={handleReload}>
            {translate('pwa.expired.reload')}
          </AmgButton>
        }
      </div>
      <div className={classes.version}>
        <AmgVersion />
      </div>
    </StyledAmgMode>
  );
}

export default connect(
  mapStateToProps,
)(AmgError);

AmgError.propTypes = {
  lang: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
