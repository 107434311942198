import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';

import { ReactComponent as ChevronRightIcon } from '../svg/chevron-right.svg';
import useTranslate from '../hooks/useTranslate';
import useTracking from '../hooks/useTracking';

const StyledListItem = styled(ListItem)(() => {});

const mapStateToProps = (state) => {
  return {
    lang: state.user.preferred_language,
  };
};

const icons = {
  details: PersonIcon,
  language: PublicIcon,
  privacy: LockIcon,
};

function AmgSettingsListItem({ link }) {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const theme = useTheme();

  const Icon = icons[link];

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'link',
        category: 'settings',
        label: link,
      },
    });

    navigate(`/${link}`);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{ borderTop: `1px solid ${theme.palette.amgGreyDisabled}`, px: 0, py: 1 }}
    >
      <StyledListItem>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={translate(`pwa.settings.overview.${link}`)} />
        <ListItemSecondaryAction>
          <ChevronRightIcon />
        </ListItemSecondaryAction>
      </StyledListItem>
    </Box>
  );
}

export default connect(
  mapStateToProps,
)(AmgSettingsListItem);

AmgSettingsListItem.propTypes = {
  lang: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
