import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

const PREFIX = 'AmgChip';

const classes = {
  chip: `${PREFIX}-chip`,
};

const StyledChip = styled(Chip)((
  {
    theme,
  }
) => ({
  [`&.${classes.chip}`]: {
    backgroundColor: theme.palette.amgRed,
    color: theme.palette.amgWhite,
    left: '50%',
    position: 'fixed',
    top: 90,
    transform: 'translate(-50%, 0)',
    zIndex: 1,

    '&:hover': {
      backgroundColor: theme.palette.amgRed,
    },
  },
}));

function AmgChip({ label, onClick }) {

  return <StyledChip clickable label={label} className={classes.chip} onClick={onClick} />;
}

export default AmgChip;

AmgChip.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
