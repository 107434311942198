import AmgArticleTeaserOverview from './components/AmgArticleTeaserOverview';
import AmgComparisonSlider from './components/AmgComparisonSlider';
import AmgFreeMediaGallery from './components/AmgFreeMediaGallery';
import AmgFullWidthImage from './components/AmgFullWidthImage';
import AmgHeadlineText from './components/AmgHeadlineText';
import AmgHeroStage from './components/AmgHeroStage';
import AmgImageGallery from './components/AmgImageGallery';
import AmgImageText from './components/AmgImageText';
import AmgMediaSlider from './components/AmgMediaSlider';
import AmgParticipation from './components/AmgParticipation';
import AmgQuickLinks from './components/AmgQuickLinks';
import AmgStage from './components/AmgStage';
import AmgTabbedContent from './components/AmgTabbedContent';
import AmgTable from './components/AmgTable';
import AmgTeaserCombination from './components/AmgTeaserCombination';
import AmgText from './components/AmgText';
import AmgVideoDam from './components/AmgVideoDam';
import AmgVideoOnDemand from './components/AmgVideoOnDemand';
import AmgVideoProjectOne from './components/AmgVideoProjectOne';
import AmgVideoYoutube from './components/AmgVideoYoutube';

const MAP_HOST_TO_ENV = {
  'localhost': 'dev',
  'test-publish.amg4web.aem.oneweb.mercedes-benz.com': 'test',
  'www-test.mercedes-amg.com': 'int',
  'www.mercedes-amg.com': 'prod',
};

const MAP_TYPE_TO_COMPONENTS = {
  'amg/communities/core/components/participation': AmgParticipation,
  'amg/components/components/content/articleTeaserOverview': AmgArticleTeaserOverview,
  'amg/components/components/content/comparisonSlider': AmgComparisonSlider,
  'amg/components/components/content/damvideo': AmgVideoDam,
  'amg/components/components/content/freemediagallery': AmgFreeMediaGallery,
  'amg/components/components/content/fullWidthImage': AmgFullWidthImage,
  'amg/components/components/content/headlineText': AmgHeadlineText,
  'amg/components/components/content/heroStage': AmgHeroStage,
  'amg/components/components/content/imageText': AmgImageText,
  'amg/components/components/content/mediaSlider': AmgMediaSlider,
  'amg/components/components/content/quickLinks': AmgQuickLinks,
  'amg/components/components/content/sixImageGallery': AmgImageGallery,
  'amg/components/components/content/stage': AmgStage,
  'amg/components/components/content/standardVideo': AmgVideoYoutube,
  'amg/components/components/content/tabbedContent': AmgTabbedContent,
  'amg/components/components/content/table': AmgTable,
  'amg/components/components/content/teaserCombination': AmgTeaserCombination,
  'amg/components/components/content/text': AmgText,
  'amg/components/components/content/threeImageGallery': AmgImageGallery,
  'amg/components/components/content/videoOnDemand': AmgVideoOnDemand,
  'communities/core/components/projectonevideo2': AmgVideoProjectOne,
};

export {
  MAP_HOST_TO_ENV,
  MAP_TYPE_TO_COMPONENTS,
};
