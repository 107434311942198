import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleYTPConsent as toggleYTPConsentAction } from '../redux/actions';

function AmgConsentObserver({ toggleYTPConsent }) {
  const dataLayerChangeObserver = {
    set: (obj, prop, value) => {
      // eslint-disable-next-line no-param-reassign
      obj[prop] = value;

      Object.keys(value).some((key) => {
        if (key === 'YouTube Video') {
          toggleYTPConsent(value[key]);
        }
        return true;
      });
      return true;
    },
    get: (object, prop) => object[prop],
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer = new Proxy(window.dataLayer, dataLayerChangeObserver);

  return null;
}

export default connect(
  null,
  { toggleYTPConsent: toggleYTPConsentAction },
)(AmgConsentObserver);

AmgConsentObserver.propTypes = {
  toggleYTPConsent: PropTypes.func.isRequired,
};
