import { useSelector } from 'react-redux';

function useCookieLayer() {
  const lang = useSelector((state) => state.user.preferred_language);
  const UcScriptId = 'MhcfbQZxs';
  const existingScript = document.getElementById(UcScriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://app.usercentrics.eu/latest/main.js'; // URL for the third-party library being loaded.
    script.id = UcScriptId;
    script.setAttribute('language', lang.toLowerCase());
    script.setAttribute('type', 'application/javascript');
    document.head.appendChild(script);
  }
}

export default useCookieLayer;
