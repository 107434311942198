import {
  SET_ENV,
  SET_HAS_NEW_POSTS,
  SET_PAGE,
  SET_USER,
  TOGGLE_DRAWER,
  TOGGLE_UPDATE_DRAWER,
  UPDATE_ACTIVITY_STREAM,
  UPDATE_FILTER,
  UPDATE_STATUS,
  UPDATE_YOUTUBE_LIST,
  YOUTUBE_CONSENT,
} from './actionTypes';

export const setEnv = (vars) => ({
  type: SET_ENV,
  payload: vars,
});

export const setHasNewPosts = (posts) => ({
  type: SET_HAS_NEW_POSTS,
  payload: posts,
});

export const setPage = (page) => ({
  type: SET_PAGE,
  payload: page,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const toggleDrawer = (open) => ({
  type: TOGGLE_DRAWER,
  payload: { open },
});
export const updateFiltersDrawer = (filtersOnDrawer) => ({
  type: TOGGLE_UPDATE_DRAWER,
  payload: { filtersOnDrawer },
});

export const updateActivityStream = (obj) => ({
  type: UPDATE_ACTIVITY_STREAM,
  payload: obj,
});

export const updateFilter = (filter, isActive) => ({
  type: UPDATE_FILTER,
  payload: { filter, isActive },
});

export const updateStatus = (obj) => ({
  type: UPDATE_STATUS,
  payload: obj,
});

export const toggleYTPConsent = (accepted) => ({
  type: YOUTUBE_CONSENT,
  payload: { accepted },
});

export const updateYoutubeList = (playerId, video, player) => ({
  type: UPDATE_YOUTUBE_LIST,
  payload: { playerId, video, player },
});
