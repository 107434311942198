import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';

import { ReactComponent as InfoIcon } from '../svg/i-icon.svg';
import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgInfo';

const classes = {
  info: `${PREFIX}-info`,
};

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.info}`]: {
    marginRight: 0,
  },
}));

const mapStateToProps = (state) => {
  return {
    lang: state.user.preferred_language,
  };
};

function AmgInfo({ lang }) {

  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'app bar',
        category: 'navigation click',
        label: 'info',
      },
    });

    window.open(`https://www.mercedes-amg.com/${lang.toLowerCase()}/footer/provider-data-protection.html`, '_blank');
  };

  return (
    <StyledIconButton
      aria-label="privacy policy"
      className={classes.info}
      edge="end"
      color="inherit"
      onClick={handleClick}
      size="large">
      <InfoIcon />
    </StyledIconButton>
  );
}

export default connect(
  mapStateToProps,
)(AmgInfo);

AmgInfo.propTypes = {
  lang: PropTypes.string.isRequired,
};
