import { SET_PAGE } from '../actionTypes';

const initialState = {};

const page = (state = initialState, action = {}) => {
  if (action.type === SET_PAGE) {
    return action.payload;
  } else {
    return state;
  }
};

export default page;
