import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import pkg from '../../package.json';
import { MAP_HOST_TO_ENV } from '../constants';
import useMediaQuery from './useMediaQuery';

function useTracking() {
  const mediaQuery = useMediaQuery();
  const user = useSelector((state) => state.user);

  const trackEvent = useCallback((data) => {
    const userLayer = user.user_layer.slice(-1).toUpperCase(); // A | B | C | D
    const sysEnv = MAP_HOST_TO_ENV[window.location.hostname];
    let pageName;
    let pagePath;
    let pageType;

    // In case content is coming from aem, get the values from the page else from pwa internal pages like settings...
    if (data.page?.url) {
      const purePath = data.page.url.replace(/.model.json/gi, '');
      pageName = data.page?.title;
      pagePath = `${pkg.homepage}/index.html#${purePath}`;
      pageType = data.page?.type?.split('/').pop();
    } else {
      pageName = data.page?.name;
      pagePath = `${pkg.homepage}/index.html${window.location.hash}`;
      pageType = data.page?.type;
    }

    const layer = {
      application: {
        id: 'pwa', // Constant value
        isStandalone: ('standalone' in window.navigator) && window.navigator.standalone,
        version: pkg.version, // i.e "1.9.5-11"
      },
      event: data.event,
      eventDetail: data.eventDetail,
      page: {
        pageName,
        pagePath,
        pageType,
        ...data.page,
      },
      site: {
        language: user.preferred_language,
        sysEnv,
        mediaQuery,
      },
      user: {
        id: user.hashId, // Hashed ID, i.e. "P-123ABC4ZMLGYRP6W-DDR2NZJPR2DKXQN3-DE"
        loginState: 'logged in', // logged in | logged out
        userType: userLayer, // Is this both needed with page.layer?
        ...data.user,
      },
    };

    window.dataLayer_dag = window.dataLayer_dag || [];
    window.dataLayer_dag.push(layer);
  }, [mediaQuery, user]);

  return { trackEvent };
}

export default useTracking;
