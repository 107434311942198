import { useRef } from 'react';
import PropTypes from 'prop-types';

import 'video.js/dist/video-js.css';
import ReactPlayer from 'react-player';

export const AmgVideoJS = ({ options }) => {
  const videoRef = useRef(null);

  return (
    <div style={{ position: 'relative', paddingTop: '56.25%' }}>
      <ReactPlayer
        ref={videoRef}
        url={options.sources[0].src}
        controls={true}
        width={'100%'}
        height={'auto'}
        config={{ file: { attributes: { poster: options.poster } } }}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );
};

export default AmgVideoJS;

AmgVideoJS.propTypes = {
  onReady: PropTypes.func.isRequired,
  options: PropTypes.shape({
    autoplay: PropTypes.bool.isRequired,
    sources: PropTypes.array.isRequired,
    poster: PropTypes.string.isRequired,
  }),
};
