import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AmgBanner from './AmgBanner';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

function AmgDeviceNotificationBanner() {
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const { preferred_language, user_layer } = useSelector((state) => state.user);

  const getUrlToHome = () => {
    // Layer is something like "layer_c", we get the "_c" and transform it to "-c" to acccess the specific home for the layer
    const layerShorthand = user_layer && user_layer.length > 1 ? user_layer.slice(-2).replace('_', '-') : '';
    return `https://www.mercedes-amg.com/content/global/${preferred_language.toLowerCase()}/home${layerShorthand}.html`;
  };

  const handleClose = () => {
    track('close');
    window.sessionStorage.setItem('deviceNotification', true);
  };

  const handleClick = () => {
    track('exit');
    const homeUrl = getUrlToHome();
    window.open(homeUrl, '_self');
  };

  const track = useCallback((label) => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'pwa-optimized-info',
        category: 'link',
        label,
      },
    });
  }, [trackEvent]);

  useEffect(() => {
    track('displayed');
  }, [track]);

  return (
    <AmgBanner handleClose={handleClose}>
      {translate('pwa.homescreen.compatibleDevice.text.1')}<span onClick={handleClick}>{translate('pwa.homescreen.compatibleDevice.text.2')}</span>{translate('pwa.homescreen.compatibleDevice.text.3')}
    </AmgBanner>
  );
}

export default AmgDeviceNotificationBanner;
