import { useSelector } from 'react-redux';
import i18n from '../i18n';

function useTranslate() {
  const lang = useSelector((state) => state.user.preferred_language);

  return (key) => {
    return i18n[lang][key];
  };
}

export default useTranslate;
