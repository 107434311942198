import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateActivityStream } from '../redux/actions';

function useScrollTop() {
  const dispatch = useDispatch();

  useEffect(() => {
    let currentScrollTop = 0;

    const onScroll = (scrollEvent) => {
      if (scrollEvent.target.documentElement.scrollTop > 0) {
        currentScrollTop = scrollEvent.target.documentElement.scrollTop;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      dispatch(updateActivityStream({ scrollTop: currentScrollTop }));
      window.removeEventListener('scroll', onScroll);
    };
  }, [dispatch]);
}

export default useScrollTop;
