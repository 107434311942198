import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as ChevronDownIcon } from '../svg/chevron-down.svg';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadlines from './AmgHeadlines';
import AmgImage from './AmgImage';
import AmgParticipationForm from './AmgParticipationForm';
import AmgRichText from './AmgRichText';

const PREFIX = 'AmgParticipation';

const classes = {
  emissions: `${PREFIX}-emissions`,
  headline: `${PREFIX}-headline`,
  image: `${PREFIX}-image`,
  richText: `${PREFIX}-richText`,
  showMore: `${PREFIX}-showMore`,
  showMoreTxt: `${PREFIX}-showMoreTxt`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`& .${classes.emissions}`]: {
    margin: theme.spacing(0, 2),
  },

  [`& .${classes.headline}`]: {
    fontSize: '1rem',
    lineHeight: 1,
  },

  [`& .${classes.image}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.richText}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.showMore}`]: {
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.amgWhite}`,
    display: 'block',
    paddingTop: '10px',
    margin: '24px auto',
    textTransform: 'none',
    width: '80%',
  },

  [`& .${classes.showMoreTxt}`]: {
    color: theme.palette.amgWhite,
    fontFamily: 'MBCorpoSText-Regular-Web',
    fontSize: '1rem',
    lineHeight: '1.125rem',
    marginLeft: '12px',
  },
}));

function AmgParticipation({ content }) {

  const [view, setView] = useState('button');
  return (
    <StyledBox>
      {content.headline && <AmgHeadlines headline={content.headline} subHeadline={content.caption} className={classes.headline} />}
      {content.richText && <AmgRichText className={classes.richText} content={content.richText} />}
      {content.image && <AmgImage className={classes.image} content={content.image} />}
      {content.assetEmissionStatementModel && <AmgEmissionStatement className={classes.emissions} content={content.assetEmissionStatementModel} />}
      {/*generate show more button/ remove it when clicked*/}
      {view === 'button' &&
        <IconButton
          aria-label="show more information"
          className={classes.showMore}
          onClick={() => {
            setView('form');
          }}
          size="large">
          <ChevronDownIcon />
          <span className={classes.showMoreTxt}>{content.i18nKeys['amg.communities.participation.text.showmore']}</span>
        </IconButton>
      }
      {/*generate participation form, when show more button is clicked*/}
      {view === 'form' && <AmgParticipationForm content={content} />}
    </StyledBox>
  );
}

export default AmgParticipation;

AmgParticipation.propTypes = {
  content: PropTypes.object.isRequired,
};
