import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';

import {
  setHasNewPosts as setHasNewPostsAction,
} from '../redux/actions';

const PREFIX = 'AmgLogo';

const classes = {
  logo: `${PREFIX}-logo`,
};

const Root = styled('img')(() => ({
  [`&.${classes.logo}`]: {
    width: 133,
  },
}));

function AmgLogo({ setHasNewPosts }) {

  const handleClick = () => {
    window.dispatchEvent(new Event('refresh'));
    setHasNewPosts(false);
    window.scrollTo(0, 0);
  };

  return <Root src="amg-logo.svg" alt="AMG logo" className={classes.logo} onClick={handleClick} width="300" height="87" />;
}

export default connect(
  null,
  {
    setHasNewPosts: setHasNewPostsAction,
  },
)(AmgLogo);

AmgLogo.propTypes = {
  setHasNewPosts: PropTypes.func.isRequired,
};
