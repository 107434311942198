import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import { ReactComponent as ChevronRightIcon } from '../svg/chevron-right.svg';
import { buildHref } from '../utils';
import useTracking from '../hooks/useTracking';
import useDateBeautifier from '../hooks/useDateBeautifier';

const PREFIX = 'AmgArticleTeaser';

const classes = {
  date: `${PREFIX}-date`,
  dateSlider: `${PREFIX}-dateSlider`,
  emission: `${PREFIX}-emission`,
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  slider: `${PREFIX}-slider`,
  stack: `${PREFIX}-stack`,
  teaser: `${PREFIX}-teaser`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.date}`]: {
    background: theme.palette.amgWhite,
    color: theme.palette.amgBlack,
    padding: theme.spacing(0.5, 2),
    position: 'absolute',
    top: 20,
  },

  [`& .${classes.dateSlider}`]: {
    paddingLeft: theme.spacing(3),
  },

  [`& .${classes.emission}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.icon}`]: {
    height: 17,
  },

  [`& .${classes.link}`]: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.slider}`]: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },

  [`& .${classes.stack}`]: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },

  [`&.${classes.teaser}`]: {
    paddingBottom: theme.spacing(2),
    position: 'relative',
  },
}));

const mapStateToProps = (state) => {
  return {
    lang: state.user.preferred_language,
  };
};

function AmgArticleTeaser({ className, content, variant }) {

  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const articleDate = useDateBeautifier(content.date, 'short');

  const handleClick = () => {
    const href = buildHref(content.link);

    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'teaser',
        category: 'link',
        destinationUrl: decodeURIComponent(href),
        label: content.headline,
      },
    });

    if (href === '#') {
      window.reload();
    } else if (href.endsWith('.html')) {
      window.open(href, '_blank');
    } else {
      navigate(`/page/${encodeURIComponent(href)}`);
    }
  };

  return (
    <Root className={clsx(classes.teaser, className)} onClick={handleClick} role="link">
      <AmgImage content={content.image} />
      {content.date && <AmgHeadline
        className={clsx({
          [classes.date]: true,
          [classes.dateSlider]: variant === 'slider',
        })}
        component="div"
        variant="headline"
      >
        {articleDate}
      </AmgHeadline>}
      <AmgHeadline
        className={clsx({
          [classes.link]: true,
          [classes.slider]: variant === 'slider',
          [classes.stack]: variant === 'stack',
        })}
        component="div"
        variant="headlineSlider"
      >
        {content.headline}
        {variant === 'slider' && <ChevronRightIcon className={classes.icon} />}
      </AmgHeadline>
      <AmgEmissionStatement
        className={clsx({
          [classes.emission]: true,
          [classes.slider]: variant === 'slider',
          [classes.stack]: variant === 'stack',
        })}
        content={content.emissionStatement}
      />
    </Root>
  );
}

export default connect(
  mapStateToProps,
)(AmgArticleTeaser);

AmgArticleTeaser.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
