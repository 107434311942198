import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useRef } from 'react';

import AmgArrow from './AmgArrow';
import AmgDivider from './AmgDivider';
import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import AmgLink from './AmgLink';
import AmgSlider from './AmgSlider';
import AmgVideoDam from './AmgVideoDam';

const PREFIX = 'AmgMediaSlider';

const classes = {
  button: `${PREFIX}-button`,
  divider: `${PREFIX}-divider`,
  emissionStatement: `${PREFIX}-emissionStatement`,
  headline: `${PREFIX}-headline`,
  img: `${PREFIX}-img`,
  subHeadline: `${PREFIX}-subHeadline`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(0, 0, 4, 2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },

  [`& .${classes.divider}`]: {
    margin: theme.spacing(0, 2, 2, 2),
  },

  [`& .${classes.emissionStatement}`]: {
    margin: theme.spacing(1, 2, 0),
  },

  [`& .${classes.headline}`]: {
    margin: theme.spacing(0, 2, 2),
  },

  [`& .${classes.img}`]: {
    display: 'block',
    width: '100%',
  },

  [`& .${classes.subHeadline}`]: {
    margin: theme.spacing(0, 0, 4, 2),
  },
}));

function AmgMediaSlider({ content }) {

  const mediaSlider = useRef(null);

  if (!content.slidesModel || content.slidesModel.length === 0) {
    return null;
  }

  const settings = {
    arrows: true,
    dots: true,
    centerMode: false,
    eventDetailAction: 'media-slider',
    prevArrow: <AmgArrow variant="left" />,
    nextArrow: <AmgArrow variant="right" />,

    // Position the chevron exactly in the middle of the image inside a slider
    onReInit: () => {
      const container = mediaSlider.current.querySelector('.slick-active .amg-placeholder');
      const chevrons = mediaSlider.current.querySelectorAll('.slick-slider .chevron');

      if (container && chevrons) {
        // Get how far from the top of the slide the placeholder is, then get its height, then put the chevrons in the middle
        const top = container.offsetTop + (container.offsetHeight / 2) - 12; // 12 = height of the chevron / 2
        chevrons.forEach((chevron) => {
          chevron.style.top = `${top}px`;
        });
      }
    },
  };

  return (
    <Root ref={mediaSlider}>
      <AmgSlider settings={settings}>
        {content.slidesModel.map((slide, index) => (
          <div key={index}>
            {slide.topicLine && (
              <AmgHeadline variant="headline" className={classes.headline}>
                {slide.topicLine}
              </AmgHeadline>
            )}
            {slide.topicLine && slide.headline && <AmgDivider className={classes.divider} />}
            {slide.headline && (
              <AmgHeadline variant="subHeadline" className={classes.subHeadline}>
                {slide.headline}
              </AmgHeadline>
            )}
            <AmgLink className={classes.button} eventdetailaction="media-slider" link={slide.link} />
            {slide.media.mediaVideoImage.image && <AmgImage className={classes.img} content={slide.media.mediaVideoImage.image} />}
            {slide.video && (
              <AmgVideoDam
                content={{
                  posterImage: slide.videoPreviewImage.fallbackSourceElement.imageUrl,
                  exportedVideoPath: slide.videoResourcePath,
                }}
              />
            )}
            <AmgEmissionStatement content={slide.emissionStatementModel} className={classes.emissionStatement} />
          </div>
        ))}
      </AmgSlider>
    </Root>
  );
}

export default AmgMediaSlider;

AmgMediaSlider.propTypes = {
  content: PropTypes.object.isRequired,
};
