import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import pkg from '../../package.json';

const PREFIX = 'AmgVersion';

const classes = {
  version: `${PREFIX}-version`,
};

const StyledTypography = styled(Typography)((
  {
    theme,
  }
) => ({
  [`&.${classes.version}`]: {
    textAlign: 'center',
  },
}));

function AmgVersion() {

  return (
    <StyledTypography className={classes.version} variant="caption">
        v{pkg.version}
    </StyledTypography>
  );
}

export default AmgVersion;
