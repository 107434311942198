import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { FormLabel } from '@mui/material';

import AmgButton from './AmgButton';
import AmgCheckbox from './AmgFormElements/AmgCheckbox';
import AmgInputText from './AmgFormElements/AmgInputText';
import { AmgParticipationFormSettings } from './AmgParticipationForm.settings';
import AmgSelectBox from './AmgFormElements/AmgSelectBox';
import AmgSubmitMsg from './AmgFormElements/AmgSubmitMsg';
import useCsrfToken from '../hooks/useCsrfToken';

const PREFIX = 'AmgParticipationForm';

const classes = {
  root: `${PREFIX}-root`,
  submit: `${PREFIX}-submit`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.root}`]: {
    '& button': {
      marginTop: '32px',
    },
    '& .MuiInputLabel-shrink': {
      fontFamily: 'MBCorpoSTitle-Bold-Web',
      transform: 'scale(0.875)',
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.amgWhite,
    },
    margin: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    marginTop: '32px',
  },
}));

function AmgParticipationForm({ content }) {

  const formId = `participationForm-${Math.floor(Math.random() * 1000)}`;
  const csrfToken = useCsrfToken();

  // import needed settings to render the component
  const [formElements, setFormElements] = useState(AmgParticipationFormSettings(content));
  // form hook for dealing with form elements
  const { control, handleSubmit } = useForm();
  const [view, setView] = useState('form');
  const [disabled, setDisabled] = useState(false);

  const STATES = ['ALREADY_REGISTERED', 'DEADLINE_REACHED', 'ERROR', 'MAX_PARTICIPANTS_REACHED', 'SUCCESS'];

  // useEffect to not calculate each time the component is rerendered
  useEffect(()=> {
    setFormElements(AmgParticipationFormSettings(content));
  }, [content]);

  const onSubmit = async (data) => {
    const formParams = Object.entries(data).map((e) => e.join('=')).join('&'); // change to url form params as in sites
    const token = await csrfToken.get();

    setDisabled(true);
    fetch(content.actionUrlPwa, {
      body: formParams,
      headers: {
        'CSRF-Token': token,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      method: 'POST',
    }).then((res) => {
      res.json().then((response) => {
        setView(STATES.includes(response.status) ? response.status : 'ERROR');
      });
    });
  };

  return (
    (<Root>
      {view === 'form' &&
        <form className={classes.root} id={formId} method="POST" onSubmit={handleSubmit(onSubmit)} data-testid="form">
          <FormLabel htmlFor={formId}>{content.i18nKeys['amg.communities.participation.text.formheadline']}</FormLabel>
          {
            formElements.map((el, key) => {
              if (el.type === 'checkbox' && el.linkUrl.length > 0) {
                return (
                  <Controller
                    control={control}
                    defaultValue={false}
                    key={key}
                    name={el.name}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <AmgCheckbox
                        error={error}
                        id={el.id}
                        label={el.label}
                        linkUrl={el.linkUrl}
                        name={el.name}
                        onChange={onChange}
                        value={value} />
                    )}
                    rules={{
                      required: el.required, // contains the error message inside
                    }}
                  />
                );
              } else if (el.type === 'text') {
                return (
                  <Controller
                    control={control}
                    defaultValue={el.value}
                    key={key}
                    name={el.name}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <AmgInputText control={control} error={error} key={key} label={el.label} name={el.name} onChange={onChange} value={value} />
                    )}
                    rules={{
                      required: el.required || '',
                      pattern: {
                        message: el.required,
                        value: el.validation,
                      },
                    }}
                  />
                );
              } else if (el.type === 'select') {
                return (
                  <Controller
                    control={control}
                    defaultValue={content?.user?.country || 'pleaseChoose'}
                    key={key}
                    name={el.name}
                    render={({ field: { onChange, value } }) => (
                      <AmgSelectBox
                        entries={el.countries}
                        firstOptionLabel={content.i18nKeys['amg.communities.participation.text.choose']}
                        name={el.name}
                        onChange={onChange}
                        selectLabel={content.i18nKeys['amg.communities.userprofile.myData.country']}
                        value={value}
                      />
                    )}
                  />
                );
              } else {
                return null;
              }
            })
          }
          <AmgButton link="#" fullwidth="true" disabled={disabled} type="submit">
            {content.i18nKeys['amg.communities.participation.label.submit']}
          </AmgButton>
        </form>
      }
      {STATES.includes(view) &&
        <AmgSubmitMsg
          i18nKeys={content.i18nKeys}
          successMessage={content.successMessage}
          type={view}
        />
      }
    </Root>)
  );
}

export default AmgParticipationForm;

AmgParticipationForm.propTypes = {
  content: PropTypes.object.isRequired,
};
