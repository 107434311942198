import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'AmgBanner';

const classes = {
  action: `${PREFIX}-action`,
  message: `${PREFIX}-message`,
  root: `${PREFIX}-root`,
};

const StyledSnackbar = styled(Snackbar)((
  {
    theme,
  }
) => ({
  [`& .${classes.action}`]: {
    '& svg': {
      fill: theme.palette.amgMineshaftPure,
    },
  },

  [`& .${classes.message}`]: {
    maxWidth: 'calc(100% - 34px)',

    '& span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },

  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.amgWhiteSmoke,
  },
}));

function AmgBanner({ children, handleClose }) {

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    handleClose();
    setOpen(false);
  };

  return (
    <StyledSnackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      open={open}
    >
      <SnackbarContent
        action={<>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClick}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>}
        classes={{
          action: classes.action,
          message: classes.message,
          root: classes.root,
        }}
        message={children}
      />
    </StyledSnackbar>
  );
}

export default AmgBanner;

AmgBanner.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
};

