import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function AmgActivityStreamForumAvatar({ src }) {
  const fallbackSrc = '/etc/clientlibs/amg/amg.communities.app/assets/images/profile-fallback.png';
  let hasFallbackSrc = false;

  const handleError = ({ currentTarget }) => {
    // Prevent looping if the fallback image is also returning an error
    if (!hasFallbackSrc) {
      currentTarget.src = fallbackSrc;
      hasFallbackSrc = true;
    }
  };

  return (
    <Box sx={{ height: 48, mr: 1, ml: 2, width: 48 }}>
      <img
        alt="Avatar"
        onError={handleError}
        src={src}
      />
    </Box>
  );
}

export default AmgActivityStreamForumAvatar;

AmgActivityStreamForumAvatar.propTypes = {
  src: PropTypes.string.isRequired,
};
