import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { white } from '@dh-io-amg/amg-react-lib/colors';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgHeadlines from './AmgHeadlines';
import AmgImage from './AmgImage';
import AmgLink from './AmgLink';
import AmgRichText from './AmgRichText';
import AmgVideoDam from './AmgVideoDam';

import '../css/freeMediaGallery.css';

const PREFIX = 'AmgFreeMediaGallery';

const classes = {
  element: `${PREFIX}-element`,
  emissions: `${PREFIX}-emissions`,
  freeMediaGallery: `${PREFIX}-freeMediaGallery`,
  headlines: `${PREFIX}-headlines`,
};

function AmgFreeMediaGallery({ content }) {
  const MEDIA_ELEMENTS = [];
  const hasButtons = content.button1 || content.button2;
  const btnStyle = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  };

  const galleryStyle = {
    marginBottom: 8,
    display: 'grid',
    gridColumnGap: '3.4722%',
    gridTemplateColumns: 'repeat(12, 5.1504%)',
    px: 2,
  };
  const secondaryLink = {
    ...btnStyle, ...{ backgroundColor: 'transparent', border: `1px solid ${white}` } };

  if (content.media1) {
    MEDIA_ELEMENTS.push(content.media1);
  }
  if (content.media2) {
    MEDIA_ELEMENTS.push(content.media2);
  }
  if (content.media3) {
    MEDIA_ELEMENTS.push(content.media3);
  }

  return (
    <Box>
      <Box className={`amg-fmg-${content.imageLayout}`} sx={galleryStyle}>
        {MEDIA_ELEMENTS.map((media, index) => (
          <Box className={classes.element} key={index} sx={{ gridRow: '1 / 5' }}>
            {media?.mediaVideoImage?.mediaType === 'image' &&
              <AmgImage key={index} content={media.mediaVideoImage.image} />
            }
            {media?.mediaVideoImage?.mediaType === 'video' &&
              <AmgVideoDam
                content={{
                  autoPlay: true,
                  controls: false,
                  exportedVideoPath: media.mediaVideoImage.video.url,
                  loop: true,
                  muted: true,
                  posterImage: media.mediaVideoImage.image.fallbackSourceElement.imageUrl,
                }}
              />
            }
          </Box>
        ))}
      </Box>
      {content && <Box sx={{ mb: 4, px: 2 }}><AmgEmissionStatement content={content.emissionStatement} /></Box>}
      {content.headline && <AmgHeadlines headline={content.headline} subHeadline={content.subline} />}
      {content.richText && <Box sx={{ px: 2 }}><AmgRichText content={content.richText} /></Box>}
      {hasButtons &&
        <Box sx={{ px: 2 }}>
          {content.button1 &&
            <AmgLink
              sx={content.firstButtonPrimary ? btnStyle : secondaryLink}
              eventdetailaction="free-media-gallery"
              link={content.button1}
              primary={content.firstButtonPrimary.toString()}
              fullwidth="true"
            />
          }
          {content.button2 &&
            <AmgLink sx={secondaryLink} eventdetailaction="free-media-gallery" link={content.button2} primary="false" fullwidth="true" />}
        </Box>}
    </Box>
  );
}

export default AmgFreeMediaGallery;

AmgFreeMediaGallery.propTypes = {
  content: PropTypes.object.isRequired,
};
