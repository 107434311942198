import { TOGGLE_DRAWER, TOGGLE_UPDATE_DRAWER } from '../actionTypes';

const initialState = {
  filtersOnDrawer: {},
  open: false,
};

const drawer = (state = initialState, action = {}) => {
  if (action.type === TOGGLE_DRAWER) {
    return { ...state, open: action.payload.open };
  } else if (action.type === TOGGLE_UPDATE_DRAWER) {
    return { ...state, filtersOnDrawer: action.payload.filtersOnDrawer };
  } else {
    return state;
  }
};

export default drawer;
