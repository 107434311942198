import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { List } from '@mui/material';
import { styled } from '@mui/material/styles';

import AmgHeadline from '../components/AmgHeadline';
import AmgMode from '../components/AmgMode';
import AmgPrivacyListItem from '../components/AmgPrivacyListItem';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgPrivacy';

const classes = {
  list: `${PREFIX}-list`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.list}`]: {
    borderBottom: `1px solid ${theme.palette.amgGreyDisabled}`,
    padding: 0,

    '& svg': {
      fill: theme.palette.amgMineshaftPure,
    },
  },

  [`&.${classes.wrapper}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  return {
    footerLinks: state.env.footerLinks || [], // Default value provided only for the tests
  };
};

function AmgPrivacy({ footerLinks }) {
  const { trackEvent } = useTracking();
  const translate = useTranslate();

  const page = useMemo(() => {
    const trackingType = 'pwa-privacy';

    return {
      contentProduct: trackingType,
      contentProject: trackingType,
      name: translate('pwa.settings.privacy.header'),
      siteSection: trackingType,
      type: trackingType,
    };
  }, [translate]);

  useEffect(() => {
    trackEvent({
      event: 'pageview',
      page,
    });
  }, [page, trackEvent]);

  return (
    <StyledAmgMode mode="light" className={classes.wrapper}>
      <AmgHeadline component="h1" variant="subHeadline">
        {translate('pwa.settings.privacy.header')}
      </AmgHeadline>
      <List className={classes.list} component="nav">
        {footerLinks.map((link, index) => {
          return <AmgPrivacyListItem key={index} text={link.attributeMap['data-amg4web-linkname']} url={link.attributeMap.href} />;
        })}
        <AmgPrivacyListItem text={translate('pwa.settings.privacy.cookies')} url="usercentrics" />
      </List>
    </StyledAmgMode>
  );
}

export default connect(
  mapStateToProps,
)(AmgPrivacy);

AmgPrivacy.propTypes = {
  footerLinks: PropTypes.array.isRequired,
};
