import PropTypes from 'prop-types';

import AmgHeroStage from './AmgHeroStage';
import AmgMediaSlider from './AmgMediaSlider';

function AmgStage({ content }) {
  return (
    <>
      {content.stageType === 'hide' && null}
      {content.stageType === 'hero' && content.heroStage && <AmgHeroStage content={content.heroStage} />}
      {content.stageType === 'media' && content.mediaSlider && <AmgMediaSlider content={content.mediaSlider} />}
    </>
  );
}

export default AmgStage;

AmgStage.propTypes = {
  content: PropTypes.object.isRequired,
};
