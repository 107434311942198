import { useCallback, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgVideoJS from './AmgVideoJS';

const PREFIX = 'AmgVideoOnDemand';

const classes = {
  emissionStatement: `${PREFIX}-emissionStatement`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.emissionStatement}`]: {
    margin: theme.spacing(2, 2, 0),
  },
}));

function AmgVideoOnDemand({ content }) {

  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    fluid: true,
    poster: content.image.sourceElements[0].imageUrl,
    responsive: true,
    sources: content.externalizedProxyAssets.map((source) => (
      { src: source.uri, type: source.mimeType }
    )),
  };

  const handlePlayerReady = (player) => {
    // Not sure if that's necessary? ...but the documentation recommends using it
    playerRef.current = player;

    player.on('play', () => {
      setIsPlaying(true);
    });
  };

  // Prevent re-rendering when clicking on Play to switch the Emission Statement
  const AmgVideoJSCached = useCallback(() => {
    return <AmgVideoJS onReady={handlePlayerReady} options={videoJsOptions} />;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root>
      <AmgVideoJSCached />
      <AmgEmissionStatement
        className={classes.emissionStatement}
        content={isPlaying ? content.emissionStatementVideo : content.emissionStatementPreviewImage}
      />
    </Root>
  );
}

export default AmgVideoOnDemand;

AmgVideoOnDemand.propTypes = {
  content: PropTypes.object.isRequired,
};
