import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import AmgLink from './AmgLink';

const classes = {
  'amg-spacing-1': {
    marginTop: '-16px!important',
  },
  'amg-spacing-2': {
    marginTop: '0!important',
  },
  'amg-spacing-3': {
    marginTop: '16px!important',
  },
  'amg-spacing-4': {
    marginTop: '40px!important',
  },
  'amg-spacing-5': {
    marginTop: '80px!important',
  },
};

function AmgQuickLinks({ content }) {
  return (
    <Box className={classes[content.spacing]} sx={{ px: 2, py: 0 }}>
      <Typography component="h2" sx={{
        fontFamily: 'MBCorpoSTitle-Bold-Web',
        fontSize: '30px',
        lineHeight: '33px',
        mb: 1,
      }}>
        {content.text}
      </Typography>
      {content.link1 &&
        <AmgLink
          color={content.firstButtonIsPrimary ? 'primary' : 'secondary'}
          eventdetailaction="quick-links"
          fullWidth
          link={content.link1}
        />
      }
      {content.link2 &&
        <AmgLink
          color="secondary"
          eventdetailaction="quick-links"
          fullWidth
          link={content.link2}
          sx={{ mt: content.link1 ? 2 : 0 }}
        />}
    </Box>
  );
}

export default AmgQuickLinks;

AmgQuickLinks.propTypes = {
  content: PropTypes.object.isRequired,
};
