import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import useTracking from '../hooks/useTracking';

const PREFIX = 'AmgSlider';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(2),
    opacity: 0.999999,

    '& ul': {
      lineHeight: 0,
      margin: theme.spacing(2, 0, 0),
      padding: 0,
      textAlign: 'center',

      '&.dots li': {
        backgroundColor: theme.palette.amgDarkGrey,
        display: 'inline-block',
        height: 12,
        marginRight: 3,
        transform: 'skewX(-23deg)',
        width: 10,

        '&.slick-active': {
          backgroundColor: theme.palette.amgRed,
          width: 20,
        },
      },

      '&.numbers li': {
        lineHeight: 'normal',
        display: 'none',
        fontFamily: 'MBCorpoSText-Regular-Web', // Needs to be refactored
        fontSize: '18px', // Needs to be refactored

        '&.slick-active': {
          display: 'inline-block',
          color: theme.palette.amgWhite,

          '&.disabled': {
            color: theme.palette.amgDarkGrey,
          },
        },
      },
    },
  },
}));

function AmgSlider({ children, className, settings }) {

  const { trackEvent } = useTracking();
  const maxDots = 8;

  const defaultSettings = {
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '8px',

    appendDots: (dots) => {
      if (children.length <= maxDots) {
        return (
          <div>
            <ul className="dots">
              {dots}
            </ul>
          </div>
        );
      } else {
        return (
          <div>
            <ul className="numbers">
              {dots}
              <li className="slick-active disabled">&nbsp;/&nbsp;</li>
              <li className="slick-active disabled">{children.length}</li>
            </ul>
          </div>
        );
      }
    },
    beforeChange: (current, next) => {
      trackEvent({
        event: 'click',
        eventDetail: {
          action: settings.eventDetailAction,
          category: 'link',
          label: current < next ? 'swipe-right' : 'swipe-left',
        },
      });
    },
    customPaging: (index) => {
      if (children.length <= maxDots) {
        return <></>;
      } else {
        return <>{index + 1}</>;
      }
    },
  };

  settings = { ...defaultSettings, ...settings };

  return (
    <Root>
      <div className={clsx(classes.root, className)}>
        <Slider {...settings}>
          {children}
        </Slider>
      </div>
    </Root>
  );
}

export default AmgSlider;

AmgSlider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  settings: PropTypes.object,
};
