import { useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as ShareIcon } from '../svg/share-icon.svg';
import { ReactComponent as AddToHomescreen } from '../svg/add-to-screen-icon.svg';

import AmgHeadline from './AmgHeadline';
import useCsrfToken from '../hooks/useCsrfToken';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgSafariInstallHelperDialog';

const classes = {
  addToHomescreenIcon: `${PREFIX}-addToHomescreenIcon`,
  arrow: `${PREFIX}-arrow`,
  closeButton: `${PREFIX}-closeButton`,
  content: `${PREFIX}-content`,
  description: `${PREFIX}-description`,
  dialog: `${PREFIX}-dialog`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  li: `${PREFIX}-li`,
  ol: `${PREFIX}-ol`,
  shareIcon: `${PREFIX}-shareIcon`,
  skip: `${PREFIX}-skip`,
  subtitle: `${PREFIX}-subtitle`,
};

const StyledDialog = styled(Dialog)((
  {
    theme,
  }
) => ({
  [`& .${classes.addToHomescreenIcon}`]: {
    height: '19px',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '19px',
  },

  [`& .${classes.arrow}`]: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderTopColor: theme.palette.amgMineshaftPure,
    borderWidth: '15px 15px 0 15px',
    height: 0,
    margin: 'auto',
    width: 0,
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.content}`]: {
    background: theme.palette.amgMineshaftPure,
  },

  [`& .${classes.description}`]: {
    borderBottom: `1px solid ${theme.palette.amgDarkGrey}`,
    paddingBottom: theme.spacing(2),
  },

  [`&.${classes.dialog}`]: {
    '& svg': {
      fill: theme.palette.amgWhite,
    },
  },

  [`& .${classes.dialogTitle}`]: {
    background: theme.palette.amgMineshaftPure,
    paddingBottom: 0,
  },

  [`& .${classes.li}`]: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(4),
    position: 'relative',
  },

  [`& .${classes.ol}`]: {
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.shareIcon}`]: {
    height: '24px',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '17px',
  },

  [`& .${classes.skip}`]: {
    color: theme.palette.amgDarkGrey,
    fontSize: '14px',
    textAlign: 'center',
    textDecoration: 'underline',
  },

  [`& .${classes.subtitle}`]: {
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
}));

function AmgSafariInstallHelperDialog() {

  const [open, setOpen] = useState(true);
  const { trackEvent } = useTracking();
  const translate = useTranslate();
  const csrfToken = useCsrfToken();

  const handleClose = () => {
    track('close');
    setOpen(false);
    window.sessionStorage.setItem('addToHomescreen', true);
  };

  const handleDisable = async () => {
    track('disable');
    setOpen(false);
    window.sessionStorage.setItem('addToHomescreen', true);

    const token = await csrfToken.get();
    await fetch('/bin/amg/pwahomescreen', {
      method: 'POST',
      headers: {
        'CSRF-Token': token,
      },
    });
  };

  const track = useCallback((label) => {
    trackEvent({
      event: 'click',
      eventDetail: {
        action: 'homescreen-info',
        category: 'link',
        label,
      },
    });
  }, [trackEvent]);

  useEffect(() => {
    track('displayed');
  }, [track]);

  return (
    <StyledDialog
      className={classes.dialog}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          bottom: '5px',
          margin: 0,
          position: 'absolute',
          width: 'calc(100% - 32px)',
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <AmgHeadline className={classes.subtitle} variant="subHeadline">
          {translate('pwa.homescreen.popup.title')}
        </AmgHeadline>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography gutterBottom className={classes.description}>
          {translate('pwa.homescreen.popup.description')}
        </Typography>
        <ol className={classes.ol}>
          <li className={classes.li}>
            <Typography gutterBottom>
              {translate('pwa.homescreen.popup.step1')}
              <ShareIcon className={classes.shareIcon} />
            </Typography>
          </li>
          <li className={classes.li}>
            <Typography gutterBottom>
              {translate('pwa.homescreen.popup.step2')}
            </Typography>
          </li>
          <li className={classes.li}>
            <Typography gutterBottom>
              {translate('pwa.homescreen.popup.step3')}
              <AddToHomescreen className={classes.addToHomescreenIcon} />
            </Typography>
          </li>
        </ol>
        <div className={classes.skip} onClick={handleDisable}>
          {translate('pwa.homescreen.popup.disable')}
        </div>
      </DialogContent>
      <div className={classes.arrow} />
    </StyledDialog>
  );
}

export default AmgSafariInstallHelperDialog;
