import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ReactComponent as ChevronLeftIcon } from '../svg/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../svg/chevron-right.svg';

const PREFIX = 'AmgArrow';

const classes = {
  chevron: `${PREFIX}-chevron`,
  chevronLeft: `${PREFIX}-chevronLeft`,
  chevronRight: `${PREFIX}-chevronRight`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.chevron}`]: {
    height: 24, // 1.5rem
    top: 'calc(50% + 16px)',
    position: 'absolute',
    zIndex: 1,
  },

  [`&.${classes.chevronLeft}`]: {
    left: theme.spacing(1),
  },

  [`&.${classes.chevronRight}`]: {
    right: theme.spacing(1),
  },

  [`& .${classes.icon}`]: {
    fontSize: 24, // 1.5rem
  },
}));

function AmgArrow({ onClick, variant }) {

  return (
    <Root
      className={clsx({
        [classes.chevron]: true,
        [classes.chevronLeft]: variant === 'left',
        [classes.chevronRight]: variant === 'right',
        chevronLeft: variant === 'left',
        chevronRight: variant === 'right',
        chevron: true,
      })}
      onClick={onClick}
    >
      {variant === 'left' ? <ChevronLeftIcon className={classes.icon} /> : <ChevronRightIcon className={classes.icon} /> }
    </Root>
  );
}

export default AmgArrow;

AmgArrow.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
};
