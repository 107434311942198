import { useEffect, useState } from 'react';

import { fetchHandler } from '../utils';

function useEmissionStatement(post) {
  const [emissionStatement, setEmissionStatement] = useState();

  // Get the image URL from the post JSON object
  const imageUrl = post?.image?.sourceElements[0]?.imageUrl;
  // Replace the end of the URL to get the emission statement URL, i.e. ".jpeg" to ".emissions.json"
  const emissionStatementUrl = typeof imageUrl === 'string' ? imageUrl.replace(/\.media1\..+/, '.emissions.json') : '';

  useEffect(() => {
    let isSubscribed = true;

    if (emissionStatementUrl) {
      fetchHandler(emissionStatementUrl).then((res) => {
        if (isSubscribed) {
          setEmissionStatement(res);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [emissionStatementUrl]);

  return emissionStatement;
}

export default useEmissionStatement;
