import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'AmgImage';

const classes = {
  img: `${PREFIX}-img`,
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.img}`]: {
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  },

  [`&.${classes.wrapper}`]: {
    backgroundColor: theme.palette.amgSlate,
    position: 'relative',
  },
}));

function AmgImage({ content, className, skeleton }) {
  const sizes = [];
  const srcset = [];

  // Do not try to render a broken image without all mandatory attributes
  if (!content.sourceElements) {
    return null;
  }

  content.sourceElements.forEach((element) => {
    sizes.push(`(max-width: ${element.width}px) ${element.width}px`);
    srcset.push(`${element.imageUrl} ${element.width}w`);
  });

  // Show a skeleton by default
  if (typeof skeleton === 'undefined') {
    skeleton = true;
  }

  if (skeleton === false) {
    return (
      <img
        alt={content.alt}
        className={className}
        loading="lazy"
        sizes={sizes.join(',')}
        srcSet={srcset.join(',')}
      />
    );
  }

  return (
    <Root className={clsx(classes.wrapper, className, 'amg-placeholder')}
      style={{
        paddingBottom: `${content.image.originalHeight / content.image.originalWidth * 100}%`,
      }}
    >
      <img
        alt={content.alt}
        className={classes.img}
        loading="lazy"
        sizes={sizes.join(',')}
        srcSet={srcset.join(',')}
      />
    </Root>
  );
}

export default AmgImage;

AmgImage.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  skeleton: PropTypes.bool,
};
