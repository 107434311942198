import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  setHasNewPosts as setHasNewPostsAction,
} from '../redux/actions';
import { buildContentSearchUrl, fetchHandler, getMaxTimestampFromPosts } from '../utils';

function AmgActivityStreamListener({ filters, setHasNewPosts }) {
  useEffect(() => {
    const fetchData = async () => {
      const url = buildContentSearchUrl({
        page: 0,
      });
      const posts = await fetchHandler(url);
      const newestTimestamp = getMaxTimestampFromPosts(posts.results);

      if (newestTimestamp) {
        setHasNewPosts(newestTimestamp);
      }
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    window.addEventListener('checkNewPosts', fetchData);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('checkNewPosts', fetchData);
    };
  }, [filters, setHasNewPosts]);

  return null;
}

export default connect(
  null,
  {
    setHasNewPosts: setHasNewPostsAction,
  },
)(AmgActivityStreamListener);

AmgActivityStreamListener.propTypes = {
  filters: PropTypes.object,
  setHasNewPosts: PropTypes.func.isRequired,
};
