import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import AmgActivityStreamListener from './components/AmgActivityStreamListener';
import AmgAppBar from './components/AmgAppBar';
import AmgBottomNavigation from './components/AmgBottomNavigation';
import AmgConsentObserver from './components/AmgConsentObserver';
import AmgDrawer from './components/AmgDrawer';
import AmgError from './components/AmgError';
import AmgMaintenance from './pages/AmgMaintenance';
import AmgNetworkStatus from './components/AmgNetworkStatus';
import AmgTheme from './AmgTheme';
import {
  setEnv as setEnvAction,
  setUser as setUserAction,
} from './redux/actions';
import { fetchHandler, formatEndpoint, getHostToEnv } from './utils';
import useCookieLayer from './hooks/useCookieLayer';
import useGTMScript from './hooks/useGTMScript';

function AmgInit({ setEnv, setUser }) {
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState(false);

  // Force the user to login through CIAM if he is not logged in yet
  const checkUserLayer = (userLayer) => {
    if (userLayer === 'layer_a') {
      window.location.href = '/bin/daimler/public/ciam/authent.html?pwa=true';
    }
  };

  useCookieLayer();
  useGTMScript();

  useEffect(() => {
    isMounted.current = true;

    const init = async () => {
      // Step 1: get the ENV variables
      const hostToEnv = getHostToEnv();

      const envJson = await fetchHandler(`/app/env.${hostToEnv}.json`);

      // If we load the PWA during the maintenance period, stop everything and show the maintenance page
      if (envJson.maintenance) {
        setIsMaintenance(true);
        return;
      }

      // Step 2: get the user info
      const userJson = await fetchHandler(envJson.USERINFO_PATH);

      // If the user is not authorised (Layer A) then send him to CIAM
      checkUserLayer(userJson.user_layer);

      // Step 3: get the PWA links
      const pwaLinks = await fetchHandler(formatEndpoint(envJson.PWALINKS_PATH, userJson.preferred_language));

      if (isMounted.current) {
        // Add the hashId from the amg_cid cookie
        const hashIds = document.cookie.match(/(^| )amg_cid=([^;]+)/);
        userJson.hashId = hashIds ? hashIds[2] : '';

        // Step 4: save everything in the Redux store
        setUser(userJson);
        setEnv({ ...envJson, ...pwaLinks });

        // Step 5: flag that the app is ready
        setIsLoading(false);
      }
    };

    init();

    return () => {
      isMounted.current = false;
    };
  }, [setEnv, setUser]);

  if (isMaintenance) {
    return (
      <AmgTheme>
        <AmgMaintenance />
      </AmgTheme>
    );
  }

  // If the user is offline then show an error message
  if (!navigator.onLine) {
    return <AmgError variant="offline" />;
  }

  // If the app is not ready yet then do not render anything
  if (isLoading) {
    return null;
  }

  // If the user is online and the app is ready then render the app
  return (
    <HashRouter>
      <AmgActivityStreamListener />
      <AmgTheme>
        <AmgAppBar />
        <AmgDrawer />
        <AmgNetworkStatus />
        <AmgBottomNavigation />
      </AmgTheme>
      <AmgConsentObserver />
    </HashRouter>
  );
}

export default connect(
  null,
  {
    setEnv: setEnvAction,
    setUser: setUserAction,
  },
)(AmgInit);

AmgInit.propTypes = {
  setEnv: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};
