import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import AmgHeadline from './AmgHeadline';
import AmgImage from './AmgImage';
import AmgLabel from './AmgLabel';

import { ReactComponent as CalendarIcon } from '../svg/calendar.svg';
import { ReactComponent as LocationIcon } from '../svg/location.svg';
import { ReactComponent as TimeIcon } from '../svg/time.svg';
import { maxChar } from '../utils';
import useDateBeautifier from '../hooks/useDateBeautifier';

const PREFIX = 'AmgActivityStreamEvent';

const classes = {
  emission: `${PREFIX}-emission`,
  headline: `${PREFIX}-headline`,
  icon: `${PREFIX}-icon`,
  info: `${PREFIX}-info`,
  text: `${PREFIX}-text`,
  username: `${PREFIX}-username`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.emission}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.headline}`]: {
    margin: theme.spacing(2, 2, 0, 2),
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.info}`]: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '0.875rem', // 14px
    margin: theme.spacing(1, 2, 0),

    '& svg': {
      fill: theme.palette.amgQuickSilver,
    },
  },

  [`& .${classes.text}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.username}`]: {
    color: theme.palette.amgBlack,
  },
}));

function AmgActivityStreamEvent({ content }) {
  // If same day, we only show one. If same year, we only show year once
  const startDate = useDateBeautifier(content.startDate, 'short', true);
  const endDate = useDateBeautifier(content.endDate, 'short', true);
  const startDateNoYear = useDateBeautifier(content.startDate, 'noyear', true);
  const d1 = new Date(content.startDate);
  const d2 = new Date(content.endDate);
  let date;

  if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()) {
    date = startDateNoYear;
  } else if (d1.getFullYear() === d2.getFullYear()) {
    date = `${startDateNoYear} - ${endDate}`;
  } else {
    date = `${startDate} - ${endDate}`;
  }

  // We show start date time and end date time if no duration is shown
  const startTime = useDateBeautifier(content.startDate, 'timeonly', true);
  const endTime = useDateBeautifier(content.endDate, 'timeonly', true);
  const minuteTime = content.minutes ? `${content.hours}h ${content.minutes}m` : `${content.hours}h`;
  const time = content.displayDuration ? minuteTime : `${startTime} - ${endTime}`;

  return (
    (<Root>
      <AmgImage content={content.image} />
      <AmgLabel type={content.category} />
      {content.eventCategory &&
        <div className={classes.text}>
          {maxChar(content.eventCategory, 40)}
        </div>
      }
      <AmgHeadline
        className={classes.headline}
        component="div"
        variant="headlineSlider"
      >
        {maxChar(content.headline, 45)}
      </AmgHeadline>
      <div className={classes.text}>
        {maxChar(content.introductionText, 165)}
      </div>
      <div className={classes.info}>
        <CalendarIcon className={classes.icon} />
        {date}
      </div>
      <div className={classes.info}>
        <TimeIcon className={classes.icon} />
        {time}
      </div>
      <div className={classes.info}>
        <LocationIcon className={classes.icon} />
        {content.location}
      </div>
    </Root>)
  );
}

export default AmgActivityStreamEvent;

AmgActivityStreamEvent.propTypes = {
  content: PropTypes.object.isRequired,
};
