import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { cleanHTML } from '../utils';

import AmgHeadlines from './AmgHeadlines';

const PREFIX = 'AmgTable';

const classes = {
  cell: `${PREFIX}-cell`,
  fixedWidth: `${PREFIX}-fixedWidth`,
  leftCell: `${PREFIX}-leftCell`,
  rightCell: `${PREFIX}-rightCell`,
  subHeadline: `${PREFIX}-subHeadline`,
  tableContainer: `${PREFIX}-tableContainer`,
};

const StyledBox = styled(Box)((
  {
    theme,
  }
) => ({
  [`& .${classes.cell}`]: {
    borderColor: theme.palette.amgObsidian,
    fontSize: '16px',
    padding: theme.spacing(2, 0),
  },

  [`& .${classes.fixedWidth}`]: {
    width: '50%',
  },

  [`& .${classes.leftCell}`]: {
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.rightCell}`]: {
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.subHeadline}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.tableContainer}`]: {
    padding: theme.spacing(0, 2),
  },
}));

function AmgTable({ content }) {

  const columns = { left: [], right: [] };

  // Convert a string into a NodeList
  const template = document.createElement('template');
  template.innerHTML = content.richTextTable.markup.trim();
  const html = template.content.firstChild;

  // Loop over all the <dd> and put each element alternatively on the left and right
  html.querySelectorAll('dd').forEach((element, index) => {
    const col = index % 2 === 0 ? 'left' : 'right';
    const align = element.classList.contains('amg-m-two-column-table__col--right') ? 'right' : 'left';
    columns[col].push({
      content: element.innerHTML,
      align: align,
    });
  });

  return (
    <StyledBox>
      <AmgHeadlines headline={content.headline.headline} subHeadline={content.headline.topicLine} />
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="table">
          <TableBody>
            {columns.left.map((value, index) => (
              <TableRow key={index}>
                <TableCell
                  align={value.align}
                  className={clsx({
                    [classes.cell]: true,
                    [classes.leftCell]: true,
                    [classes.fixedWidth]: !content.fluidColumnWidth,
                  })}
                  dangerouslySetInnerHTML={{ __html: cleanHTML(value.content) }}
                />
                <TableCell
                  align={columns.right[index].align}
                  className={clsx({
                    [classes.cell]: true,
                    [classes.rightCell]: true,
                    [classes.fixedWidth]: !content.fluidColumnWidth,
                  })}
                  dangerouslySetInnerHTML={{ __html: cleanHTML(columns.right[index].content) }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBox>
  );
}

export default AmgTable;

AmgTable.propTypes = {
  content: PropTypes.object.isRequired,
};
