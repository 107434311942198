import { styled } from '@mui/material/styles';

import AmgButton from '../components/AmgButton';
import AmgHeadlines from '../components/AmgHeadlines';
import AmgMode from '../components/AmgMode';

const PREFIX = 'AmgMaintenance';

const classes = {
  button: `${PREFIX}-button`,
  img: `${PREFIX}-img`,
  text: `${PREFIX}-text`,
  version: `${PREFIX}-version`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(4, 2, 0),
    textAlign: 'center',
  },

  [`& .${classes.img}`]: {
    display: 'block',
  },

  [`& .${classes.text}`]: {
    margin: theme.spacing(0, 2, 2),
  },

  [`& .${classes.version}`]: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

function AmgMaintenance() {
  const isGerman = navigator.language.toLowerCase().startsWith('de');

  const handleClick = () => {
    window.location.href = `/content/amg-pl/${isGerman ? 'de' : 'en'}/members-home/community.html`;
  };

  return (
    <StyledAmgMode mode="dark">
      <AmgHeadlines
        headline={isGerman
          ? 'Neuer Community Feed'
          : 'All new community feed'}
      />
      <div
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: isGerman
          ? 'Liebes AMG Private Lounge Community-Mitglied,<br /><br />der Activity Stream wurde mit dem Start der neuen AMG Private Lounge Gruppen abgeschaltet. Aber keine Sorge, Sie können ab sofort Ihre gewünschten Inhalte auf einen Blick im neuen Community Feed der AMG Private Lounge sehen.<br /><br />Öffnen Sie den Link zum Community Feed auf Ihrem IOS oder Android Smartphone und fügen Sie den Community Feed als Favorit zu Ihrem Home-Bildschirm hinzu. So bleiben Sie über Ihr Smartphone jederzeit informiert.'
          : 'Dear AMG Private Lounge Community Member,<br /><br />this activity stream has been switched off with the launch of the new AMG Private Lounge groups. But don\'t worry, you can now access your favourite content at a glance in the new AMG Private Lounge community feed.<br /><br />Open the link to the community feed on your IOS or Android smartphone and add the community feed as a favourite to your home screen. This will keep you informed via your smartphone at all times.',
        }}
      />
      <img src={`maintenance-${isGerman ? 'de' : 'en'}.jpg`} alt="" className={classes.img} />
      <div className={classes.button}>
        <AmgButton fullWidth onClick={handleClick}>
          {isGerman
            ? 'Zum Community Feed'
            : 'Go to community feed'}
        </AmgButton>
      </div>
    </StyledAmgMode>
  );
}

export default AmgMaintenance;
