import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import AmgActivityStreamChip from '../components/AmgActivityStreamChip';
import AmgActivityStreamPost from '../components/AmgActivityStreamPost';
import AmgActivityStreamSkeleton from '../components/AmgActivityStreamSkeleton';
import AmgActivityStreamSpinner from '../components/AmgActivityStreamSpinner';
import AmgDeviceNotification from '../components/AmgDeviceNotification';
import AmgError from '../components/AmgError';
import AmgFilterDialog from '../components/AmgFilterDialog';
import AmgFiltersResetBanner from '../components/AmgFiltersResetBanner';
import AmgMode from '../components/AmgMode';
import AmgSafariInstallHelper from '../components/AmgSafariInstallHelper';

import { updateActivityStream, updateStatus } from '../redux/actions';
import { buildContentSearchUrl, fetchHandler } from '../utils';

import useScrollTop from '../hooks/useScrollTop';
import useTracking from '../hooks/useTracking';
import useTranslate from '../hooks/useTranslate';

const PREFIX = 'AmgActivityStream';

const classes = {
  empty: `${PREFIX}-empty`,
  infiniteScroll: `${PREFIX}-infiniteScroll`,
};

const StyledAmgMode = styled(AmgMode)((
  {
    theme,
  }
) => ({
  [`& .${classes.empty}`]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.infiniteScroll}`]: {
    position: 'relative',
  },
}));

function AmgActivityStream() {

  const [hasInitPostFetched, setHasInitPostFetched] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const post = useSelector((state) => state.post);
  const { trackEvent } = useTracking();

  useScrollTop();

  // Fetch new posts on scroll
  const fetchScrollPosts = useCallback(async () => {
    // Don't do anything if the previous call didn't return an "next" link
    if (!post.nextLink) {
      return;
    }

    const url = buildContentSearchUrl(post.nextLink);
    const res = await fetchHandler(url);

    // Add the new posts at the end of the list
    dispatch(updateActivityStream({
      nextLink: res._links.next,
      posts: post.posts.concat(res.results),
    }));
  }, [dispatch, post.nextLink, post.posts]);

  // Needs to wait for the posts to be rendered before scrolling down
  if (!hasInitPostFetched && post?.posts?.length > 0) {
    window.requestAnimationFrame(() =>{
      window.scrollTo(0, post.scrollTop);
      setHasInitPostFetched(true);
    });
  }

  useEffect(() => {
    dispatch(updateStatus({ isHome: true }));

    trackEvent({
      event: 'pageview',
      page: {
        type: 'homepage',
        name: 'activity stream',
        layer: 'b',
      },
    });

    return () => {
      dispatch(updateStatus({ isHome: false }));
    };
  }, [dispatch, trackEvent]);

  const refresh = () => {
    window.dispatchEvent(new CustomEvent('refresh', {
      detail: {
        action: 'pullDown',
      },
    }));
  };

  if (post?.posts?.length === 0) {
    return <AmgError variant="emptyStream" />;
  }

  return (
    <StyledAmgMode className="amg-page" mode="dark">
      <AmgFiltersResetBanner />
      <AmgActivityStreamChip setIsFetching={setIsFetching} />
      {!post?.posts &&
        <div>
          <AmgActivityStreamSkeleton />
          <AmgActivityStreamSkeleton />
          <AmgActivityStreamSkeleton />
        </div>
      }
      {post?.posts &&
        <AmgActivityStreamSpinner isFetching={isFetching}>
          <InfiniteScroll
            className={classes.infiniteScroll}
            dataLength={post?.posts?.length || 0}
            hasMore={true}
            next={fetchScrollPosts}
            pullDownToRefresh
            pullDownToRefreshContent={<div style={{ height: '80px' }}></div>}
            pullDownToRefreshThreshold={80}
            refreshFunction={refresh}
          >
            {post.posts.map((item, index) => {
              return item ? <AmgActivityStreamPost content={item} key={index} /> : null;
            })}
            {post.posts.length === 0 && hasInitPostFetched &&
              <div className={classes.empty}>
                {translate('pwa.activityStream.empty')}
              </div>
            }
          </InfiniteScroll>
        </AmgActivityStreamSpinner>
      }
      <AmgFilterDialog />
      <AmgSafariInstallHelper />
      <AmgDeviceNotification />
    </StyledAmgMode>
  );
}

export default AmgActivityStream;
