import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { cleanHTML } from '../utils';

import pkg from '../../package.json';

const PREFIX = 'AmgRichText';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.root}`]: {
    '& h2': {
      textTransform: 'uppercase',
    },

    '& h4': {
      fontSize: 26,
    },

    '& .amg-m-richtext__link': {
      color: theme.palette.amgWhite,

      '&:hover, &:active, &:focus': {
        color: theme.palette.amgRed,
      },
    },

    '& .wb-e-bulletlist-1': {
      paddingInlineStart: 20,
    },

    '& .amg-m-two-column-table__row': {
      display: 'flex',
      width: '100%',
      margin: 0,
      borderTop: `1px solid ${theme.palette.amgObsidian}`,

      '&:first-of-type': {
        borderTop: 'none',
      },

      '&:last-child .amg-m-two-column-table__col': {
        paddingBottom: 0,
      },
    },

    '& .amg-m-two-column-table__col': {
      flexGrow: 1,
      margin: 0,
      padding: theme.spacing(2, 1),
    },
  },
}));

function AmgRichText({ className, content }) {

  let html = '';

  if (content.markup) {
    // first exchange all _self to _blank
    html = content.markup.replace(/_self/gm, '_blank');

    // check if the link is internal by .model.json and remove the target="_blank"
    html = html.replace(/(\.model.json.* target="_blank")./gm, (match, link) => {
      return link.replace('_blank', '_self');
    });

    // adapt the url to be conform pwa
    html = html.replace(/"([^"]+.model.json)"/gm, (match, url) => {
      return `${pkg.homepage}/index.html#/page/${encodeURIComponent(url)}`;
    });
  }
  return <Root className={clsx(classes.root, className, 'amg-tracked')} dangerouslySetInnerHTML={{ __html: cleanHTML(html, true) }} />;
}

export default AmgRichText;

AmgRichText.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
};
