import PropTypes from 'prop-types';
import { useState } from 'react';

import AmgEmissionStatement from './AmgEmissionStatement';
import AmgComparisonSliderSelect from './AmgComparisonSliderSelect';
import AmgComparisonSliderSlides from './AmgComparisonSliderSlides';
import { Box } from '@mui/material';

const combineEmissions = (left, right) => {
  if (left.ignoreEmission) {
    return right;
  }

  if (right.ignoreEmission) {
    return left;
  }

  return {
    'combustionEmissionStatement': {
      combinedConsumptionMax: Math.max(left.combustionEmissionStatement.combinedConsumptionMax, right.combustionEmissionStatement.combinedConsumptionMax),
      combinedConsumptionMin: Math.min(left.combustionEmissionStatement.combinedConsumptionMin, right.combustionEmissionStatement.combinedConsumptionMin),
      combinedCO2EmissionMax: Math.max(left.combustionEmissionStatement.combinedCO2EmissionMax, right.combustionEmissionStatement.combinedCO2EmissionMax),
      combinedCO2EmissionMin: Math.min(left.combustionEmissionStatement.combinedCO2EmissionMin, right.combustionEmissionStatement.combinedCO2EmissionMin),
      combinedConsumption: Math.min(left.combustionEmissionStatement.combinedConsumption, right.combustionEmissionStatement.combinedConsumption),
      footnotes: Array.from(new Set(left.combustionEmissionStatement.footnotes.concat(right.combustionEmissionStatement.footnotes))),
    },
    'electricEmissionStatement': {
      combinedConsumptionMax: Math.max(left.electricEmissionStatement.combinedConsumptionMax, right.electricEmissionStatement.combinedConsumptionMax),
      combinedConsumptionMin: Math.min(left.electricEmissionStatement.combinedConsumptionMin, right.electricEmissionStatement.combinedConsumptionMin),
      combinedCO2EmissionMax: Math.max(left.electricEmissionStatement.combinedCO2EmissionMax, right.electricEmissionStatement.combinedCO2EmissionMax),
      combinedCO2EmissionMin: Math.min(left.electricEmissionStatement.combinedCO2EmissionMin, right.electricEmissionStatement.combinedCO2EmissionMin),
      combinedConsumption: Math.min(left.electricEmissionStatement.combinedConsumption, right.electricEmissionStatement.combinedConsumption),
      footnotes: Array.from(new Set(left.electricEmissionStatement.footnotes.concat(right.electricEmissionStatement.footnotes))),
    },
    'hybridEmissionStatement': {
      combinedConsumptionMax: Math.max(left.hybridEmissionStatement.combinedConsumptionMax, right.hybridEmissionStatement.combinedConsumptionMax),
      combinedConsumptionMin: Math.min(left.hybridEmissionStatement.combinedConsumptionMin, right.hybridEmissionStatement.combinedConsumptionMin),
      combinedCO2EmissionMax: Math.max(left.hybridEmissionStatement.combinedCO2EmissionMax, right.hybridEmissionStatement.combinedCO2EmissionMax),
      combinedCO2EmissionMin: Math.min(left.hybridEmissionStatement.combinedCO2EmissionMin, right.hybridEmissionStatement.combinedCO2EmissionMin),
      combinedConsumption: Math.min(left.hybridEmissionStatement.combinedConsumption, right.hybridEmissionStatement.combinedConsumption),
      footnotes: Array.from(new Set(left.hybridEmissionStatement.footnotes.concat(right.hybridEmissionStatement.footnotes))),
    },
  };
};
function AmgComparisonSliderPanel({ content, index, value }) {

  const [values, setValues] = useState({
    left: content.slides[0],
    right: content.slides[1],
    selected: {
      left: 0,
      right: 1,
    },
  });

  const handleSelect = (side, newIndex) => {
    setValues({
      ...values,
      [side]: content.slides[newIndex],
      selected: { ...values.selected, [side]: newIndex },
    });
  };

  return (
    <Box hidden={value !== index} role="tabpanel" sx={{ overflow: 'hidden' }}>
      <AmgComparisonSliderSlides index={index} value={value} values={values} />
      <Box sx={{ px: 2, py: 0 }}>
        <AmgComparisonSliderSelect content={content} handleSelect={handleSelect} side="left" value={values.selected.left} />
        <AmgComparisonSliderSelect content={content} handleSelect={handleSelect} side="right" value={values.selected.right} />
      </Box>
      <Box sx={{ mx: 2, my: 0 }}>
        <AmgEmissionStatement content={combineEmissions(values.left.emissions, values.right.emissions)} />
      </Box>
    </Box>
  );
}

export default AmgComparisonSliderPanel;

AmgComparisonSliderPanel.propTypes = {
  content: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
